import { useState, useEffect } from 'react';

export const useValidateOnBlur = (error?: boolean) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const validateField = () => {
    setHasError(!!error);
  };

  const validateNoDuplicateAndLength = (
    textFieldValue?: string,
    maxLength?: number,
    values?: string[]
  ) => {
    // Make sure that maxLength is respected, if maxLength
    if (!!maxLength && textFieldValue!.length > maxLength) {
      return false;
    }

    // Make sure textFieldValue is not already present, if textFieldValue
    if (textFieldValue && values?.includes(textFieldValue)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (hasError && !error) setHasError(false);
  }, [error, hasError]);

  return { hasError, setHasError, validateField, validateNoDuplicateAndLength };
};
