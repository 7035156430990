import { IApiData } from '../apiType';
import axiosInstance from '../axiosInstance';
import {
  IPublishAsset,
  IUploadSession,
  IUploadSessionStatusResponse
} from '../models/uploadSession';

const UPLOAD_SESSION_PREFIX = 'api/v1/uploadsessions';
const PUBLISH_SUFFIX = 'publish';
const STATUS_SUFFIX = 'status';

export async function getUploadSession(): Promise<IApiData<IUploadSession>> {
  return await axiosInstance.post(UPLOAD_SESSION_PREFIX);
}

export async function publishUploadSession(
  id: string,
  body: IPublishAsset[]
): Promise<void> {
  return await axiosInstance.post(
    `${UPLOAD_SESSION_PREFIX}/${id}/${PUBLISH_SUFFIX}`,
    body
  );
}

export async function deleteUploadSession(id: string): Promise<void> {
  return await axiosInstance.delete(`${UPLOAD_SESSION_PREFIX}/${id}`);
}

export async function getUploadSessionStatusById(
  id: string,
  signal?: AbortSignal
): Promise<{ data: IUploadSessionStatusResponse }> {
  return await axiosInstance.get(
    `${UPLOAD_SESSION_PREFIX}/${id}/${STATUS_SUFFIX}`,
    { signal: signal }
  );
}
