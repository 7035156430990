import { CssVarsTheme, PaletteMode, Theme } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  CustomColorSchemesWithoutMode,
  defaultTheme,
  blackTheme,
  greenTheme,
  orangeTheme,
  redTheme
} from './customTheme';
import { MyCssVarsProvider } from './_components/MyCssVarsProvider';
import {
  COLOR_MODE_LOCALSTORAGE_KEY,
  DEFAULT_COLOR_MODE,
  SELECTED_COLOR_LOCALSTORAGE_KEY
} from 'src/app/utilities';

interface ITheme {
  name: typeof availableThemes[keyof typeof availableThemes];
  theme: Omit<Theme, 'palette'> & CssVarsTheme;
}

export const availableThemes = {
  default: 'default',
  black: 'black',
  green: 'green',
  orange: 'orange',
  red: 'red'
};

/**
 * All available themes use allAvailableThemes[0] as default theme
 */
export const allAvailableThemes: ITheme[] = [
  { name: availableThemes.default, theme: defaultTheme },
  { name: availableThemes.black, theme: blackTheme },
  { name: availableThemes.green, theme: greenTheme },
  { name: availableThemes.orange, theme: orangeTheme },
  { name: availableThemes.red, theme: redTheme }
];

interface IThemeProps {
  theme: ITheme;
  setTheme: Dispatch<SetStateAction<ITheme>> | (() => unknown);
}

export const getSelectedModeFromLocalStorage = (): PaletteMode => {
  const selectedModeInLocalStorage =
    globalThis.localStorage.getItem(COLOR_MODE_LOCALSTORAGE_KEY) ||
    DEFAULT_COLOR_MODE;

  // We have to manually set the color scheme in the local storage
  // because for whatever reason the dark color scheme is often light
  // and breaks the whole light/dark theming
  globalThis.localStorage.setItem('mui-color-scheme-light', 'light');
  globalThis.localStorage.setItem('mui-color-scheme-dark', 'dark');

  return selectedModeInLocalStorage as PaletteMode;
};

export const getSelectedColorFromLocalStorage =
  (): CustomColorSchemesWithoutMode => {
    const selectedColorInLocalStorage =
      globalThis.localStorage.getItem(SELECTED_COLOR_LOCALSTORAGE_KEY) ||
      'default';

    return selectedColorInLocalStorage as CustomColorSchemesWithoutMode;
  };

// Get the theme from the local storage (if it exists)
const getInitialTheme = () => {
  const localStorageTheme = localStorage.getItem(
    SELECTED_COLOR_LOCALSTORAGE_KEY
  );

  const themeToApply =
    allAvailableThemes.find((t) => t.name === localStorageTheme) ??
    allAvailableThemes[0];

  return themeToApply;
};

export const CustomThemeContext = createContext<IThemeProps>({} as IThemeProps);

export const CustomThemeProvider = ({
  children,
  themeFromTenant,
  nestedTheme = false
}: {
  children: React.ReactNode;
  themeFromTenant?: CustomColorSchemesWithoutMode | undefined;
  nestedTheme?: boolean | undefined;
}) => {
  const [theme, setTheme] = useState<ITheme>(getInitialTheme());

  const setThemeWithLocalStorage = useCallback(
    (value: SetStateAction<ITheme>) => {
      if (!nestedTheme) {
        localStorage.setItem(SELECTED_COLOR_LOCALSTORAGE_KEY, value.name);
      }
      setTheme(value);
    },
    [nestedTheme]
  );

  const updateThemeFromLocalStorage = (event: StorageEvent) => {
    if (event.key === SELECTED_COLOR_LOCALSTORAGE_KEY) {
      const newTheme =
        allAvailableThemes.find((t) => t.name === event.newValue) ??
        allAvailableThemes[0];
      setTheme(newTheme);
    }
  };

  // update theme when is updated in another tab
  useEffect(() => {
    if (!nestedTheme) {
      window.addEventListener('storage', updateThemeFromLocalStorage);
    }
    return () => {
      window.removeEventListener('storage', updateThemeFromLocalStorage);
    };
  }, [nestedTheme]);

  useEffect(() => {
    if (themeFromTenant) {
      setThemeWithLocalStorage(
        allAvailableThemes.find((t) => t.name === themeFromTenant) ??
          allAvailableThemes[0]
      );
    }
  }, [setThemeWithLocalStorage, themeFromTenant]);

  return (
    <CustomThemeContext.Provider
      value={{ theme: theme, setTheme: setThemeWithLocalStorage }}
    >
      <MyCssVarsProvider theme={theme.theme}>
        <CssVarsProvider
          theme={theme.theme}
          defaultColorScheme={getSelectedModeFromLocalStorage()}
        >
          {children}
        </CssVarsProvider>
      </MyCssVarsProvider>
    </CustomThemeContext.Provider>
  );
};
