import { IAssetDetailWithMetadata } from './assetDetailWithMetadata';

export type AIReviewStatus = 'readyToReview' | 'reviewed' | 'none';

export interface IAIReviewStatus {
  needsReview: boolean;
  assetsCount: number;
}
//'notAvailable' means there is no aiContent available for this asset. 'notSupported' means this type of asset can't have AI.
export type AIGenerationStatus =
  | 'inProgress'
  | 'done'
  | 'notAvailable'
  | 'notSupported';

export interface IAIContentState {
  aiReviewStatus: AIReviewStatus;
  aiGenerationStatus: AIGenerationStatus;
}

export type AssetReviewContentStatus =
  | 'pending'
  | 'completed'
  | 'error'
  | 'failed'
  | 'unsupported'
  | 'disabled';

export interface IItemForAIReview extends IAssetDetailWithMetadata {
  aiContentToReview: IAssetAIReviewContentSectionData[];
}
export interface IAssetAIReviewProps {
  isEnabled?: boolean;
  status?: AssetReviewContentStatus;
  handleToggleModification: () => void;
}
export interface IAssetAIContentSectionProps {
  aiValues: string | string[];
  reviewAIProps?: IAssetAIReviewProps;
  handleAIContentChange?: (content: string[] | string) => void;
}

export interface IAssetAIContentBE {
  smartKeywords?: string[];
  smartSummary?: string;
  smartTranscript?: string;
}

export type TAssetAIContent = Partial<
  Record<TAssetAIModules, IAssetAIContentSectionProps>
>;

export interface IAssetAIReviewContentSectionData {
  aiModuleType: TAssetAIModules;
  content: string | string[];
  status?: AssetReviewContentStatus;
  isEnabled: boolean;
}

//the values in this object are the same as what we receive from BE
//const assertion here allows us to use values as a type (see TAssetAIModules) or as values.
//Can easily be used as array also with Object.values().
export const ASSET_AI_MODULES = {
  SmartKeywords: 'smartKeywords',
  SmartSummary: 'smartSummary',
  SmartTranscript: 'smartTranscript'
} as const;

export type TAssetAIModules =
  typeof ASSET_AI_MODULES[keyof typeof ASSET_AI_MODULES];
