import Drawer from '@mui/material/Drawer';
import { EDrawerDepthLevel, useDrawer } from 'src/app/stores/drawerStore';
import './basic-drawer.scss';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { CustomIcons } from '../../dataDisplay/icons/CustomIcons';
import { useTranslation } from 'react-i18next';
import { AdminIcon } from 'src/app/pages/admin/_components/dataDisplay/AdminIcon';
import { useEffect, useState } from 'react';

export const BasicDrawer = () => {
  const theme = useTheme();
  const [t] = useTranslation();
  const {
    isOpen,
    changeOpen,
    content,
    header,
    drawerDepthLevel,
    changeDrawerDepthLevel,
    hideCloseButton
  } = useDrawer();

  const [bgColor, setBgColor] = useState(theme.vars.palette.background.paper);

  useEffect(() => {
    setBgColor(
      drawerDepthLevel === EDrawerDepthLevel.SECONDARY
        ? theme.vars.palette.background.default
        : theme.vars.palette.background.paper
    );
  }, [theme, drawerDepthLevel]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        changeDrawerDepthLevel(EDrawerDepthLevel.PRIMARY);
        changeOpen(false);
      }}
      className="drawer"
      PaperProps={{
        sx: {
          backgroundColor: bgColor,
          overflowX: 'hidden'
        }
      }}
    >
      <div
        className="drawer-header"
        style={{
          padding: header?.desc
            ? theme.spacing(4)
            : `${theme.spacing(3)} ${theme.spacing(4)}`
        }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={header?.desc ? theme.spacing(2) : theme.spacing(1)}
          >
            <AdminIcon
              iconName={header.iconName}
              containerStyles={{
                padding: header?.desc ? theme.spacing(0.5) : theme.spacing(1)
              }}
            />
            <Box className="drawer-header__text">
              <span>
                <Typography
                  variant="h3"
                  color={theme.vars.palette.text.secondary}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {header.title}
                </Typography>
              </span>
              {header.desc && (
                <span>
                  <Typography
                    variant={'body2'}
                    color={theme.vars.palette.text.secondary}
                  >
                    {header.desc}
                  </Typography>
                </span>
              )}
            </Box>
          </Box>
          {!hideCloseButton && (
            <IconButton
              className="btn-borderless"
              onClick={() => {
                changeDrawerDepthLevel(EDrawerDepthLevel.PRIMARY);
                changeOpen(false);
              }}
              aria-label={t('icon__close')}
              sx={{ backgroundColor: bgColor }}
            >
              <CustomIcons iconName="close" />
            </IconButton>
          )}
        </Box>
      </div>
      <div className="drawer-content">{content}</div>
    </Drawer>
  );
};
