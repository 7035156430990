import { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface IFlexbox extends BoxProps {
  children: ReactNode;
  verticallyCentered?: boolean;
  horizontallyCentered?: boolean;
}

export const Flexbox = ({
  children,
  verticallyCentered = false,
  horizontallyCentered = false,
  ...props
}: IFlexbox) => {
  return (
    <Box
      display={'flex'}
      alignItems={verticallyCentered ? 'center' : 'flex-start'}
      justifyContent={horizontallyCentered ? 'center' : 'flex-start'}
      {...props}
    >
      {children}
    </Box>
  );
};
