import { baseDarkColorScheme, baseLightColorScheme } from './baseColorScheme';
import i18next from 'i18next';
import { IThemeColorScheme, IThemeNaming } from '../customTheme';
import {
  getPrimaryDarkColorShade,
  getPrimaryLightColorShade
} from '../_components/utils/colors';

const themeNaming: IThemeNaming = {
  colorName: 'default',
  label: i18next.t('settings__color_scheme_label_default')
};

export const defaultLightColorScheme: IThemeColorScheme = {
  ...baseLightColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(227, 80%, 38%)',
    light: 'hsl(227, 80%, 44%)',
    dark: 'hsl(227, 80%, 33%)',
    variant: 'hsl(227, 80%, 33%)',
    shades: getPrimaryLightColorShade('227'),
    contrast: baseLightColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(227, 50%, 94%)',
    light: 'hsl(227, 50%, 96%)',
    dark: 'hsl(227, 49%, 90%)',
    contrast: 'hsl(227, 80%, 33%)'
  },
  accent: {
    main: 'hsl(17, 95%, 58%)'
  },
  action: {
    ...baseLightColorScheme.action,
    backdropOverlay: 'hsla(227, 82%, 13%, 0.5)'
  }
};

export const defaultDarkColorScheme: IThemeColorScheme = {
  ...baseDarkColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(225, 77%, 60%)',
    light: 'hsl(225, 77%, 73%)',
    dark: 'hsl(225, 77%, 56%)',
    variant: 'hsl(225, 77%, 56%)',
    shades: [
      ...getPrimaryDarkColorShade('225', '43%', ['31%', '35%', '39%', '45%']),
      'hsl(225, 47%, 53%)'
    ],
    contrast: baseDarkColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(227, 43%, 35%)',
    light: 'hsl(227, 43%, 38%)',
    dark: 'hsl(227, 43%, 31%)',
    contrast: baseDarkColorScheme.text.contrast
  },
  accent: {
    main: 'hsl(17, 95%, 58%)'
  },
  background: {
    paper: 'hsl(227, 38%, 26%)',
    default: 'hsl(227, 38%, 20%)'
  },
  action: {
    ...baseDarkColorScheme.action,
    disabled: 'hsl(227, 5%, 40%)',
    disableBg: 'hsla(227, 81%, 13%, 0.7)',
    backdropOverlay: 'hsla(234, 11%, 19%, 0.8)',
    divider: 'hsl(227, 30%, 18%)',
    outlineBorder: 'hsl(226, 35%, 34%)'
  },
  error: {
    main: 'hsl(359, 55%, 56%)',
    light: 'hsl(0, 100%, 70%)',
    shades: ['hsla(360, 42%, 35%, 0.6)', 'hsl(0, 43%, 24%)'],
    contrast: baseDarkColorScheme.text.contrast
  },
  success: {
    main: 'hsl(147, 54%, 39%)',
    shades: ['hsl(148, 54%, 39%)'],
    contrast: baseDarkColorScheme.text.contrast
  },
  warning: {
    main: 'hsl(39, 80%, 61%)',
    shades: ['hsl(40, 74%, 70%)'],
    contrast: 'hsl(38, 31%, 25%)'
  }
};
