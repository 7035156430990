import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDropdown } from 'src/app/utilities/hooks/useDropdown';
import { useSettingLanguageForm } from './useSettingLanguageForm';

import { LoadingButton } from '@mui/lab';
import { Language } from 'src/app/utilities/i18n';
import './settingLanguageForm.scss';
import { useResponsive } from 'src/app/utilities/hooks/useResponsive';

interface ISelectOption {
  label: string;
  code: Language;
}

const SELECT_OPTIONS: Record<Language, ISelectOption> = {
  [Language.enUS]: {
    label: 'English',
    code: Language.enUS
  },
  [Language.esES]: {
    label: 'Español',
    code: Language.esES
  },
  [Language.frCA]: {
    label: 'Français',
    code: Language.frCA
  }
};
//z-index to have the dropdown above the modal
const SELECT_ZINDEX = '2000';

export const SettingLanguageForm = () => {
  const { t } = useTranslation();
  const { breakpoints } = useResponsive();
  const {
    selectedLanguage,
    handleLanguageChange,
    allowedLanguages,
    handleLanguageSubmit,
    showSaveButton,
    isLanguageLoading
  } = useSettingLanguageForm();

  const { getSelectMenuProps, getSelectSxProps, theme } = useDropdown();

  const originalMenuProps = getSelectMenuProps(theme);

  const labelText =
    allowedLanguages?.length === 1
      ? t('settings__interface_language')
      : t('settings__select_language');

  const formElementMaxWidth = allowedLanguages?.length === 1 ? '7rem' : '15rem';

  return (
    <Box
      className="setting__form setting__language-form"
      sx={{
        gridTemplateColumns: `repeat( auto-fill, minmax(max-content, ${formElementMaxWidth} ))`
      }}
    >
      <InputLabel
        className="setting__language-label"
        id="settings-language-label"
        sx={{
          paddingTop: allowedLanguages?.length === 1 ? 0 : theme.spacing(2)
        }}
      >
        {labelText}
      </InputLabel>
      {allowedLanguages?.length === 0 && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="15rem"
          height="2.5rem"
        />
      )}
      {allowedLanguages?.length === 1 && (
        <Typography variant="body1">
          {SELECT_OPTIONS[selectedLanguage].label}
        </Typography>
      )}
      {allowedLanguages?.length > 1 && (
        <Box width={breakpoints.xxs ? 'auto' : '100%'}>
          <Select
            className="setting__language-select"
            labelId="setting-language-label"
            value={selectedLanguage}
            id="setting-language-select"
            onChange={handleLanguageChange}
            autoWidth={false}
            disabled={isLanguageLoading}
            sx={getSelectSxProps(theme)}
            MenuProps={{
              PaperProps: {
                className: 'setting__language-menu',
                sx: {
                  ...originalMenuProps?.PaperProps?.sx
                }
              },
              sx: {
                zIndex: SELECT_ZINDEX
              }
            }}
            inputProps={{
              sx: { paddingX: theme.spacing(1.5), paddingY: theme.spacing(2) }
            }}
          >
            {allowedLanguages.map((language) => {
              const option = SELECT_OPTIONS[language.key];

              if (option) {
                return (
                  <MenuItem
                    key={`setting-language-select-option-${option.code}`}
                    value={option.code}
                    sx={{
                      display:
                        selectedLanguage === option.code ? 'none' : 'block'
                    }}
                  >
                    {option.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
          <LoadingButton
            type="submit"
            variant="contained"
            className="setting__language-submit"
            endIcon={
              <Box sx={{ width: isLanguageLoading ? '20px' : '0' }}></Box>
            }
            sx={{
              display: showSaveButton ? 'flex' : 'none'
            }}
            onClick={handleLanguageSubmit}
            loading={isLanguageLoading}
            loadingPosition="end"
            disabled={isLanguageLoading}
          >
            {t('asset__detail_save')}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};
