import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledContainer: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xxl'
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.down('xxs')]: {
          paddingRight: theme.spacing(1.5),
          paddingLeft: theme.spacing(1.5),
          '&.no-container-padding-below-xxs': {
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0)
          }
        },
        [theme.breakpoints.up('xxs')]: {
          paddingRight: theme.spacing(4),
          paddingLeft: theme.spacing(4)
        },
        [theme.breakpoints.up('sm')]: {
          paddingRight: theme.spacing(10),
          paddingLeft: theme.spacing(10)
        }
      })
    }
  }
};
