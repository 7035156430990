import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledPaper: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        padding: theme.spacing(4),
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'unset',

        '&.MuiPopover-paper': {
          padding: '1rem'
        },

        '&.MuiPickersPopper-paper': {
          border:
            theme.palette.mode === 'dark'
              ? `1px solid ${theme.vars.palette.action.outlineBorder}`
              : ''
        }
      }),
      dashed: ({ theme }) => ({
        border: `2px dashed ${theme.vars.palette.divider}`,

        '&:hover': {
          borderStyle: 'solid'
        }
      })
    }
  }
};
