import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledPaper } from './paper';
import { styledAccordion } from './accordion';
import { styledAppBar } from './appBar';

export const styledSurfacesAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledPaper,
  ...styledAccordion,
  ...styledAppBar
};
