const adminLangs: Record<string, string> = {
  'en-US': 'en',
  'fr-CA': 'fr'
};

export function formatLoginRoute(language: string) {
  return formatRoute('login', adminLangs[language]);
}

export function formatLogoutRoute(language: string) {
  return formatRoute('logout', adminLangs[language]); // need formated lang key for login repo
}

function formatRoute(endpoint: string, language: string) {
  const url = `${
    process.env.REACT_APP_LOGIN_REDIRECT_URL
  }${endpoint}?redirectUri=${window.origin}&lang=${language}&redirectTo=${
    window.location.pathname + encodeURIComponent(window.location.search)
  }`;

  return url;
}
