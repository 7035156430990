import { Components, CssVarsTheme, Fade, Theme } from '@mui/material';

export const styledSnackbar: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiSnackbar: {
    defaultProps: {
      TransitionComponent: Fade,
      transitionDuration: { enter: 250, exit: 250 },
      TransitionProps: { easing: 'ease-out' },
      ClickAwayListenerProps: {
        onClickAway: () => null
      }
    },
    styleOverrides: {
      root: ({ theme }) => {
        return {
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',

          [theme.breakpoints.down('xxs')]: {
            bottom: theme.spacing(1.5),
            maxWidth: `calc(100% - ${theme.spacing(3)})`
          },
          [theme.breakpoints.up('xxs')]: {
            bottom: theme.spacing(2.5),
            maxWidth: '31.25rem' //500px
          }
        };
      }
    }
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: ({ theme }) => {
        return {
          backgroundColor: theme.vars.palette.primary.main,
          color: theme.vars.palette.primary.contrastText,
          boxShadow: theme.shadows[3],
          display: 'flex',
          flexFlow: ['row', 'nowrap'],
          flex: '0 1 100%',
          gap: theme.spacing(1),
          padding: theme.spacing(0.5, 0.5, 0.5, 1),
          minWidth: '18.75rem', //300px -- We need the minWidth in order to avoid content width to overflow the SnackbarWidth in mobile view (I know, it sounds crazy)

          [theme.breakpoints.up('xxs')]: {
            flex: '0 1 auto',
            gap: theme.spacing(2),
            padding: theme.spacing(1, 1, 1, 2)
          }
        };
      },
      message: () => {
        //set 2 lines as a maximum, with ellipsis.
        //if ellipsis should be applied to a portion of the message,
        //wrap this text in a tag with global '.ellipsis' class
        //(ex: <strong className='ellipsis'>text</strong>)
        return {
          flex: '2 1 auto',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          '.ellipsis': {
            display: 'inline-block',
            maxWidth: '100%',
            width: 'auto',
            verticalAlign: 'text-bottom'
          }
        };
      },
      action: ({ theme }) => {
        return {
          flex: '0 1 auto',
          margin: 0,
          padding: 0,
          gap: theme.spacing(1),

          [theme.breakpoints.up('xxs')]: {
            gap: theme.spacing(2)
          }
        };
      }
    }
  }
};
