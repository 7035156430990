import { IUserActivities } from 'src/app/api/models/users';

export class Claim {
  static Asset = class {
    static Read = 'Asset.Read';
    static Share = 'Asset.Share';
    static Download = 'Asset.Download';
    static Create = 'Asset.Create';
    static Update = 'Asset.Update';
    static Delete = 'Asset.Delete';
  };
  static Catalog = class {
    static Read = 'Catalog.Read';
    static Create = 'Catalog.Create';
    static Update = 'Catalog.Update';
    static Delete = 'Catalog.Delete';
  };
  static User = class {
    static Read = 'User.Read';
    static Create = 'User.Create';
    static Update = 'User.Update';
    static Delete = 'User.Delete';
  };
  static Permission = class {
    static Read = 'Permission.Read';
    static Create = 'Permission.Create';
    static Update = 'Permission.Update';
    static Delete = 'Permission.Delete';
  };
  static Tenant = class {
    static Read = 'Tenant.Read';
    static Create = 'Tenant.Create';
    static Update = 'Tenant.Update';
    static Delete = 'Tenant.Delete';
  };
  static Admin = class {
    static Dashboard = 'Admin.Dashboard';
  };
}

export class Claims {
  public userActivities: IUserActivities;

  constructor(userActivities: IUserActivities) {
    this.userActivities = userActivities;
  }

  hasClaim = (claim: Claim): boolean =>
    this.userActivities.global.includes(claim.toString()) ?? false;

  hasClaims = (claims: Claim[]): boolean =>
    claims.every((claim) => this.hasClaim(claim));

  // If the user has the claim in any of the catalogs
  hasClaimInAtLeastOneCatalog = (claim: Claim): boolean =>
    new Set(Object.values(this.userActivities.catalog).flat()).has(
      claim.toString()
    );

  hasCatalogClaim = (catalogId: string, claim: Claim): boolean =>
    this.userActivities.catalog[catalogId]?.includes(claim.toString()) ?? false;

  hasCatalogClaims = (catalogId: string, claims: Claim[]): boolean =>
    claims.every((claim) => this.hasCatalogClaim(catalogId, claim));

  // If the user has the claims in any of the catalogs from the list
  hasClaimsInAnyCatalogFromList = (
    claims: Claim[],
    catalogIds: string[]
  ): boolean => {
    return catalogIds.some((catalogId) =>
      claims.every((claim) => this.hasCatalogClaim(catalogId, claim))
    );
  };
}
