import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  FormControl,
  PaletteMode,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useThemePaletteValues } from 'src/styles/_theme/useThemePaletteValues';
import { useResponsive } from 'src/app/utilities/hooks/useResponsive';
import {
  HomepageThumbnailSVG,
  IHomeThumbnailColors
} from './HomepageThumbnailSVG';

import { useSettingAppearanceForm } from './useSettingAppearanceForm';
import './settingAppearanceForm.scss';

export const SettingAppearanceForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { breakpoints } = useResponsive();
  const { getPaletteValues } = useThemePaletteValues();
  const { currentMode, handleThemeModeChange } = useSettingAppearanceForm();

  const modeOptions = [
    {
      label: t('settings__select_appearance_option_light'),
      value: 'light'
    },
    {
      label: t('settings__select_appearance_option_dark'),
      value: 'dark'
    }
  ];

  const getSVGColors = (paletteMode: PaletteMode): IHomeThumbnailColors => {
    const palette = getPaletteValues({ mode: paletteMode });
    return {
      windowBorder: palette.background.default,
      browserBackground: palette.background.paper,
      browserDot1: palette.text.primary,
      browserDot2: palette.primary.shades[2],
      pageBackground: palette.background.default,
      headerBackground: palette.background.paper,
      headerLogo: palette.primary.main,
      headerDot1: palette.primary.shades[2],
      headerDot2: palette.primary.shades[2],
      uploadBackground: palette.primary.shades[1],
      uploadBorder: palette.primary.main,
      uploadButton: palette.primary.main,
      uploadInstructions: palette.primary.main,
      libraryBackground: palette.background.paper,
      libraryTitle: palette.text.primary,
      librarySubtitle: palette.text.disabled,
      libraryCard: palette.background.default
    };
  };

  return (
    <Box className="setting__form setting-appearance-form">
      <Box className="setting-appearance-form-title">
        <Typography
          variant={breakpoints.xs ? 'subtitle1' : 'subtitle2'}
          color={theme.vars.palette.text.primary}
          fontWeight={600}
        >
          {t('settings__select_appearance_title')}
        </Typography>

        <Typography variant="body2" color={theme.vars.palette.text.secondary}>
          {t('settings__select_appearance_legend')}
        </Typography>
      </Box>

      <FormControl component={'fieldset'}>
        <RadioGroup
          row
          aria-labelledby="color-mode-choice"
          name="color-mode"
          value={currentMode}
          className="setting-appearance-form-section"
        >
          {modeOptions.map((option, index) => {
            return (
              <Card
                key={index}
                variant="elevation"
                sx={{
                  outline: `1px solid ${
                    currentMode === option.value
                      ? theme.vars.palette.primary.main
                      : theme.vars.palette.action.outlineBorder
                  }`
                }}
                className="setting-appearance-form-section-card"
                onClick={() => handleThemeModeChange(option.value)}
              >
                <CardActionArea>
                  <CardHeader
                    title={
                      <Typography variant="subtitle1">
                        {option.label}
                      </Typography>
                    }
                    avatar={
                      <Radio
                        checked={currentMode === (option.value as PaletteMode)}
                        size="small"
                        sx={{
                          padding: theme.spacing(0.825)
                        }}
                      />
                    }
                    className="setting-appearance-form-section-card-header"
                  />
                  <CardContent className="setting-appearance-form-section-card-content">
                    <HomepageThumbnailSVG
                      colors={getSVGColors(option.value as PaletteMode)}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
