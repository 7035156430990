import { styledSkeleton } from './skeleton';
import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledDialog } from './dialog';
import { styledAlert } from './alert';
import { styledSnackbar } from './snackbar';
import { PROGRESS_BAR_TRANSITION_DURATION } from 'src/app/utilities';

export const styledFeedbackAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.vars.shape.borderRadius,
        marginRight: theme.spacing(1)
      }),
      bar: () => ({
        transitionDuration: `${PROGRESS_BAR_TRANSITION_DURATION}`
      })
    }
  },
  ...styledDialog,
  ...styledAlert,
  ...styledSkeleton,
  ...styledSnackbar
};
