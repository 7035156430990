import { changeLanguage } from 'i18next';
import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMyUser, updateMyUser } from 'src/app/api/controllers/users';
import { getTenantLanguages } from 'src/app/api/controllers/tenants';
import {
  IJsonPatchOperation,
  JsonPatchOperationEnum
} from 'src/app/api/jsonPatch';
import { useAuth } from 'src/app/stores';
import { LANGUAGE_LOCALSTORAGE_KEY } from '../constants';
import { Language, defaultLanguage } from '../i18n';
import { getLocaleRouteFromUrl } from '../navigation';
import { ITenantLanguage } from 'src/app/api/models/tenants';

/**
 * Allow to update the language for a user and in the navigation
 * Warning, this hook need to be use inside the RouterContext because is only working with the useNavigate from react-router-dom
 */
export const useUserLanguage = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useAuth();
  const { pathname } = useLocation();
  const [allowedLanguages, setAllowedLanguages] = useState<ITenantLanguage[]>(
    []
  );

  const getLanguageFromLocalStorage = () => {
    return (
      (globalThis.localStorage.getItem(
        LANGUAGE_LOCALSTORAGE_KEY
      ) as Language) || getDefaultTenantLanguage()
    );
  };

  const updateInterfaceLanguage = (language: string) => {
    changeLanguage(language);
    globalThis.localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, language);
  };

  /**
   * Update the Interface Language with the language from the User settings, without any navigation update
   * @param userLanguage the language from the user settings
   */
  const updateInterfaceLanguageOnLogin = async (userLanguage?: Language) => {
    const tenantLanguages = await getAllowedLanguages();
    const currentLanguage = getLanguageFromLocalStorage();

    let language: Language =
      tenantLanguages.filter((l) => l.isDefaultInterfaceLanguage)[0]?.key ||
      currentLanguage;

    if (userLanguage && tenantLanguages.find((l) => l.key === userLanguage)) {
      language = userLanguage;
    }

    updateInterfaceLanguage(language);
  };

  const getAllowedLanguages = useCallback(async (): Promise<
    ITenantLanguage[]
  > => {
    const controller = new AbortController();
    try {
      const tenantLanguages = await getTenantLanguages(controller);
      if (tenantLanguages?.data?.length > 0) {
        setAllowedLanguages(tenantLanguages.data);
        return tenantLanguages.data;
      }

      return [];
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('getUserLanguage', err);
      }
      throw new Error('Error on user update');
    }
  }, []);

  /**
   * Changes the language in the app and update the route
   * @param language allowed tenant language
   */
  const changeInterfaceLanguage = async (language: Language) => {
    try {
      const newRoute = getLocaleRouteFromUrl({
        url: pathname,
        locale: language
      });

      const searchParams = window.location.search;

      if (newRoute) {
        updateInterfaceLanguage(language);

        navigate({ pathname: newRoute, search: searchParams });
      }
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('changeUserLanguage', err);
      }
      throw new Error('Error on user update');
    }
  };

  /**
   * Saves user language in backend and changes interface language
   * @param language allowed tenant language
   */
  const setUserLanguage = async (language: Language) => {
    updateInterfaceLanguage(language);

    if (userInfo?.id) {
      const controller = new AbortController();
      const me = await getMyUser();
      const lastModifiedDate =
        me.data.modifiedDateTime ?? me.data.createdDateTime;

      if (!lastModifiedDate) {
        return;
      }

      const operations: IJsonPatchOperation[] = [
        {
          op: JsonPatchOperationEnum.REPLACE,
          path: `/preferredlanguage`,
          value: language
        }
      ];

      const updatedUser = await updateMyUser(
        operations,
        encodeURIComponent(lastModifiedDate),
        controller
      );

      setUserInfo(updatedUser.data);
    }
  };

  const setLanguageFromLocalStorage = () => {
    const currentLanguage = getLanguageFromLocalStorage();

    changeInterfaceLanguage(currentLanguage);
  };

  const getDefaultTenantLanguage = () => {
    return (
      allowedLanguages.find(
        (allowedLanguage) => allowedLanguage.isDefaultInterfaceLanguage === true
      )?.key ||
      allowedLanguages[0]?.key ||
      defaultLanguage
    );
  };

  return {
    updateInterfaceLanguageOnLogin,
    changeInterfaceLanguage,
    allowedLanguages,
    getAllowedLanguages,
    setUserLanguage,
    setLanguageFromLocalStorage,
    getDefaultTenantLanguage
  };
};
