import { AssetGroupsMetadataList } from 'src/app/api/controllers/metadata';
import { StateCreator } from 'zustand';
import { uploadResetters } from '../uploadStore';

//need to declare initial state like this to be able to reset it
const step2InitialState = {
  isValidating: false,
  isErrorViewVisible: false,
  triedContinueOnce: false,
  metadata: {}
};

export interface IStep2Slice {
  isValidating: boolean;
  isErrorViewVisible: boolean;
  metadata: AssetGroupsMetadataList;
  triedContinueOnce: boolean;
  setIsValidating: (visible: boolean) => void;
  setIsErrorViewVisible: (visible: boolean) => void;
  setMetadata: (metadata: AssetGroupsMetadataList) => void;
  setTriedContinueOnce: (value: boolean) => void;
}

export const createStep2Slice: StateCreator<IStep2Slice> = (set) => {
  uploadResetters.push(() => set(step2InitialState));

  return {
    ...step2InitialState,
    setIsValidating: (visible: boolean) =>
      set(() => ({ isValidating: visible })),
    setIsErrorViewVisible: (visible: boolean) =>
      set(() => ({ isErrorViewVisible: visible })),
    setMetadata: (value: AssetGroupsMetadataList) =>
      set(() => ({ metadata: value })),
    setTriedContinueOnce: (value: boolean) =>
      set(() => ({ triedContinueOnce: value }))
  };
};
