import { useState } from 'react';
import { useValidateOnBlur } from './useValidateOnBlur';
import {
  getSelectMenuProps,
  getSelectSxProps
} from 'src/styles/_theme/_components/inputs/dropdownProps';
import clsx from 'clsx';
import { useTheme } from '@mui/material';

export const useDropdown = (
  error?: boolean,
  values?: string[],
  onChange?: (values: string[]) => void
) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { hasError, setHasError, validateField } = useValidateOnBlur(error);
  const dropDownClass = clsx({ open: isOpen });

  const onDelete = (value: string) => {
    if (!onChange || !values) return;

    const newValues = values.filter((val) => val !== value);
    onChange(newValues);
  };

  return {
    isOpen,
    setIsOpen,
    onDelete,
    hasError,
    setHasError,
    validateField,
    dropDownClass,
    getSelectMenuProps,
    getSelectSxProps,
    theme
  };
};
