import { LoaderFunctionArgs } from 'react-router-dom';
import {
  getAssetDetailWithMetadata,
  getDetailedAssets
} from 'src/app/api/controllers/assets';
import { IAssetDetailWithMetadata } from 'src/app/api/models/assetDetailWithMetadata';

export async function loadSingleAsset({
  request
}: LoaderFunctionArgs): Promise<IAssetDetailWithMetadata | undefined> {
  const url = new URL(request.url);
  const id = url.searchParams.get('id');
  if (id !== null) {
    const res = await getAssetDetailWithMetadata(id, request.signal);
    const assetDetail = res.data;

    return assetDetail;
  }

  return undefined;
}

export async function loadMultipleAsset({
  request
}: LoaderFunctionArgs): Promise<IAssetDetailWithMetadata[] | undefined> {
  const url = new URL(request.url);
  const ids = url.searchParams.getAll('id');

  if (ids !== undefined && ids.length > 0) {
    const res = await getDetailedAssets(ids, request.signal);
    const assetDetail = res.map((r) => r.data);

    return assetDetail;
  }

  return undefined;
}
