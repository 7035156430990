import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

//ROUTING
import { IRoute, IRouteWithLoader } from './Routes';

import homeRoute from 'src/app/pages/home/home.routes';
import shareRoutes, {
  shareRedirectPath
} from 'src/app/pages/share/share.routes';
import uikitRoutes from 'src/app/pages/uikit/uikit.routes';
import {
  adminRoutes,
  usersAndGroupsRoutes,
  catalogsRoutes,
  attributesRoutes,
  companySettingsRoutes,
  advancedFiltersRoutes
} from 'src/app/pages/admin/admin.routes';
import {
  viewAssetsRoute,
  editAssetsRoute,
  uploadAssetsRoute,
  aiReviewAssetsRoute
} from 'src/app/pages/assets/assets.routes';
import {
  loginRoute,
  forgotPasswordRoute,
  newPasswordRequiredRoute
} from 'src/app/pages/user/user.routes';
import GuardedRoute from './GuardedRoute';
import notFoundRoute from 'src/app/pages/notFound/notFound.routes';

//COmponents
import ConnectedAdminLayout from 'src/app/pages/admin/_components/layout/ConnectedAdminLayout';
import ConnectedLayout from 'src/app/components/layout/connectedLayout/ConnectedLayout';
import { ErrorBoundary } from 'src/app/pages/error/ErrorBoundary';
import { ShareWrapper } from '../pages/share/ShareWrapper';
import { EmptyRoute } from './EmptyRoute';
import { AdminError } from '../pages/admin/AdminError';
import AsmblRouterWrapper from './AsmblRouterWrapper';

interface IRoutes {
  connectedRoutes: IRoute[];
  connectedRoutesWithLoader: Array<IRouteWithLoader>;
  authRoutes: IRoute[];
  connectedAdminRoutes: IRoute[];
  connectedAdminRoutesWithLoader: IRouteWithLoader[];
  shareAssetRoutes: IRouteWithLoader[];
}

export const ROUTES: IRoutes = {
  //routes in connectedRoutes will have the header and the footer
  connectedRoutes: [
    homeRoute,
    uploadAssetsRoute,
    uikitRoutes,
    aiReviewAssetsRoute
  ],
  connectedRoutesWithLoader: [viewAssetsRoute, editAssetsRoute],
  authRoutes: [loginRoute, forgotPasswordRoute, newPasswordRequiredRoute],
  connectedAdminRoutes: [
    adminRoutes,
    usersAndGroupsRoutes,
    attributesRoutes,
    advancedFiltersRoutes,
    companySettingsRoutes
  ],
  connectedAdminRoutesWithLoader: [catalogsRoutes],
  shareAssetRoutes: [shareRoutes]
};

// Use `replace` in Navigate to avoid adding the same route to the history stack

// Keep the creation of the router outside of the component tree to avoid re-creating it on every render (which cause the loader to be render twice)
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AsmblRouterWrapper />}>
      <Route path="" element={<EmptyRoute />} />
      {/* Base application route */}
      <Route element={<ConnectedLayout />} errorElement={<ErrorBoundary />}>
        {/* redirects the path "/" to "/[locale]/[home slug]" */}
        {/* Local (/en) */}
        {/* redirects the path "/[locale]/" to "/[locale]/[home slug]" */}
        {Object.keys(homeRoute.paths).map((locale, index) => (
          <Route
            key={index}
            path={locale}
            element={<Navigate to={homeRoute.paths[locale]} replace />}
          />
        ))}
        {ROUTES.connectedRoutes.map((route) =>
          Object.values(route.paths).map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <GuardedRoute claims={route.requiredClaims}>
                  <route.component />
                </GuardedRoute>
              }
            />
          ))
        )}
        {ROUTES.connectedRoutesWithLoader.map((route) =>
          Object.values(route.paths).map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <GuardedRoute claims={route.requiredClaims}>
                  <route.component />
                </GuardedRoute>
              }
              loader={route.loader}
            />
          ))
        )}
      </Route>

      {/* Admin route */}
      <Route element={<ConnectedAdminLayout />} errorElement={<AdminError />}>
        {/* Admin route without loader */}
        {ROUTES.connectedAdminRoutes.map((route) =>
          Object.values(route.paths).map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <GuardedRoute claims={route.requiredClaims}>
                  <route.component />
                </GuardedRoute>
              }
            />
          ))
        )}

        {/* Admin route with loader */}
        {ROUTES.connectedAdminRoutesWithLoader.map((route) =>
          Object.values(route.paths).map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <GuardedRoute claims={route.requiredClaims}>
                  <route.component />
                </GuardedRoute>
              }
              loader={route.loader}
            />
          ))
        )}
      </Route>

      {/* Login/Auth */}
      {/* Those route are only keep to get the visual component without acceding it  */}
      {ROUTES.authRoutes.map((route) =>
        Object.values(route.paths).map((path, index) => (
          <Route key={index} path={path} element={<route.component />} />
        ))
      )}

      {/* Share */}
      {ROUTES.shareAssetRoutes.map((route) =>
        Object.values(route.paths).map((path, index) => (
          <Route
            key={index}
            path={path}
            loader={route.loader}
            element={
              <ShareWrapper>
                <shareRoutes.component />
              </ShareWrapper>
            }
          />
        ))
      )}

      {shareRedirectPath.map((path, index) => (
        <Route
          key={index}
          path={path.oldUrl}
          element={
            <main>
              <shareRoutes.component />
            </main>
          }
        />
      ))}

      {/* Not found */}
      <Route path="*" element={<notFoundRoute.component />} />
    </Route>
  )
);

export const Router = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
