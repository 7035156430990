import {
  BasicPaletteColorChannel,
  ColorSystem,
  PaletteColor,
  PaletteMode,
  Theme,
  TypeBackground
} from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export type IPaletteColors = 'primary' | 'secondary' | 'error' | 'info';
export type IBackgroundColors = 'background';
export type IBackgroundColor = 'default' | 'paper';
export type IPaletteColor =
  | 'light'
  | 'main'
  | 'dark'
  | 'contrastText'
  | 'shades';
interface IGetThemeColorsInLightAndWhiteMode {
  theme: Theme;
  mode: PaletteMode;
  paletteColor?: IPaletteColors;
  backgroundColor?: IBackgroundColors;
  subPaletteColor?: IPaletteColor;
  subBackgroundColor?: IBackgroundColor;
  colorIndex?: number;
}

export const getPrimaryLightColorShade = (hue: string): string[] => {
  return [
    `hsl(${hue}, 50%, 96%)`,
    `hsl(${hue}, 50%, 94%)`,
    `hsl(${hue}, 49%, 90%)`,
    `hsl(${hue}, 47%, 81%)`,
    `hsl(${hue}, 41%, 66%)`
  ];
};

export const getPrimaryDarkColorShade = (
  hue: string,
  saturation: string,
  shadesLightness: string[]
): string[] => {
  const shades: string[] = shadesLightness.map((shadeLightness) => {
    return `hsl(${hue}, ${saturation}, ${shadeLightness})`;
  });

  return shades;
};

export const getHslValues = (hslArray: string[]): string[] => {
  const shadeChannels = hslArray.map((hslItem) => {
    // removes hsl()
    const hslBracesContent: string = hslItem.match(/\(([^)]+)\)/)?.[1] ?? '';
    // removes ,
    const hslValues: string = hslBracesContent.replaceAll(/[,]/g, '');
    return hslValues;
  });

  return shadeChannels;
};

export const getThemeColorsInLightAndWhiteMode = ({
  theme,
  mode,
  paletteColor,
  backgroundColor,
  subPaletteColor,
  subBackgroundColor,
  colorIndex
}: IGetThemeColorsInLightAndWhiteMode): string => {
  const colorScheme = theme.colorSchemes[mode] as ColorSystem;
  let colorToReturn;

  if (paletteColor && subPaletteColor) {
    const colorPalette = colorScheme.palette[paletteColor] as PaletteColor &
      BasicPaletteColorChannel;
    colorToReturn = colorPalette[subPaletteColor] as string;

    if (subPaletteColor === 'shades' && colorIndex) {
      colorToReturn = colorToReturn[colorIndex ?? 1];
    } else if (subPaletteColor === 'shades' && !colorIndex) {
      console.error('Add a ColorIndex to use shades');
    }
  } else if (backgroundColor && subBackgroundColor) {
    const colorPalette = colorScheme.palette[backgroundColor] as TypeBackground;
    colorToReturn = colorPalette[subBackgroundColor] as string;
  } else if (
    (paletteColor && !subPaletteColor) ||
    (backgroundColor && !subBackgroundColor)
  ) {
    console.error('wrong color and subcolor associations');
  }

  const defaultColor =
    mode === DEFAULT_COLOR_MODE
      ? theme.palette.text.contrast
      : theme.palette.background.default;

  return colorToReturn ?? defaultColor;
};
