import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledDataDisplayAll } from './dataDisplay/_dataDisplayAll';
import { styledFeedbackAll } from './feedback/_feedbackAll';
import { styledInputsAll } from './inputs/_inputsAll';
import { styledLayoutAll } from './layout/_layoutAll';
import { styledNavigationAll } from './navigation/_navigationAll';
import { styledSurfacesAll } from './surfaces/_surfacesAll';
import { styledUtilsAll } from './utils/_utilsAll';

export const styledMuiComponents: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledDataDisplayAll,
  ...styledFeedbackAll,
  ...styledInputsAll,
  ...styledLayoutAll,
  ...styledNavigationAll,
  ...styledSurfacesAll,
  ...styledUtilsAll
};
