import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledDrawer: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiDrawer: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiDrawer-modal': {
          '.MuiBackdrop-root': {
            backgroundColor: theme.vars.palette.action.disabledBackground
          },
          '.MuiPaper-root:not(.autocomplete-paper):not(.autocomplete-base__paper):not(.attributes-card):not(.mobile-menu__paper):not(.mobile-search__paper):not(.swipeable-drawer):not(.mobile-menu__settings):not(.setting-appearance-form-section-card):not(.mobile-menu__header)':
            {
              width: '41rem',
              borderRadius: '0.5rem 0 0 0.5rem',
              padding: 0,
              gap: 'unset'
            }
        }
      })
    }
  }
};
