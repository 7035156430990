import { useMemo } from 'react';
import { t } from 'i18next';
import {
  Box,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemText,
  useTheme
} from '@mui/material';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom'; //use 'RouterLink' name to avoid confusion with Mui Link
import { useSettings } from 'src/app/stores/settingsStore';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';
import { ISettingsSection } from './SettingsMain';
export interface ISettingsMainNav {
  navItems: ISettingsSection[];
}

export interface ILinkSections {
  navLabel: string;
  link: string;
}

export const SettingsMainNav = ({ navItems }: ISettingsMainNav) => {
  const theme = useTheme();
  const { selectedSectionIndex, setSelectedSectionIndex } = useSettings();

  const linkSections: ILinkSections[] = useMemo(
    () => [
      {
        navLabel: t('settings__sections_nav_policy_privacy_title'),
        link: t('settings__sections_nav_policy_privacy_link')
      }
    ],
    [navItems]
  );

  const NavItems = useMemo(() => {
    return navItems.map((item, index) => {
      const selected = index === selectedSectionIndex;
      return (
        <ListItemButton
          key={`settings-nav-${index}`}
          onClick={() => setSelectedSectionIndex(index)}
          selected={selected}
          className="settings__nav_item"
        >
          <ListItemText className={clsx({ 'selected-item': selected })}>
            {item.title}
          </ListItemText>
        </ListItemButton>
      );
    });
  }, [navItems, selectedSectionIndex, setSelectedSectionIndex]);

  const LinkItems = useMemo(() => {
    return linkSections.map((item, index) => (
      <Link
        component={RouterLink}
        key={`settings-nav-${index}`}
        to={item.link}
        target="_blank"
        className={'link-external'}
        sx={{ margin: theme.spacing(0.5, 1.5) }}
      >
        <CustomIcons iconName="external-link" />
        {item.navLabel}
      </Link>
    ));
  }, [theme]);

  return (
    <Box className="settings__main_nav">
      <List component="nav">{NavItems}</List>
      <Divider />
      <List component="nav">{LinkItems}</List>
    </Box>
  );
};
