import { LoaderFunctionArgs } from 'react-router-dom';
import { IAssetCatalogDetails } from '../../api/admin/models/assetCatalogs';
import { getAssetCatalogsDetails } from '../../api/admin/controllers/assetCatalogs';

export async function loadCatalogsAssets({
  request
}: LoaderFunctionArgs): Promise<IAssetCatalogDetails[] | undefined> {
  const res = await getAssetCatalogsDetails(request.signal);

  const catalogs = res.data.map((resp) => ({
    id: resp.id,
    name: resp.name,
    assetCount: resp.assetCount
  }));
  return catalogs;
}
