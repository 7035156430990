const squares0 = (
  <svg
    width="351"
    height="195"
    viewBox="0 0 351 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade2"
      d="M74.0001 60L132 60L132 0.999989L74.0001 0.999983L74.0001 60Z"
    />
    <path
      className="color-shade2"
      d="M173.473 80.5586L230.997 80.5586L230.997 37.9158L173.473 37.9158L173.473 80.5586Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade2"
      d="M-3.8147e-06 98.5234L47.9552 98.5234L47.9552 63.9978L-7.96378e-07 63.9978L-3.8147e-06 98.5234Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade2"
      d="M239.922 194.176L239.922 169L229 169L229 194.176L239.922 194.176Z"
      fillOpacity="0.5"
    />
    <path className="color-shade2" d="M287 81L330 81L330 38L287 38L287 81Z" />
    <path
      className="color-shade2"
      d="M337 17L351 17L351 3.24249e-05L337 3.1201e-05L337 17Z"
    />
    <path
      className="color-shade1"
      d="M229 134.559L266.561 134.559L266.561 96.9974L229 96.9974L229 134.559Z"
    />
    <path
      className="color-shade1"
      d="M267 157L287 157L287 137L267 137L267 157Z"
    />
  </svg>
);

const squares1 = (
  <svg
    width="334"
    height="82"
    viewBox="0 0 334 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade0"
      d="M297.597 26.078H242.025V82H297.597V26.078Z"
    />
    <path className="color-shade1" d="M203.836 0H160.17V32.2106H203.836V0Z" />
    <path
      className="color-shade2"
      d="M327 0H297.598V26.0792H334V7C334 3.13401 330.866 0 327 0Z"
      fillOpacity="0.6"
    />
    <path className="color-shade1" d="M104.439 0H85.3278V8.24997H104.439V0Z" />
    <path className="color-shade0" d="M45.8786 0H0V32.2106H45.8786V0Z" />
    <path
      className="color-shade2"
      d="M132.961 39.3294H104.448V67.7016H132.961V39.3294Z"
      fillOpacity="0.6"
    />
  </svg>
);

const squares2 = (
  <svg
    width="215"
    height="53"
    viewBox="0 0 215 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
  >
    <path
      className="color-shade2"
      d="M191.567 16.8553H155.795V53H191.567V16.8553Z"
    />
    <path className="color-shade2" d="M131.212 0H103.104V20.8191H131.212V0Z" />
    <path
      className="color-shade3"
      d="M208 0H191.567V16.8561H215V7C215 3.13401 211.866 0 208 0Z"
    />
    <path className="color-shade2" d="M67.2287 0H54.9266V5.3323H67.2287V0Z" />
    <path
      className="color-shade2"
      d="M29.5327 0H0V20.8191H29.5327V0Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade3"
      d="M85.5886 25.4202H67.2348V43.7584H85.5886V25.4202Z"
    />
  </svg>
);

const squares3 = (
  <svg
    width="271"
    height="324"
    viewBox="0 0 271 324"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade2"
      d="M270.636 230H179V284.581H270.636V230Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade1"
      d="M176 249L176 155L74.9999 155L74.9999 249L176 249Z"
    />
    <path
      className="color-shade2"
      d="M149.896 97L149.896 23L116.896 23L116.896 97L149.896 97Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade2"
      d="M149.25 323.249L149.25 285L111 285L111 323.249L149.25 323.249Z"
    />
    <path
      className="color-shade2"
      d="M75.4808 155.426L75.4808 121L41.0549 121L41.0549 155.426L75.4808 155.426Z"
    />
    <path
      className="color-shade1"
      d="M94.0009 38L94.0009 -3.8147e-05L56.0009 -3.9808e-05L56.0008 38L94.0009 38Z"
    />
    <path
      className="color-shade2"
      d="M23 257L23 234L-1.90735e-06 234L-2.91271e-06 257L23 257Z"
      fillOpacity="0.5"
    />
  </svg>
);

const squares4 = (
  <svg
    width="509"
    height="113"
    viewBox="0 0 509 113"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade0"
      d="M7 113L69.8247 113L69.8247 71.3451L-3.64159e-06 71.3451L-6.11959e-07 106C-2.73984e-07 109.866 3.134 113 7 113Z"
    />
    <path
      className="color-shade2"
      d="M69.8248 71.3449L141.057 71.3449L141.057 -1.52588e-05L69.8248 -9.03143e-06L69.8248 71.3449Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade2"
      d="M244.662 113L315.895 113L315.895 56.8418L244.662 56.8418L244.662 113Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade0"
      d="M360.415 112.738L416.801 112.738L416.801 87.5527L360.415 87.5528L360.415 112.738Z"
    />
    <path
      className="color-shade1"
      d="M155.96 102.77L185.105 102.77L185.105 73.579L155.96 73.579L155.96 102.77Z"
    />
    <path
      className="color-shade0"
      d="M315.895 56.8415L342.127 56.8415L342.127 30.5685L315.895 30.5685L315.895 56.8415Z"
    />
    <path
      className="color-shade2"
      d="M425.945 84.6338L454.9 84.6338L454.9 55.6331L425.945 55.6331L425.945 84.6338Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade0"
      d="M491.475 96.8447L509 96.8447L509 79.2917L491.475 79.2917L491.475 96.8447Z"
    />
  </svg>
);

const squares5 = (
  <svg
    width="300"
    height="67"
    viewBox="0 0 300 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade3"
      d="M7 67L41.1541 67L41.1541 42.302L-2.15917e-06 42.302L-6.11959e-07 60C-2.73984e-07 63.866 3.13401 67 7 67Z"
    />
    <path
      className="color-shade2"
      d="M41.1541 42.3018L83.138 42.3018L83.138 -7.62939e-06L41.1541 -3.95905e-06L41.1541 42.3018Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade2"
      d="M144.202 67L186.186 67L186.186 33.7027L144.202 33.7027L144.202 67Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade2"
      d="M212.425 66.8444L245.659 66.8444L245.659 51.9118L212.425 51.9118L212.425 66.8444Z"
    />
    <path
      className="color-shade2"
      d="M91.9216 60.9344L109.099 60.9344L109.099 43.6265L91.9216 43.6265L91.9216 60.9344Z"
    />
    <path
      className="color-shade3"
      d="M186.186 33.7025L201.647 33.7025L201.647 18.1247L186.186 18.1247L186.186 33.7025Z"
    />
    <path
      className="color-shade2"
      d="M251.048 50.1811L268.114 50.1811L268.114 32.986L251.048 32.986L251.048 50.1811Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade3"
      d="M289.671 57.4212L300 57.4212L300 47.0136L289.671 47.0136L289.671 57.4212Z"
    />
  </svg>
);

const squares6 = (
  <svg
    width="258"
    height="39"
    viewBox="0 0 258 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade1"
      d="M27.9995 18L45.9995 18L45.9995 1.90735e-06L27.9995 3.33738e-07L27.9995 18Z"
    />
    <path className="color-shade1" d="M101 39L130 39L130 17L101 17L101 39Z" />
    <path
      className="color-shade0"
      d="M-1.33514e-05 38.6641L28.1235 38.6641L28.1235 18.4164L-1.15813e-05 18.4164L-1.33514e-05 38.6641Z"
    />
    <path className="color-shade1" d="M172 39L197 39L197 28L172 28L172 39Z" />
    <path className="color-shade1" d="M232 39L258 39L258 20L232 20L232 39Z" />
    <path className="color-shade0" d="M145 18L158 18L158 5L145 5L145 18Z" />
    <path className="color-shade0" d="M223 22L232 22L232 13L223 13L223 22Z" />
  </svg>
);

const squares7 = (
  <svg
    width="92"
    height="32"
    viewBox="0 0 92 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade1"
      d="M8.29697e-05 21.1985L11.8032 21.1985L11.8032 11.4146L8.3825e-05 11.4146L8.29697e-05 21.1985Z"
    />
    <path
      className="color-shade0"
      d="M16.8616 31.7977L36.2524 31.7977L36.2524 22.0138L16.8616 22.0138L16.8616 31.7977Z"
    />
    <path
      className="color-shade0"
      d="M16.8617 6.52258L24.4495 6.52258L24.4495 -3.43323e-05L16.8617 -3.49956e-05L16.8617 6.52258Z"
      fillOpacity="0.6"
    />
    <path
      className="color-shade0"
      d="M46.3695 17.9373L58.1726 17.9373L58.1726 8.15333L46.3695 8.15333L46.3695 17.9373Z"
    />
    <path
      className="color-shade0"
      d="M68.2896 32L85 32C88.866 32 92 28.866 92 25L92 15.4915L68.2896 15.4915L68.2896 32Z"
    />
  </svg>
);

const squares8 = (
  <svg
    width="509"
    height="113"
    viewBox="0 0 509 113"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade3"
      d="M0 113L69.8247 113L69.8247 71.3451L-3.64159e-06 71.3451L0 113Z"
    />
    <path
      className="color-shade2"
      d="M69.8248 71.3447L141.057 71.3447L141.057 -0.000152588L69.8248 -0.000146361L69.8248 71.3447Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade2"
      d="M244.662 113L315.895 113L315.895 56.8418L244.662 56.8418L244.662 113Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade2"
      d="M360.415 112.737L416.801 112.737L416.801 87.5525L360.415 87.5525L360.415 112.737Z"
    />
    <path
      className="color-shade2"
      d="M155.96 102.77L185.105 102.77L185.105 73.579L155.96 73.579L155.96 102.77Z"
    />
    <path
      className="color-shade3"
      d="M315.895 56.8413L342.127 56.8413L342.127 30.5683L315.895 30.5683L315.895 56.8413Z"
    />
    <path
      className="color-shade2"
      d="M425.945 84.6338L454.9 84.6338L454.9 55.6331L425.945 55.6331L425.945 84.6338Z"
      fillOpacity="0.5"
    />
    <path
      className="color-shade3"
      d="M491.475 96.8447L509 96.8447L509 79.2917L491.475 79.2917L491.475 96.8447Z"
    />
  </svg>
);

const squares9 = (
  <svg
    width="334"
    height="82"
    viewBox="0 0 334 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="color-shade3"
      d="M297.597 26.0781H242.025V82.0001H297.597V26.0781Z"
    />
    <path d="M203.836 0H160.17V32.2106H203.836V0Z" className="color-shade2" />
    <path
      className="color-shade3"
      d="M334 0H297.598V26.0792H334V0Z"
      fillOpacity="0.5"
    />
    <path className="color-shade2" d="M104.439 0H85.3278V8.24997H104.439V0Z" />
    <path className="color-shade3" d="M45.8786 0H0V32.2106H45.8786V0Z" />
    <path
      className="color-shade3"
      d="M132.961 39.3296H104.448V67.7018H132.961V39.3296Z"
      fillOpacity="0.5"
    />
  </svg>
);

const squares10 = (
  <svg
    width="351"
    height="195"
    viewBox="0 0 351 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74 60L132 60L132 0.999981L74 0.999976L74 60Z"
      className="color-shade2"
    />
    <path
      d="M173.473 80.5586L230.997 80.5586L230.997 37.9158L173.473 37.9158L173.473 80.5586Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path
      d="M-0.000125885 98.5234L47.9551 98.5234L47.9551 63.9978L-0.000122867 63.9978L-0.000125885 98.5234Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path
      d="M239.922 194.176L239.922 169L229 169L229 194.176L239.922 194.176Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path d="M287 81L330 81L330 38L287 38L287 81Z" className="color-shade2" />
    <path
      d="M337 17L351 17L351 3.24249e-05L337 3.1201e-05L337 17Z"
      className="color-shade2"
    />
    <path
      d="M229 134.559L266.561 134.559L266.561 96.9974L229 96.9974L229 134.559Z"
      className="color-shade1"
    />
    <path
      d="M267 157L287 157L287 137L267 137L267 157Z"
      className="color-shade1"
    />
  </svg>
);

const squares11 = (
  <svg
    width="351"
    height="194"
    viewBox="0 0 351 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M277 134.177L219 134.177L219 193.177L277 193.177L277 134.177Z"
      className="color-shade2"
    />
    <path
      d="M177.527 113.618L120.002 113.618L120.002 156.261L177.527 156.261L177.527 113.618Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path
      d="M351 95.6531L303.044 95.6531L303.044 130.179L351 130.179L351 95.6531Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path
      d="M111.078 3.43323e-05L111.078 25.1765L122 25.1765L122 3.4202e-05L111.078 3.43323e-05Z"
      className="color-shade2"
      fillOpacity="0.5"
    />
    <path
      d="M63.9996 113.177L20.9996 113.177L20.9996 156.177L63.9996 156.177L63.9996 113.177Z"
      className="color-shade2"
    />
    <path
      d="M14 177.176L0 177.176L-1.48619e-06 194.176L14 194.176L14 177.176Z"
      className="color-shade2"
    />
    <path
      d="M121.999 59.6179L84.4382 59.6179L84.4382 97.1791L121.999 97.1791L121.999 59.6179Z"
      className="color-shade1"
    />
    <path
      d="M83.9995 37.1765L63.9995 37.1765L63.9995 57.1765L83.9995 57.1765L83.9995 37.1765Z"
      className="color-shade1"
    />
  </svg>
);

const squares12 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="257"
    height="146"
    viewBox="0 0 257 146"
    fill="none"
  >
    <path
      d="M54.7899 43.8H97.1299V1.52588e-05H54.7899V43.8Z"
      className="color-shade2"
    />
    <path
      d="M127.058 43.8H169.398V11.68H127.058V43.8Z"
      className="color-shade1"
    />
    <path
      d="M0.770554 73H35.8105V46.72H0.770554V73Z"
      className="color-shade1"
    />
    <path
      d="M175.24 146L175.24 127.02L166.48 127.02L166.48 146L175.24 146Z"
      className="color-shade1"
    />
    <path
      d="M209.55 61.32H241.67V29.2H209.55V61.32Z"
      className="color-shade2"
    />
    <path d="M246.78 13.14H257V0H246.78V13.14Z" className="color-shade2" />
    <path
      d="M167.21 100.74H194.95V73H167.21V100.74Z"
      className="color-shade1"
    />
    <path
      d="M196.41 116.8H211.01V102.2H196.41V116.8Z"
      className="color-shade1"
    />
  </svg>
);

export const bgSquaresArray = [
  squares0,
  squares1,
  squares2,
  squares3,
  squares4,
  squares5,
  squares6,
  squares7,
  squares8,
  squares9,
  squares10,
  squares11,
  squares12
];
