import axiosInstance from '../../axiosInstance';
import { IJsonPatchOperation } from '../../jsonPatch';
import {
  IAssetCatalogCreated,
  IAssetCatalogDetails,
  IAssetCatalogPatched,
  IAssetCatalogPermissions,
  IAssetCatalogToCreate
} from '../models/assetCatalogs';

const ASSETS_CATALOGS_PREFIX = 'api/v1/assetcatalogs';
const ASSETS_CATALOGS_DETAILS_SUFFIX = `details`;
const ASSETS_CATALOGS_DUPLICATE = `duplicate`;
const ASSETS_CATALOGS_PERMISSIONS = `permissions`;

export async function createAssetCatalog(
  data: IAssetCatalogToCreate,
  controller?: AbortController
): Promise<{
  data: IAssetCatalogCreated;
}> {
  return await axiosInstance.post(
    `${ASSETS_CATALOGS_PREFIX}`,
    { ...data },
    {
      signal: controller?.signal
    }
  );
}

export async function updateAssetCatalogById(
  id: string,
  lastModified: string,
  jsonPatchDocument: IJsonPatchOperation[],
  signal?: AbortSignal
): Promise<{ data: IAssetCatalogPatched }> {
  return await axiosInstance.patch(
    `${ASSETS_CATALOGS_PREFIX}/${id}?lastModified=${lastModified}`,
    jsonPatchDocument,
    { signal: signal }
  );
}

export async function checkCatalogDuplicateName(
  name: string,
  id?: string,
  controller?: AbortController
): Promise<{
  data: boolean;
}> {
  return await axiosInstance.get(
    `${ASSETS_CATALOGS_PREFIX}/${ASSETS_CATALOGS_DUPLICATE}/${name}`,
    {
      ...(!!id && { params: { catalogId: id } }),
      signal: controller?.signal
    }
  );
}

export async function getAssetCatalogs(controller?: AbortController): Promise<{
  data: IAssetCatalogCreated[];
}> {
  return await axiosInstance.get(ASSETS_CATALOGS_PREFIX, {
    signal: controller?.signal
  });
}

export async function getAssetCatalogsDetails(signal?: AbortSignal): Promise<{
  data: IAssetCatalogDetails[];
}> {
  return await axiosInstance.get(
    `${ASSETS_CATALOGS_PREFIX}/${ASSETS_CATALOGS_DETAILS_SUFFIX}`,
    {
      signal: signal
    }
  );
}

export async function getAssetCatalogPermissions(
  id: string,
  controller?: AbortController
): Promise<{
  data: IAssetCatalogPermissions;
}> {
  return await axiosInstance.get(
    `${ASSETS_CATALOGS_PREFIX}/${id}/${ASSETS_CATALOGS_PERMISSIONS}`,
    {
      signal: controller?.signal
    }
  );
}

export async function deleteCatalogById(
  id: string,
  controller?: AbortController
) {
  return await axiosInstance.delete(ASSETS_CATALOGS_PREFIX + '/' + id, {
    signal: controller?.signal
  });
}
