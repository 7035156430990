import create from 'zustand';
import { persist } from 'zustand/middleware';
import { IUserActivities, IUserInfo } from 'src/app/api/models/users';
import { Claims } from 'src/app/utilities/claims';
import jwt_decode from 'jwt-decode';
import { IAuthConfig } from 'src/app/api/models/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import { logErrorInConsole } from '../utilities';

interface IDecodedToken {
  sub: string;
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}

interface IAuthStore {
  userInfo?: IUserInfo;
  claims?: Claims;
  idToken?: string;
  authConfig?: IAuthConfig;
  cognitoUser?: CognitoUser;
  getIdTokenWithRefresh: () => Promise<string | undefined>;
  setIdToken: (idToken: string) => void;
  setUserInfo: (userInfo: IUserInfo) => void;
  setClaims: (permissions?: IUserActivities) => void;
  setAuthConfig: (authConfig?: IAuthConfig) => void;
  setCognitoUser: (cognitoUser?: CognitoUser) => void;
  reset: () => void;
}

const AuthCookieName = 'Auth';

function setAuthCookie(value: string) {
  const isSecure = process.env.NODE_ENV === 'production';
  const domain = process.env.REACT_APP_AUTH_COOKIE_DOMAIN ?? '';

  const expiration = decodeToken(value)?.exp ?? 0;
  Cookies.set(AuthCookieName, value, {
    expires: new Date(expiration * 1000), // Convert to milliseconds
    secure: isSecure,
    sameSite: 'strict',
    path: '/',
    domain: `.${domain}`
  });
}

function resetAuthCookie() {
  // Reset cookie with a Expires date in the past
  Cookies.set(AuthCookieName, '', { expires: new Date(0), path: '/' });
}

async function getIdToken(
  token?: string
): Promise<{ idToken: string | undefined; tokenNeedToBeRefresh: boolean }> {
  if (token) {
    const decoded: IDecodedToken | undefined = decodeToken(token);

    // Remove 30 seconds from token expiration to avoid token expiration during API call
    const secondToRemove = 30;

    if (
      decoded?.exp &&
      decoded.exp - secondToRemove < new Date().getTime() / 1000
    ) {
      return { idToken: undefined, tokenNeedToBeRefresh: true };
    }
  }

  return { idToken: token, tokenNeedToBeRefresh: token === undefined };
}

function decodeToken(token: string | null) {
  let decoded: IDecodedToken;

  if (!token) {
    return undefined;
  }

  try {
    decoded = jwt_decode(token);
    return decoded;
  } catch (err) {
    console.error(err);
  }
}

export const useAuth = create<IAuthStore>()(
  persist(
    (set, get) => ({
      userInfo: undefined,
      claims: undefined,
      idToken: undefined,
      authConfig: undefined,
      cognitoUser: undefined,
      getIdTokenWithRefresh: async () => {
        try {
          const { idToken, tokenNeedToBeRefresh } = await getIdToken(
            get().idToken
          );

          if (tokenNeedToBeRefresh) {
            resetAuthCookie();
            get().reset();
          }

          return idToken;
        } catch (e) {
          logErrorInConsole('authStore, getIdTokenWithRefresh: ', e);
          return '';
        }
      },
      setUserInfo: (userInfo: IUserInfo) => set(() => ({ userInfo })),
      setIdToken: (idToken?: string) => {
        setAuthCookie(idToken ?? '');
        set(() => ({ idToken: idToken }));
      },
      setClaims: (permissions?: IUserActivities) => {
        if (permissions) {
          set(() => ({ claims: new Claims(permissions) }));
        } else {
          set(() => ({ claims: undefined }));
        }
      },
      setAuthConfig: (authConfig?: IAuthConfig) => set(() => ({ authConfig })),
      setCognitoUser: (cognitoUser?: CognitoUser) =>
        set(() => ({ cognitoUser })),
      reset: () => {
        resetAuthCookie();
        set(() => ({
          userInfo: undefined,
          claims: undefined,
          userId: undefined,
          idToken: undefined,
          authConfig: undefined,
          cognitoUser: undefined
        }));
      }
    }),
    {
      name: 'authStore',
      getStorage: () => localStorage,
      merge: (persistedState, currentState) => {
        const persistedStore = persistedState as IAuthStore;

        if (persistedStore.claims !== undefined) {
          persistedStore.claims = new Claims(
            persistedStore.claims.userActivities
          );
        }

        return {
          ...currentState,
          ...persistedStore
        };
      }
    }
  )
);
