import { SettingsMainNav } from './SettingsMainNav';
import { SettingsMainContent } from './SettingsMainContent';
import { SettingLanguageForm } from './settingsSectionsForms/SettingLanguage/SettingLanguageForm';
import { SettingTermsOfUse } from './settingsSectionsForms/SettingTermsOfUse/SettingTermsOfUse';
import { SettingAppearanceForm } from './settingsSectionsForms/SettingAppearance/SettingAppearanceForm';
import { ElementType, useEffect, useMemo } from 'react';
import { useSettings } from 'src/app/stores/settingsStore';
import { useTranslation } from 'react-i18next';

export interface ISettingsSection {
  navLabel: string;
  title: string;
  sectionComponent?: ElementType;
}

export const SettingsMain = () => {
  const { selectedSectionIndex, setSelectedSectionIndex } = useSettings();
  const [t] = useTranslation();

  const settingsSections: ISettingsSection[] = useMemo(
    () => [
      {
        navLabel: t('settings__sections_nav_language_label'),
        title: t('settings__sections_language_title'),
        sectionComponent: SettingLanguageForm
      },
      {
        navLabel: t('settings__sections_nav_appearance_label'),
        title: t('settings__sections_appearance_title'),
        sectionComponent: SettingAppearanceForm
      },
      {
        navLabel: t('settings__sections_nav_terms_use_label'),
        title: t('settings__sections_nav_terms_use_title'),
        sectionComponent: SettingTermsOfUse
      }
    ],
    [t]
  );

  useEffect(() => {
    return () => {
      //when we close the settings, reset the section to be showed next time to the 1st option.
      setSelectedSectionIndex(0);
    };
  }, [setSelectedSectionIndex]);

  return (
    <div className="settings__main">
      <SettingsMainNav navItems={settingsSections} />
      {selectedSectionIndex !== undefined && (
        <SettingsMainContent
          title={settingsSections[selectedSectionIndex].title}
          sectionComponent={
            settingsSections[selectedSectionIndex].sectionComponent
          }
        />
      )}
    </div>
  );
};
