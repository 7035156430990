import isEqual from 'react-fast-compare';
import { IAdvancedFilterSelection } from 'src/app/api/controllers/metadata';
import {
  ESearchViews,
  SearchAssetTypeFiltersEnum,
  SearchDateFiltersEnum,
  SearchSortingEnum,
  SearchSortingOrderEnum
} from 'src/app/api/models/search';
import { LIMIT_PER_PAGE } from 'src/app/contexts/search/searchConstants';
import { getParamsFromUrlSearchParams } from 'src/app/contexts/search/searchHelpers';

export interface ISearchState extends ISorting {
  searchView: ESearchViews;
  assetTypeFilters: SearchAssetTypeFiltersEnum[];
  page: number;
  limit: number;
  dateFilter: SearchDateFiltersEnum;
  metadata: string[];
  searchText: string;
  advancedFilterOptions: IAdvancedFilterSelection;
  isVectorSearch: boolean;
}
export interface ISorting {
  sorting: SearchSortingEnum;
  orderBy: SearchSortingOrderEnum;
}

export type TSearchPayload = Omit<ISearchState, 'limit' | 'dateFilter'>;

export enum ESearchActionType {
  updateSearchView = 'UPDATE_SEARCHVIEW',
  updateQuery = 'UPDATE_QUERY',
  increasePage = 'INCREASE_PAGE',
  resetSearch = 'RESET_SEARCH'
}

export const getInitialState = (): ISearchState => {
  const searchParams = new URLSearchParams(document.location.search);

  const {
    searchView,
    sorting,
    orderBy,
    assetTypeFilters,
    metadata,
    searchText,
    advancedFilterOptions,
    isVectorSearch
  } = getParamsFromUrlSearchParams(searchParams);

  return {
    searchView: searchView,
    sorting: sorting,
    orderBy: orderBy,
    assetTypeFilters: assetTypeFilters,
    page: 1,
    limit: LIMIT_PER_PAGE,
    dateFilter: SearchDateFiltersEnum.ALL,
    metadata: metadata,
    searchText: searchText,
    advancedFilterOptions,
    isVectorSearch: isVectorSearch
  };
};

export const SEARCH_INITIALSTATE: ISearchState = {
  ...getInitialState()
};

//we set the payload depending the action
export type TSearchAction =
  | { type: ESearchActionType.updateSearchView; payload: ESearchViews }
  | {
      type: ESearchActionType.updateQuery;
      payload: TSearchPayload;
    }
  | {
      type: ESearchActionType.increasePage;
    }
  | { type: ESearchActionType.resetSearch };

export const searchReducer = (state: ISearchState, action: TSearchAction) => {
  switch (action.type) {
    /* searchVierw should be updated independantly because it doesn't trigger
    a new search query when being modfied */
    case ESearchActionType.updateSearchView: {
      return {
        ...state,
        searchView: action.payload
      };
    }
    case ESearchActionType.updateQuery: {
      const newState: Partial<ISearchState> = {
        searchView: action.payload.searchView,
        searchText: action.payload.searchText,
        sorting: action.payload.sorting,
        orderBy: action.payload.orderBy,
        page: action.payload.page,
        isVectorSearch: action.payload.isVectorSearch
      };

      //avoids unnecessary rerenders
      if (!isEqual(action.payload.assetTypeFilters, state.assetTypeFilters)) {
        newState.assetTypeFilters = action.payload.assetTypeFilters;
      }

      //avoids unnecessary rerenders
      if (!isEqual(action.payload.metadata, state.metadata)) {
        newState.metadata = action.payload.metadata;
      }

      //avoids unnecessary rerenders
      if (
        !isEqual(
          action.payload.advancedFilterOptions,
          state.advancedFilterOptions
        )
      ) {
        newState.advancedFilterOptions = action.payload.advancedFilterOptions;
      }
      return { ...state, ...newState };
    }
    case ESearchActionType.increasePage: {
      /* page number should be only updated in the context and not the parameters
      due to the infinite scroll */
      return {
        ...state,
        page: state.page + 1
      };
    }
    case ESearchActionType.resetSearch: {
      return {
        ...getInitialState()
      };
    }
    default:
      return state;
  }
};
