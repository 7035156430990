import { useTranslation } from 'react-i18next';
import { Container, Modal, Paper } from '@mui/material';
import { SettingsHeader } from './_components/SettingsHeader';
import { SettingsMain } from './_components/settingsMain/SettingsMain';
import { Copyright } from 'src/app/components/dataDisplay/copyright/Copyright';

import './settings.scss';

interface ISettingsModal {
  open: boolean;
  onClose: () => void;
}

export const SettingsModal = ({ open, onClose }: ISettingsModal) => {
  const [t] = useTranslation();
  const modalLabelledById = 'settings-modal-title';

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={modalLabelledById}
      className="settings__modal_root"
      hideBackdrop
    >
      <Container className="settings__modal_wrapper">
        <Paper className="settings__modal_body">
          <SettingsHeader
            labelledbyId={modalLabelledById}
            title={t('settings__title')}
            iconName="gear"
            onCloseButtonClick={onClose}
          />
          <SettingsMain />
        </Paper>
        <Copyright className="settings__modal_footer" isFooter />
      </Container>
    </Modal>
  );
};
