export enum EPageLocation {
  home,
  login,
  upload,
  notFound,
  newPasswordRequired,
  forgotPassword,
  search,
  assets,
  share,
  uikit,
  admin
}
