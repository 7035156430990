import { Components, CssVarsTheme, Theme } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledSwitch: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiSwitch-track': {
          backgroundColor:
            theme.palette.paletteMode === DEFAULT_COLOR_MODE
              ? theme.vars.palette.basic.darkest
              : theme.vars.palette.basic.brightest
        }
      })
    }
  }
};
