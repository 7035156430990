import { IdAndName } from 'src/app/utilities/utilityTypes';
import { AssetGroupsKeys } from './assets';
import { IAssetCatalogBase } from './assetCatalogs';

export enum SearchSortingEnum {
  RELEVANCE = 'relevance',
  ALPHABETICAL = 'alphabetical',
  DATE = 'date',
  SIZE = 'size'
}

export enum SearchSortingOrderEnum {
  DEFAULT = 'default',
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export enum SearchAssetTypeFiltersEnum {
  ALL = 'all',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  PRESENTATION = 'presentation',
  CREATIVE_FILE = 'creativefile',
  OTHER = 'other'
}

export enum SearchDateFiltersEnum {
  ALL = 'all',
  TODAY = 'today',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth'
}

export enum ESearchBarSuggestionsTypes {
  RECENT = 'recent',
  META_TAGS = 'metaTags',
  ASSETS = 'assets'
}

export enum ESearchType {
  VECTOR = 'Vector',
  ATTRIBUTE = 'Attribute'
}

export enum RecentSearchTypeEnum {
  TEXT = 'text',
  METADATA = 'metaTags',
  ASSET = 'asset'
}

export enum ESearchTriggeringEvent {
  KEYWORDS_MODIFICATION = 'keywords',
  SUBMIT = 'submit',
  BLUR = 'blur'
}

export enum ESearchViews {
  grid = 'grid',
  list = 'list'
}

export interface ISearchResultAsset {
  id: string;
  name: string;
  fileName?: string;
  extension?: string;
  assetTypeId?: string;
  createdDateTime?: string;
  modifiedDateTime?: string;
  publishDateTime?: string;
  size: number;
  catalog: IAssetCatalogBase;
  thumbnailSrc: string;
  isPreviewable: boolean;
  summary?: string;
}

export interface IAssetUserPermissions {
  canEdit?: boolean;
  canDelete?: boolean;
}

export interface IDisplayAssetData extends ISearchResultAsset {
  userPermissions?: IAssetUserPermissions;
  assetType?: AssetGroupsKeys;
}

export interface ISearchKeywords {
  searchText: string;
  metadata: string[];
}

export interface IAssetSuggestionDisplay extends IdAndName {
  thumbnailSrc?: string;
  catalogId: string;
}
export interface ISuggestionResponse {
  metadata: string[];
  assets: IAssetSuggestionDisplay[];
}

export interface ISearchInputValues {
  metaTags: string[];
  freeTextWords: string[];
  tempFreeInputText: string;
  hasSearchValues?: boolean;
}

export interface ISearchQueryBase {
  searchText: string;
  sorting: SearchSortingEnum;
  orderBy: SearchSortingOrderEnum;
  assetTypeFilters: SearchAssetTypeFiltersEnum[];
  dateFilter: SearchDateFiltersEnum;
  page: number;
  limit: number;
  searchView: ESearchViews;
  advancedFilters?: ISearchQueryAdvancedFilters;
}

export interface ISearchQuery extends ISearchQueryBase {
  metadata: string[];
  isVectorSearch: boolean;
}

export interface ISearchQueryAdvancedFilters {
  fields?: Record<string, string[]>;
}

export interface ISearchResponse {
  page: number;
  perPage: number;
  pageCount: number;
  totalCount: number;
  assetTypes: Record<string, number>;
  items: ISearchResultAsset[];
}

export interface ISearchItem {
  label: string;
}

export interface IRecentSearch extends ISearchItem {
  type: RecentSearchTypeEnum;
  query?: ISearchKeywords;
  onClick?: () => void;
}

export interface IAssetSuggestion extends ISearchItem {
  thumbnailSrc?: string;
  onClick?: () => void;
}
