import { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTenantInfoAndBranding } from 'src/app/api/controllers/tenants';
import {
  ITenantInfoWithBranding,
  TenantBrandImagesInfo
} from 'src/app/api/models/tenants';
import { useTenantStore } from 'src/app/stores/tenantStore';
import { logErrorInConsole } from 'src/app/utilities';
import { CustomColorSchemesWithoutMode } from 'src/styles/_theme/customTheme';
import { useAuthentication } from 'src/app/contexts/authentication';

interface ITenantProps {
  tenant: ITenantInfoWithBranding;
  setTenant: (tenant: ITenantInfoWithBranding) => void;
  refreshCurrentTenantAndBrandImages: () => void;
}

export const TenantContext = createContext<ITenantProps>({} as ITenantProps);

export interface ITenantProviderProps {
  children: React.ReactNode;
  shareContext?: boolean;
  setCustomTheme: (theme: CustomColorSchemesWithoutMode) => void;
}

export const TenantProvider = (props: ITenantProviderProps) => {
  const [tenant, setTenant] = useState<ITenantInfoWithBranding>(
    {} as ITenantInfoWithBranding
  );
  const { children, setCustomTheme, shareContext } = props;
  const { isAuthenticated } = useAuthentication();
  const tenantStore = useTenantStore();
  const { t } = useTranslation();

  // do not place default value or what was set in the localstorage
  // will be overriden on first app load by default value
  useEffect(() => {
    if (!tenant?.themeColor) return;
    setCustomTheme(tenant.themeColor);
  }, [tenant.themeColor, setCustomTheme]);

  const mapApiBrandImagesToFE = useCallback(
    (
      brandImages: Partial<TenantBrandImagesInfo>,
      tenantName?: string
    ): Partial<TenantBrandImagesInfo> => {
      let brandImagesInfo: Partial<TenantBrandImagesInfo> = {};
      Object.entries(brandImages).forEach(([imgKey, imgInfo]) => {
        let imgAltText: string | undefined = undefined;
        if (imgKey !== 'favicon' && imgInfo?.imageUri) {
          imgAltText = tenantName
            ? t('general__logo_alt_text', { name: tenantName })
            : t('general__logo_default_alt');
        }

        brandImagesInfo = {
          ...brandImagesInfo,
          [imgKey]: { ...imgInfo, ...(imgAltText && { altText: imgAltText }) }
        };
      });
      return brandImagesInfo;
    },
    [t]
  );

  const refreshCurrentTenantAndBrandImages = useCallback(async () => {
    try {
      const tenantWithBrandImages = await getTenantInfoAndBranding();
      tenantStore.setTenant({
        ...tenantWithBrandImages,
        brandImages: mapApiBrandImagesToFE(
          tenantWithBrandImages.brandImages,
          tenantWithBrandImages.name
        )
      });
    } catch (err) {
      logErrorInConsole('refreshCurrentTenantAndBrandImages', err);
    }
    // Don't map tenantStore, because each time the store is updated this retrigger this call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapApiBrandImagesToFE]);

  useEffect(() => {
    if (isAuthenticated && !shareContext) {
      refreshCurrentTenantAndBrandImages();
    } else {
      tenantStore.setTenant({} as ITenantInfoWithBranding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shareContext]);

  useEffect(() => {
    if (tenantStore.tenant && !shareContext) {
      setTenant(tenantStore.tenant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantStore.tenant]);

  return (
    <TenantContext.Provider
      value={{ tenant, setTenant, refreshCurrentTenantAndBrandImages }}
    >
      {children}
    </TenantContext.Provider>
  );
};
