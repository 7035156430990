import { Typography, TypographyProps } from '@mui/material';
import { ElementType, ReactNode } from 'react';
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const camelCaseStrings = (strings: string[]) => {
  return strings
    .filter((s) => s && s !== '')
    .map((str, index) => {
      const string =
        index === 0
          ? str.toLowerCase()
          : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return string;
    })
    .join('');
};

export const formatSeconds = (seconds: number) => {
  return new Date(Math.round(seconds) * 1000).toISOString().substring(11, 19);
};

// https://www.w3resource.com/javascript-exercises/javascript-string-exercise-26.php
export const removeFirstOccurrence = (str: string, searchStr: string) => {
  const index = str.indexOf(searchStr);
  if (index === -1) {
    return str;
  }
  return str.slice(0, index) + str.slice(index + searchStr.length);
};

// https://stackoverflow.com/questions/1981349/regex-to-replace-multiple-spaces-with-a-single-space
export const replaceMultipleSpacesWithASingleSpace = (str: string) => {
  return str.replace(/  +/g, ' ');
};

interface IReplaceStringWithNode extends TypographyProps {
  str: string;
  find: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elementType: ElementType<any>;
}

export const replaceMatchingStringWithNode = ({
  str,
  find,
  variant,
  elementType
}: IReplaceStringWithNode): ReactNode => {
  const splitRegEx = new RegExp(`(${find})`, 'gi');
  const txtArray = str.split(splitRegEx);
  return (
    <>
      {Array.isArray(txtArray)
        ? txtArray.map((txt, index) => {
            if (txt.toLowerCase() === find.toLowerCase()) {
              return (
                <Typography
                  variant={variant}
                  component={elementType}
                  key={`${txt}-${index}`}
                >
                  {txt}
                </Typography>
              );
            } else {
              return txt;
            }
          })
        : str}
    </>
  );
};

export const guidToUploadId = (guid: string) => {
  return guid.replace(/-/g, '');
};

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
export const stripDiacritics = (str: string) => {
  return typeof str.normalize !== 'undefined'
    ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : str;
};

export const formatStringToWordArray = ({
  str,
  divider
}: {
  str: string;
  divider: string;
}): string[] => {
  if (str.length === 0) {
    return [];
  }
  return replaceMultipleSpacesWithASingleSpace(str).split(divider);
};

export const removeDuplicatesInString = ({
  str,
  divider
}: {
  str: string;
  divider: string;
}): string => {
  const stringArr = formatStringToWordArray({ str, divider });
  const setString = new Set(stringArr);
  return Array.from(setString).join(' ');
};

export const returnNameAndExtension = (fileName: string) => {
  const extensionDelimiterIndex = fileName.lastIndexOf('.');
  const extension = fileName.substring(extensionDelimiterIndex);
  const name = fileName.substring(0, extensionDelimiterIndex);
  return { name, extension };
};

export const removeTrailingSlash = (word: string): string => {
  return word.replace(/\/$/, '');
};
