import { ReactNode } from 'react';
import create from 'zustand';

export enum EDrawerDepthLevel {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  TERTIARY = 'Tertiary'
}
interface IDrawerHeader {
  title: string;
  desc?: string;
  iconName: string;
}

interface IDrawerStore {
  isOpen: boolean;
  content: ReactNode;
  header: IDrawerHeader;
  drawerDepthLevel: EDrawerDepthLevel;
  hideCloseButton: boolean;
  changeOpen: (value: boolean) => void;
  setContent: (value: ReactNode) => void;
  setHeader: (value: IDrawerHeader) => void;
  changeDrawerDepthLevel: (value: EDrawerDepthLevel) => void;
  setHideCloseButton: (value: boolean) => void;
}

export const useDrawer = create<IDrawerStore>((set) => ({
  isOpen: false,
  drawerDepthLevel: EDrawerDepthLevel.PRIMARY,
  content: null,
  header: { title: '', iconName: '' },
  hideCloseButton: false,
  changeOpen: (value: boolean) => set({ isOpen: value }),
  setContent: (value: ReactNode) => set({ content: value }),
  setHeader: (value: IDrawerHeader) => set({ header: value }),
  changeDrawerDepthLevel: (value: EDrawerDepthLevel) =>
    set({ drawerDepthLevel: value }),
  setHideCloseButton: (value: boolean) => set({ hideCloseButton: value })
}));
