import { ReactNode, useState } from 'react';
import { TenantProvider } from 'src/app/contexts/tenant';
import { CustomThemeProvider } from 'src/styles/_theme';
import { CustomColorSchemesWithoutMode } from 'src/styles/_theme/customTheme';

export const ShareWrapper = ({ children }: { children: ReactNode }) => {
  const [customTenantTheme, setCustomTenantTheme] = useState<
    CustomColorSchemesWithoutMode | undefined
  >(undefined);

  return (
    <CustomThemeProvider themeFromTenant={customTenantTheme} nestedTheme>
      <TenantProvider shareContext setCustomTheme={setCustomTenantTheme}>
        {children}
      </TenantProvider>
    </CustomThemeProvider>
  );
};
