import { Components, CssVarsTheme, Theme } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledList: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiList: {
    styleOverrides: {
      root: () => ({
        padding: 'unset'
      })
    }
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: '0',

        '&.open': {
          marginRight: theme.spacing(1)
        }
      })
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '0.25rem',
        padding: theme.spacing(1),
        transition: 'background-color 150ms ease-in-out',
        justifyContent: 'center',

        '&:hover, &.active': {
          boxShadow: 0,
          backgroundColor: theme.vars.palette.primary.shades[0]
        },

        '&.Mui-selected': {
          backgroundColor: theme.vars.palette.primary.shades[1],
          '&:hover, &.active': {
            backgroundColor: theme.vars.palette.primary.shades[1]
          }
        },

        '.reversed-color &, &.reversed-color': {
          '&:hover, &.active': {
            boxShadow: 0,
            color: theme.palette.primary.contrastText,
            backgroundColor:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? `hsl(${theme.vars.palette.primary.shadesChannels[2]} / 0.2)`
                : theme.vars.palette.primary.main
          },
          '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
            backgroundColor:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? `hsl(${theme.vars.palette.primary.shadesChannels[2]} / 0.2)`
                : theme.vars.palette.primary.main
          },
          '&.Mui-focusVisible': {
            color: theme.palette.primary.contrastText,
            backgroundColor:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? `hsl(${theme.vars.palette.primary.shadesChannels[2]} / 0.2)`
                : theme.vars.palette.primary.main
          }
        }
      })
    }
  },
  MuiListItemText: {
    styleOverrides: {
      root: () => ({
        '.MuiTypography-root': {
          width: '100%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      })
    }
  }
};
