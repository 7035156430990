interface ISortArrayByDate {
  modifiedDateTime?: string;
  createdDateTime: string;
}

export const isEqual = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays in ascending order
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare the sorted arrays element by element
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arraysOfObjectsAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // Assuming objects have properties that need to be compared
    for (const key in obj1) {
      if (Object.hasOwn(obj1, key) && Object.hasOwn(obj2, key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      } else {
        return false; // One of the objects is missing a property
      }
    }
  }

  return true;
};

export const removeDuplicateStringInArray = (arr: string[]): string[] => {
  const arrSet = new Set(arr);
  return Array.from(arrSet);
};

export const removeDuplicateObjectsInArray = <T>(
  arr: T[],
  objectKey: keyof T
): T[] => {
  const uniqueIds = new Set();

  const unique = arr.filter((element: T) => {
    const isDuplicate = uniqueIds.has(element[objectKey]);

    if (!isDuplicate) {
      uniqueIds.add(element[objectKey]);

      return true;
    }

    return false;
  });

  return unique;
};

export const sortArraysByDates = <T extends ISortArrayByDate>(items: T[]) => {
  return items.sort(
    (a, b) =>
      new Date(b.modifiedDateTime ?? b.createdDateTime ?? '').getTime() -
      new Date(a.modifiedDateTime ?? a.createdDateTime ?? '').getTime()
  );
};

export const createCompareFnObjectsInArray = <
  T extends Record<keyof T, string | number | boolean>,
  K extends keyof T
>(
  keyName: K,
  sort_order: 'asc' | 'desc'
) => {
  const compareFnObjectsInArray = (a: T, b: T) => {
    const aVal = a[keyName];
    const bVal = b[keyName];
    const order = sort_order !== 'desc' ? 1 : -1;

    switch (typeof aVal) {
      case 'number': {
        const bValNumber = bVal as number;
        const result = aVal - bValNumber;
        return result * order;
      }
      case 'string': {
        const bValString = bVal as string;
        const result = aVal.localeCompare(bValString);
        return result * order;
      }
      // add other cases like boolean, etc.
      default:
        return 0;
    }
  };

  return compareFnObjectsInArray;
};

export const getItemById = <T extends { id: string }>(
  id: string,
  items: T[]
): T | undefined => {
  return items.find((currentItem: T) => currentItem.id === id);
};
