import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledTypography: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiTypography: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.variant === 'overline' && {
          backgroundColor: theme.vars.palette.background.default,
          borderRadius: theme.vars.shape.borderRadius,
          lineHeight: '2.3334',
          padding: '0.25rem 1rem'
        })
      })
    }
  }
};
