import { useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'react-fast-compare';

import {
  ESearchViews,
  SearchAssetTypeFiltersEnum,
  SearchSortingEnum,
  SearchSortingOrderEnum
} from 'src/app/api/models/search';
import {
  getParamsFromUrlSearchParams,
  serializeSearchQuery
} from 'src/app/contexts/search/searchHelpers';

import { Optional } from 'src/app/utilities/utilityTypes';
import { ESearchActionType, ISearchState } from './searchReducer';
import { SearchDispatchContex, SearchStateContext } from './SearchProvider';
import { formatStringToWordArray } from 'src/app/utilities/string';
import { removeDuplicateStringInArray } from 'src/app/utilities/arrays';

export interface ISearchLocation {
  selectedIds: string[];
}

export const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchState = useContext(SearchStateContext);
  const searchDispatch = useContext(SearchDispatchContex);
  const searchParamsInUrl = useMemo((): ISearchState => {
    return getParamsFromUrlSearchParams(searchParams);
  }, [searchParams]);

  /**
   * changes the search string to an array of words
   */
  const searchTextArrayInUrl = useMemo(() => {
    return formatStringToWordArray({
      str: searchParamsInUrl.searchText,
      divider: ','
    });
  }, [searchParamsInUrl.searchText]);

  const isSearchDirty = useMemo(() => {
    const searchParamsToCheck: Optional<ISearchState, 'page' | 'searchView'> = {
      ...searchParamsInUrl
    };

    const searchStateToCheck: Optional<ISearchState, 'page' | 'searchView'> = {
      ...searchState
    };

    delete searchParamsToCheck.page;
    delete searchStateToCheck.page;
    delete searchParamsToCheck.searchView;
    delete searchStateToCheck.searchView;

    return !isEqual(searchParamsToCheck, searchStateToCheck);
  }, [searchState, searchParamsInUrl]);

  const hasNoSearchElement = useMemo(() => {
    return (
      searchParamsInUrl.searchText === '' &&
      searchParamsInUrl.metadata.length === 0 &&
      searchParamsInUrl.assetTypeFilters.includes(
        SearchAssetTypeFiltersEnum.ALL
      )
    );
  }, [searchParamsInUrl]);

  const toggleSearchView = useCallback(
    (newView: ESearchViews) => {
      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          searchView: newView
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleSortingChange = useCallback(
    ({
      sortOption,
      orderByOption
    }: {
      sortOption: SearchSortingEnum;
      orderByOption: SearchSortingOrderEnum;
    }) => {
      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          sorting: sortOption,
          orderBy: orderByOption
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleAssetTypesChange = useCallback(
    ({ assetType }: { assetType: SearchAssetTypeFiltersEnum }) => {
      const { hasAssetTypeBeenRemoved, currentAssetTypes } = [
        ...searchParamsInUrl.assetTypeFilters
      ].reduce(
        (acc, currentAsset) => {
          if (
            currentAsset !== SearchAssetTypeFiltersEnum.ALL &&
            currentAsset !== assetType
          ) {
            acc.currentAssetTypes.push(currentAsset);
          } else if (currentAsset === assetType) {
            acc.hasAssetTypeBeenRemoved = true;
          }

          return acc;
        },
        {
          hasAssetTypeBeenRemoved: false,
          currentAssetTypes: [] as SearchAssetTypeFiltersEnum[]
        }
      );

      if (!hasAssetTypeBeenRemoved) {
        currentAssetTypes.push(assetType);
      } else if (currentAssetTypes.length === 0) {
        currentAssetTypes.push(SearchAssetTypeFiltersEnum.ALL);
      }

      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          assetTypeFilters: currentAssetTypes
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleMetadatasChange = useCallback(
    ({ metadata }: { metadata: string[] }) => {
      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          metadata: removeDuplicateStringInArray(metadata)
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleMetadataAndTextChange = useCallback(
    ({
      metadata,
      searchText
    }: {
      metadata: string[];
      searchText: string[];
    }) => {
      const searchTextQuery =
        removeDuplicateStringInArray(searchText).join(',');

      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          searchText: searchTextQuery,
          metadata: removeDuplicateStringInArray(metadata)
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleSearchTextChange = useCallback(
    ({ newSearchText }: { newSearchText: string[] }) => {
      const searchTextQuery =
        removeDuplicateStringInArray(newSearchText).join(',');
      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          searchText: searchTextQuery
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleAdvancedFiltersOptionsChange = useCallback(
    ({ fieldId, optionId }: { fieldId: string; optionId: string }) => {
      const newAdvancedFilters = { ...searchParamsInUrl.advancedFilterOptions };
      const exisingOptionIndex = newAdvancedFilters[fieldId]?.findIndex(
        (option) => optionId === option
      );

      if (!exisingOptionIndex && exisingOptionIndex !== 0) {
        newAdvancedFilters[fieldId] = [optionId];
      } else if (exisingOptionIndex === -1) {
        newAdvancedFilters[fieldId].push(optionId);
      } else {
        newAdvancedFilters[fieldId].splice(exisingOptionIndex, 1);
        if (newAdvancedFilters[fieldId].length === 0) {
          delete newAdvancedFilters[fieldId];
        }
      }

      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          advancedFilterOptions: newAdvancedFilters
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleAdvancedFilterFieldReset = useCallback(
    (fieldId: string) => {
      const newAdvancedFilters = { ...searchParamsInUrl.advancedFilterOptions };
      delete newAdvancedFilters[fieldId];

      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          advancedFilterOptions: newAdvancedFilters
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const handleClearAllAdvancedFilters = useCallback(() => {
    setSearchParams(
      serializeSearchQuery({
        ...searchParamsInUrl,
        advancedFilterOptions: {}
      })
    );
  }, [searchParamsInUrl, setSearchParams]);

  const advancedFiltersOptionsCount = useMemo(() => {
    const options: string[] = [];
    Object.values(searchState.advancedFilterOptions).forEach((filter) =>
      filter.forEach((option) => {
        options.push(option);
      })
    );

    return options.length;
  }, [searchState.advancedFilterOptions]);

  const handlePageChange = useCallback(() => {
    searchDispatch({
      type: ESearchActionType.increasePage
    });
  }, [searchDispatch]);

  const changeIsVectorSearch = useCallback(
    (searchText?: string) => {
      setSearchParams(
        serializeSearchQuery({
          ...searchParamsInUrl,
          searchText: searchText?.replaceAll(',', ' ') ?? '',
          metadata: [],
          isVectorSearch: !searchParamsInUrl.isVectorSearch
        })
      );
    },
    [searchParamsInUrl, setSearchParams]
  );

  const clearSearchInputParams = () => {
    setSearchParams(
      serializeSearchQuery({
        ...searchParamsInUrl,
        searchText: '',
        metadata: []
      })
    );
  };

  return {
    toggleSearchView,
    handleSortingChange,
    searchParamsInUrl,
    handleAssetTypesChange,
    handleMetadatasChange,
    handleMetadataAndTextChange,
    isSearchDirty,
    handlePageChange,
    handleSearchTextChange,
    searchTextArrayInUrl,
    handleAdvancedFiltersOptionsChange,
    handleAdvancedFilterFieldReset,
    hasNoSearchElement,
    handleClearAllAdvancedFilters,
    advancedFiltersOptionsCount,
    changeIsVectorSearch,
    clearSearchInputParams
  };
};
