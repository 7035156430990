export enum JsonPatchOperationEnum {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  MOVE = 'move',
  COPY = 'copy'
}

export type IJsonPatchOperation =
  | IJsonPatchAddOperation<unknown>
  | IJsonPatchRemoveOperation<unknown>
  | IJsonPatchReplaceOperation<unknown>
  | IJsonPatchMoveOperation;

export interface IJsonPatchBaseOperation {
  path: string;
}

export interface IJsonPatchAddOperation<T> extends IJsonPatchBaseOperation {
  op: JsonPatchOperationEnum.ADD;
  value: T;
}

export interface IJsonPatchRemoveOperation<T> extends IJsonPatchBaseOperation {
  op: JsonPatchOperationEnum.REMOVE;
  value?: T;
}

export interface IJsonPatchReplaceOperation<T> extends IJsonPatchBaseOperation {
  op: JsonPatchOperationEnum.REPLACE;
  value: T;
}

export interface IJsonPatchMoveOperation extends IJsonPatchBaseOperation {
  op: JsonPatchOperationEnum.MOVE;
  from: string;
}

export interface IJsonPatchCopyOperation extends IJsonPatchBaseOperation {
  op: JsonPatchOperationEnum.COPY;
  from: string;
}
