import { styledIconButtons } from './iconButtons';
import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledAutocomplete } from './autocomplete';
import { styledButtons } from './buttons';
import { styledCheckboxes } from './checkbox';
import { styledMenu } from './menu';
import { styledSwitch } from './switch';
import { styledLink } from './link';
import { styledRadios } from './radios';

export const styledInputsAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledAutocomplete,
  ...styledButtons,
  ...styledIconButtons,
  ...styledLink,
  ...styledCheckboxes,
  ...styledMenu,
  ...styledSwitch,
  ...styledRadios,
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: '0.125rem 0 0 0.75rem',
        '&.Mui-error': {
          color: theme.vars.palette.error.main
        }
      })
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        caretColor: theme.vars.palette.secondary.main,

        '> .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.vars.palette.action.outlineBorder,
          transition: '150ms border-color ease-in-out'
        },
        '&:hover': {
          '> .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.vars.palette.primary.shades[4]
          }
        },
        '&.Mui-focused': {
          '> .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.vars.palette.primary.main,
            borderWidth: '2px'
          }
        },
        '&.Mui-disabled': {
          '> .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.vars.palette.text.disabled
          }
        },
        '&.Mui-error': {
          '> .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.vars.palette.error.light
          }
        },
        textarea: {
          color: theme.palette.text.secondary
        }
      })
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: ({ theme }) => ({
        '> .icon': {
          color: theme.vars.palette.primary.main,
          opacity: '0.54',
          transition: 'opacity 300ms ease-in-out',
          cursor: 'pointer',
          '&:hover': {
            opacity: '1'
          }
        }
      })
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.share-link': {
          //this rule is only for the disabled input in the share modal
          '.MuiInputBase-input': {
            WebkitTextFillColor: theme.vars.palette.text.secondary
          }
        },
        '.MuiFormHelperText-root': {
          color: theme.vars.palette.text.secondary,

          '&.Mui-error': {
            color: theme.vars.palette.error.main
          }
        }
      })
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        //For Checkboxes and Radio buttons, don't change label color when focused
        '&.Mui-focused:not(.MuiInputLabel-root)': {
          color: theme.vars.palette.text.secondary
        },
        '&.Mui-error': {
          color: theme.vars.palette.error.light
        }
      })
    }
  }
};
