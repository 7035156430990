import clsx from 'clsx';
import { CSSProperties } from 'react';
import SVGSpritesIcons from 'src/assets/images/icons.svg';

import './custom-icons.scss';

interface ICustomIconsProps {
  iconName: string;
  label?: string;
  uniqueId?: string;
  customStyles?: CSSProperties;
  extraClassName?: string;
}

export const CustomIcons = ({
  iconName,
  label,
  uniqueId,
  customStyles,
  extraClassName
}: ICustomIconsProps) => {
  const ariaAttributes = {
    ...(label && uniqueId
      ? { role: 'graphics-symbol img', 'aria-labelledby': uniqueId }
      : label && !uniqueId
      ? { role: 'graphics-symbol img', 'aria-label': label }
      : { 'aria-hidden': true, focusable: false })
  };

  return (
    <svg
      className={clsx('icon', extraClassName)}
      style={customStyles}
      {...ariaAttributes}
    >
      {label && <title id={uniqueId}>{label}</title>}
      <use
        href={`${SVGSpritesIcons}#icon-${iconName}`}
        style={{ pointerEvents: 'none' }}
      />
    </svg>
  );
};
