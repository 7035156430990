import create from 'zustand';
import { SIDEBAR_REM_WIDTH_FULL } from '../../utilities';

interface IAdminStore {
  sidebarWidth: number;
  changeSidebarWidth: (value: number) => void;
}

export const adminStore = create<IAdminStore>((set) => ({
  sidebarWidth: SIDEBAR_REM_WIDTH_FULL,
  changeSidebarWidth: (value: number) => set({ sidebarWidth: value })
}));
