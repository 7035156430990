import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from 'react';
import {
  IAuthenticationState,
  AUTHENTICATION_INITIAL_STATE,
  authenticationReducer,
  EAuthenticationActionType
} from './authenticationReducer';
import { useAuth } from 'src/app/stores';
import { logErrorInConsole } from 'src/app/utilities';
import { Auth } from 'aws-amplify';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoUser
} from 'amazon-cognito-identity-js';
import { getAuthConfig } from 'src/app/api/controllers/auth';
import {
  formatLoginRoute,
  formatLogoutRoute
} from 'src/app/utilities/authentication';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

interface IAuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationContext = createContext<IAuthenticationState>(
  AUTHENTICATION_INITIAL_STATE
);

export const AuthenticationProvider = ({
  children
}: IAuthenticationProviderProps) => {
  const { authConfig, idToken, setIdToken, setAuthConfig } = useAuth();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const didInitialise = useRef(false);

  const [state, dispatch] = useReducer(
    authenticationReducer,
    AUTHENTICATION_INITIAL_STATE
  );

  const initialize = useCallback(async () => {
    if (didInitialise.current) {
      return;
    }

    const controller = new AbortController();

    let userSession: CognitoUserSession | undefined = undefined;
    const searchParams = new URLSearchParams(window.location.search);
    const idToken = searchParams.get('idToken');
    const accessToken = searchParams.get('accessToken');
    const refreshToken = searchParams.get('refreshToken');
    const redirectTo = searchParams.get('redirectTo');

    try {
      let tenantName: string | null = null;

      if (window.location.hostname === 'localhost') {
        tenantName = searchParams.get('tenantName');
      }

      // This is when we came back from the Login WebPage
      if (idToken && accessToken) {
        // Create a CognitoUser Session from the PassParameters
        userSession = new CognitoUserSession({
          IdToken: new CognitoIdToken({
            IdToken: idToken
          }),
          AccessToken: new CognitoAccessToken({
            AccessToken: accessToken
          }),
          RefreshToken: new CognitoRefreshToken({
            RefreshToken: refreshToken ?? ''
          })
        });

        // Get the AuthConfiguration (UserPoolId and ClientId) for this user + tenant combo
        const payload = userSession.getIdToken().decodePayload();

        const authenticationConfig = await getAuthConfig(
          {
            email: payload['email'],
            redirectUri: window.origin,
            tenantName: tenantName
          },
          controller.signal
        );

        setAuthConfig(authenticationConfig.data);

        // Setup AWS Auth
        Auth.configure({
          Auth: {
            userPoolId: authenticationConfig.data.userPool as string,
            userPoolWebClientId: authenticationConfig.data.clientId as string
          }
        });

        // Create a CognitoUser and setup with the session
        // We fake the AWS signin flow that way
        const cognitoUser = (Auth as any).createCognitoUser(
          payload['cognito:username']
        );

        cognitoUser.setSignInUserSession(userSession);

        // Setup the IdToken, so the user is "logged"
        setIdToken(idToken);

        navigate(redirectTo || '');
      } else {
        // When we are refreshing the page, all the "big" setup is already done.
        // We just need to re-setup AWS Auth and refresh the IdToken
        Auth.configure({
          Auth: {
            userPoolId: authConfig?.userPool as string,
            userPoolWebClientId: authConfig?.clientId as string
          }
        });
        const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
        userSession = (await currentUser.getSignInUserSession()) || undefined;
        if (userSession) {
          setIdToken(userSession.getIdToken().getJwtToken());
        }
      }
    } catch (error) {
      controller.abort();
      logErrorInConsole('AuthenticationProvider - initialize', error);
    } finally {
      didInitialise.current = true;
      dispatch({
        type: EAuthenticationActionType.initialize,
        payload: {
          user: userSession
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const login = useCallback(() => {
    const href = formatLoginRoute(i18n.language);
    window.location.assign(href);
  }, [i18n.language]);

  const logout = useCallback(async () => {
    window.location.assign(formatLogoutRoute(i18n.language));
    // We don't want to trigger the Refresh token useEffect
    didInitialise.current = false;
    dispatch({ type: EAuthenticationActionType.logout });

    Auth.signOut();
    useAuth.getState().reset();
  }, [i18n.language]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // Token Refresh UseEffect
  useEffect(() => {
    if (didInitialise.current && idToken === undefined) {
      // When we are redirect to the login the token are refreshed
      login();
    }
  }, [idToken, login]);

  return (
    <AuthenticationContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
