import { Suspense } from 'react';
import { useLoading } from 'src/app/stores';
import { Loading } from './Loading';

export interface ILoadingWrapper {
  children: React.ReactNode;
}

export default function LoadingWrapper({ children }: ILoadingWrapper) {
  const { isLoading } = useLoading();

  return (
    <Suspense fallback={<Loading />}>
      {isLoading && <Loading />}
      {children}
    </Suspense>
  );
}
