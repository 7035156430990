import { useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/system';

type BreakpointBooleanFn = {
  [key in Breakpoint]: boolean;
};

export const useResponsive = () => {
  const theme = useTheme();

  const breakpoints: BreakpointBooleanFn = {
    xxxs: useMediaQuery(theme.breakpoints.up('xxxs')),
    xxs: useMediaQuery(theme.breakpoints.up('xxs')),
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
    xxl: useMediaQuery(theme.breakpoints.up('xxl'))
  };

  const isBreakpoint: BreakpointBooleanFn = {
    xxxs: useMediaQuery(theme.breakpoints.only('xxxs')),
    xxs: useMediaQuery(theme.breakpoints.only('xxs')),
    xs: useMediaQuery(theme.breakpoints.only('xs')),
    sm: useMediaQuery(theme.breakpoints.only('sm')),
    md: useMediaQuery(theme.breakpoints.only('md')),
    lg: useMediaQuery(theme.breakpoints.only('lg')),
    xl: useMediaQuery(theme.breakpoints.only('xl')),
    xxl: useMediaQuery(theme.breakpoints.only('xxl'))
  };

  return {
    breakpoints,
    isBreakpoint
  };
};
