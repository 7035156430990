import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledButtons: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        height: '2.5rem',
        lineHeight: '1.15em', //if changed, please test button text on 2 lines also.
        minWidth: 'auto',
        borderRadius: theme.vars.shape.borderRadius,
        padding: theme.spacing(1, 1.5),
        textTransform: 'none',
        boxShadow: 'none',
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primaryLight' && {
            backgroundColor: theme.vars.palette.primaryLight.main,
            color: theme.vars.palette.primary.light,
            '&:hover': {
              backgroundColor: theme.vars.palette.primaryLight.dark
            }
          }),
        '&.Mui-disabled': {
          backgroundColor: theme.vars.palette.action.disabled,
          color: theme.vars.palette.text.secondary
        },
        '&.MuiLoadingButton-root': {
          '.MuiLoadingButton-loadingIndicator': {
            '&.MuiLoadingButton-loadingIndicatorEnd': {
              right: theme.spacing(1.5)
            }
          },
          '&.MuiLoadingButton-loading.Mui-disabled': {
            backgroundColor: theme.vars.palette.primary.main,
            color: theme.vars.palette.primary.contrastText
          }
        },
        '&.chip--filled': {
          '&:hover': {
            backgroundColor: 'inherit'
          }
        },
        ...(ownerState.variant === 'text' && {
          color: theme.vars.palette.primary.light,
          '&.Mui-disabled': {
            color: theme.vars.palette.text.secondary
          },
          '&:hover': {
            backgroundColor: theme.vars.palette.primary.shades[0]
          },
          '&.MuiLoadingButton-root': {
            '&.MuiLoadingButton-loading.Mui-disabled': {
              backgroundColor: theme.vars.palette.primaryLight.dark
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: theme.vars.palette.primary.light
            }
          }
        }),
        ...(ownerState.variant === 'icon' && {
          borderRadius: '0.5rem',
          border: `0.125rem solid ${theme.vars.palette.primary.shades[2]}`,
          padding: '0.625rem',
          color: theme.vars.palette.text.primary,
          '.MuiButton-startIcon': {
            marginLeft: '0'
          },
          '&.Mui-disabled': {
            backgroundColor: `${theme.vars.palette.action.disabled}`,
            borderColor: theme.vars.palette.action.disabled,
            color: theme.vars.palette.text.secondary
          }
        }),
        ...(ownerState.variant === 'actionMenuBtn' && {
          color:
            ownerState.color === 'inherit'
              ? 'inherit'
              : ownerState.color === 'primaryLight'
              ? theme.vars.palette.primaryLight.main
              : theme.vars.palette[ownerState.color || 'primary'].main,
          border: `1px solid transparent`, //prevents black border transition to alternative light
          '&:hover': {
            backgroundColor: theme.vars.palette.background.paper,
            border: `1px solid ${theme.vars.palette.primary.shades[1]}`,
            boxShadow: theme.vars.shadows[1]
          }
        }),
        ...(ownerState.variant === 'accessible' && {
          padding: 0,
          height: 'auto',
          color: 'inherit',
          textAlign: 'left',
          fontWeight: 'inherit',
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'inherit'
          }
        }),
        ...(ownerState.variant === 'coverParentZone' && {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: 'transparent'
          }
        }),
        ...(ownerState.variant === 'text' &&
          ownerState.color === 'error' && {
            color: theme.vars.palette.error.main,

            '&:hover': {
              color: theme.vars.palette.error.main,
              backgroundColor: theme.vars.palette.error.shades[0]
            },
            '&:focus-visible': {
              outline: 'none !important',
              backgroundColor: theme.vars.palette.error.shades[0]
            },
            '& .MuiTouchRipple-child': {
              backgroundColor: `hsl(${theme.vars.palette.error.lightChannel} / 0.2 )`
            },
            '&.MuiLoadingButton-root': {
              '&.Mui-disabled': {
                color: theme.vars.palette.text.secondary
              },
              '.MuiLoadingButton-loadingIndicator': {
                '&.MuiLoadingButton-loadingIndicatorEnd': {
                  right: theme.spacing(1.5)
                }
              }
            }
          }),
        ...(ownerState.variant === 'selectBtn' && {
          backgroundColor: theme.vars.palette.background.paper,
          boxShadow: theme.vars.shadows[4],
          padding: theme.spacing(0.75, 1),
          color: theme.vars.palette.primary.main,
          '.MuiButton-startIcon': {
            marginRight: 0,
            marginLeft: 0
          },
          '&:hover': {
            backgroundColor: theme.vars.palette.primaryLight.main
          }
        }),
        ...(ownerState.variant === 'logo' && {
          padding: 0,
          height: 'auto',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }),
        ...(ownerState.variant === 'outlinedReversed' && {
          backgroundColor: theme.vars.palette.primary.main,
          color: theme.vars.palette.primary.contrastText,
          outline: `1px solid ${theme.vars.palette.primary.contrastText}`,
          outlineOffset: '-1px',
          '&:hover, &:focus, &:active, &:visited': {
            outline: `1px solid ${theme.vars.palette.primary.contrastText} !important`,
            outlineOffset: '-1px'
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.vars.palette.primary.contrastText} !important`
          },
          '&:hover': {
            backgroundColor: theme.vars.palette.primary.dark
          }
        }),
        ...(ownerState.variant === 'outlined' && {
          border: `1px solid ${theme.vars.palette.action.outlineBorder}`,
          '&:focus-visible, &:focus': {
            borderColor: theme.vars.palette.primary.main,
            backgroundColor: theme.vars.palette.primary.shades[2]
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.background.paper,
            borderColor: theme.vars.palette.action.disabled,
            color: theme.vars.palette.text.secondary
          }
        })
      })
    },
    variants: [
      {
        props: { variant: 'filter' },
        style: ({ theme }) => ({
          backgroundColor: theme.vars.palette.background.default,
          color: theme.vars.palette.text.primary,
          borderRadius: '0.5rem',
          border: `2px solid ${theme.vars.palette.action.outlineBorder}`,
          padding: theme.spacing(0.25),
          minWidth: 'auto',
          height: '2.5rem',

          '.filter-button-content': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.25rem',
            height: '100%',
            padding: theme.spacing(0, 1),
            gap: theme.spacing(0.5)
          },
          '&:hover': {
            backgroundColor: theme.vars.palette.background.default,
            color: theme.vars.palette.primary.main
          },

          '&.selected': {
            color: theme.vars.palette.primary.main,
            '.filter-button-content': {
              boxShadow: theme.vars.shadows[4],
              backgroundColor: theme.vars.palette.background.paper
            }
          },
          '&.disabled': {
            backgroundColor: theme.vars.palette.action.disabled,
            color: theme.vars.palette.text.secondary,
            borderColor: theme.vars.palette.action.disabled,
            pointerEvents: 'auto',
            '&:hover': {
              '*': {
                color: theme.vars.palette.text.secondary
              }
            },
            // to accomodate 0 count but selected filter
            '&.selected': {
              '.filter-button-content': {
                boxShadow: 'unset',
                color: theme.vars.palette.text.secondary,
                backgroundColor: 'unset'
              }
            }
          }
        })
      },
      {
        props: { variant: 'outlinedZone' },
        style: ({ theme }) => ({
          border: `1px dashed ${theme.vars.palette.action.outlineBorder}`,
          color: theme.vars.palette.primary.light,
          minHeight: '3.5rem',
          padding: theme.spacing(2, 2.5),
          width: '100%',

          '&:hover': {
            border: `2px dashed ${theme.vars.palette.primary.main}`,
            backgroundColor: theme.vars.palette.primary.shades[0]
          },
          '&:focus-visible, &:focus': {
            border: `2px dashed ${theme.vars.palette.primary.main}`,
            backgroundColor: theme.vars.palette.primary.shades[0]
          },
          '&.Mui-disabled': {
            backgroundColor: theme.vars.palette.background.paper,
            borderColor: theme.vars.palette.action.disabled,
            color: theme.vars.palette.text.secondary
          }
        })
      }
    ]
  },
  MuiButtonBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiMenuItem-root': {
          '&.Mui-focusVisible': {
            backgroundColor: theme.vars.palette.primary.shades[0]
          }
        },
        '&.MuiButton-root': {
          '&:hover': {
            boxShadow: 'none'
          }
        }
      })
    }
  }
};
