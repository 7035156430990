import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledDialog: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        zIndex: 1600,
        '.MuiBackdrop-root': {
          backgroundColor: theme.vars.palette.background.backdrop
        },
        '.MuiPaper-root': {
          padding: 'unset',
          margin: 'unset',
          boxShadow: 'none',
          minWidth: '',
          gap: 'unset'
        },
        '.MuiDialog-container': {
          '&:focus-visible': {
            outline: 'unset !important'
          }
        }
      })
    }
  }
};
