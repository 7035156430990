import { CssVarsTheme, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { StyleSheet } from '@emotion/sheet';
import { TSON } from 'src/app/utilities/utilityTypes';

interface IMyCssVarsProvider {
  children: ReactNode;
  theme: Omit<Theme, 'palette'> & CssVarsTheme;
}

// Workaround for creationg css vars that are not supported by MUI
// @TODO: check if this is still usefull once MUI is updated
export const MyCssVarsProvider = ({ children, theme }: IMyCssVarsProvider) => {
  const styleSheet = new StyleSheet({
    key: 'css-global-custom',
    container: document.head
  });

  let stylesContent = ':root{';

  function objectToCssVariables(json: TSON, prefix: string) {
    if (Array.isArray(json)) {
      json.forEach((val, i) => {
        if (typeof val !== 'function' && val) {
          stylesContent += `${prefix}-${i}:${val.toString()};`;
        }
      });
    } else if (typeof json === 'object') {
      for (const k in json) {
        objectToCssVariables(json[k], prefix + '-' + k);
      }
    } else if (typeof json !== 'function' && json) {
      stylesContent += `${prefix}:${json.toString()};`;
    }
  }
  objectToCssVariables(theme.typography as unknown as TSON, '--mui-typography');
  objectToCssVariables(
    theme.breakpoints as unknown as TSON,
    '--mui-breakpoints'
  );

  stylesContent += `}`;
  styleSheet.insert(stylesContent);

  return <>{children}</>;
};
