import create from 'zustand';

interface ILoadingStore {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const useLoading = create<ILoadingStore>((set) => ({
  isLoading: false,
  setIsLoading: (active: boolean) => set(() => ({ isLoading: active }))
}));
