import axios, { InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import i18n from 'src/app/utilities/i18n';
import { useAuth } from '../stores';

export const noAuthAxiosInstance = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosInstance = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use((response) => {
  return response;
});

const requestInterceptorFn = async (
  requestConfig: InternalAxiosRequestConfig
) => {
  if (requestConfig.headers) {
    const token = await useAuth.getState().getIdTokenWithRefresh();

    requestConfig.headers['Accept-Language'] = i18n.language;

    requestConfig.headers.Authorization = `Bearer ${token}`;
  }

  return requestConfig;
};

axiosInstance.interceptors.request.use((config) =>
  requestInterceptorFn(config)
);

export const AXIOS_CANCELED = 'CanceledError';
export const AXIOS_ERROR = 'AxiosError';

export default axiosInstance;
