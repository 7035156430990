import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';

export interface IAdminNavItems {
  title: string;
  icon?: string;
  route?: string;
  children?: IAdminNavItems[];
  customClass?: string;
}

export interface INavigationItemProps {
  item: IAdminNavItems;
  smallNavigation?: boolean;
}

export const NavigationItem = (props: INavigationItemProps) => {
  const { item, smallNavigation } = props;
  const [openChildList, setOpenChildList] = useState<boolean>(false);

  const handleSublistClick = () => {
    setOpenChildList(!openChildList);
  };

  // Single item to be render, no children
  if (item.children === undefined || item.children.length === 0) {
    return (
      <ListItemButton
        component={NavLink}
        to={item.route ?? ''}
        className={clsx({ open: smallNavigation }, item.customClass)}
      >
        {item.icon && (
          <ListItemIcon className={clsx({ open: smallNavigation })}>
            <CustomIcons
              iconName={item.icon}
              customStyles={{ fontSize: '1.25rem' }}
            />
          </ListItemIcon>
        )}

        <ListItemText
          primary={item.title}
          className={clsx({ open: smallNavigation })}
        />
      </ListItemButton>
    );
  }

  // Item with children, recursive render to show all the children
  return (
    <List
      className={clsx('sidebar__item-with-child', {
        'show-child': openChildList
      })}
    >
      <ListItemButton
        onClick={handleSublistClick}
        className={clsx('sidebar__item-with-child', 'item', {
          open: smallNavigation
        })}
      >
        {item.icon && (
          <ListItemIcon className={clsx({ open: smallNavigation })}>
            <CustomIcons
              iconName={item.icon}
              customStyles={{ fontSize: '1.25rem' }}
            />
          </ListItemIcon>
        )}

        <ListItemText
          primary={item.title}
          className={clsx({ open: smallNavigation })}
        />

        <CustomIcons
          iconName={openChildList ? 'arrow-dropdown-down' : 'arrow-dropdown-up'}
          customStyles={{ fontSize: '1.25rem' }}
        />
      </ListItemButton>
      <Collapse in={openChildList} unmountOnExit>
        <List>
          {item.children?.map((child, key) => (
            <NavigationItem
              key={`admin-nav-item-child-${key}`}
              item={{
                ...child,
                customClass: 'sidebar__item-with-child item item--child'
              }}
              smallNavigation={smallNavigation}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};
