import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledModal: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiModal: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(1),

        '.MuiPaper-root': {
          boxShadow: theme.shadows[1],
          '&:focus-visible': {
            outline: 'unset !important'
          }
        },

        '.close-btn': {
          position: 'absolute',
          right: theme.spacing(3),
          top: theme.spacing(3)
        },

        ...(ownerState.hideBackdrop === true && {
          backgroundColor: theme.vars.palette.background.default,
          animation: 'fadeIn 0.25s',
          '>.MuiContainer-root': {
            animation: 'fadeIn 0.5s ease-out'
          }
        }),
        '@keyframes fadeIn': {
          from: { opacity: 0 },
          to: { opacity: 1 }
        }
      })
    }
  }
};
