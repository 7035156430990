import {
  ESearchViews,
  ISearchQuery,
  SearchAssetTypeFiltersEnum,
  SearchDateFiltersEnum,
  SearchSortingEnum,
  SearchSortingOrderEnum
} from 'src/app/api/models/search';

export const LIMIT_PER_PAGE = 25;

export const DEFAULT_QUERY: ISearchQuery = {
  searchText: '',
  metadata: [],
  sorting: SearchSortingEnum.DATE,
  orderBy: SearchSortingOrderEnum.DESCENDING,
  assetTypeFilters: [SearchAssetTypeFiltersEnum.ALL],
  dateFilter: SearchDateFiltersEnum.ALL,
  page: 1,
  limit: LIMIT_PER_PAGE,
  searchView: ESearchViews.grid,
  isVectorSearch: false
};
