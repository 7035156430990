import { CssVarsPalette, PaletteMode } from '@mui/material';
import { Palette } from '@mui/material/styles';
import { CustomColorSchemesWithoutMode } from 'src/styles/_theme/customTheme';
import { allAvailableThemes, useCustomTheme } from './';

export interface IPaletteSelectionKeys {
  paletteColorName?: CustomColorSchemesWithoutMode;
  mode?: PaletteMode;
}

export const useThemePaletteValues = () => {
  const { currentThemeName, currentMode } = useCustomTheme();

  const getPaletteValues = ({
    paletteColorName,
    mode
  }: IPaletteSelectionKeys) => {
    const colorName = paletteColorName || currentThemeName;
    const paletteMode = mode || (currentMode as PaletteMode) || 'light';

    const theme =
      allAvailableThemes.find((t) => t.name === colorName) ||
      allAvailableThemes[0];

    const palette: Required<Palette & CssVarsPalette> =
      theme.theme.colorSchemes[paletteMode].palette;
    return palette;
  };

  return { getPaletteValues };
};
