import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledAccordion: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiAccordion: {
    styleOverrides: {
      root: () => ({
        padding: 0,
        '&:before': {
          display: 'none'
        }
      })
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.vars.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        minHeight: theme.spacing(5.5),
        paddingLeft: 0,
        '&:focus:not(input)': {
          outline: '0 !important'
        }
      }),
      content: () => ({
        margin: 0
      }),
      expandIconWrapper: () => ({
        fontSize: '1.5rem'
      })
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.5, 2, 3, 2)
      })
    }
  }
};
