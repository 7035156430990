export const returnTermsOfUseEs = () => {
  return `#### Condiciones generales de uso
  1. Soluciones web

  El sitio web datacm.com es propiedad de DATA Communications Management Corp. (“DCM”) (el “Sitio”).
  Si accede al Sitio o a alguna solución web de DCM (la “Solución”), acepta estar sujeto a las siguientes condiciones (las “Condiciones”) y cumplirlas.
  Si no acepta estas Condiciones, no use el Sitio ni la Solución.
  Todo el contenido de este Sitio, incluido, a título enunciativo, todo el software, el texto, los datos, el material, la información, los iconos, las frases, el sonido, la programación, las imágenes, los gráficos, el diseño del sitio y la selección, la organización, la funcionalidad, el rendimiento y la organización de los anteriores, incluido el acceso a cualquiera de las demás soluciones web de DCM (en conjunto, el “Contenido”), se podrá modificar en cualquier momento sin previo aviso.
  Las Condiciones se podrán modificar en cualquier momento sin previo aviso simplemente actualizándolas.

  Además de las condiciones expuestas anteriormente, DCM se reserva el derecho de restringir, suspender o anular su acceso a todo el Sitio o Solución o a parte de ellos, a su criterio exclusivo, en cualquier momento, por cualquier motivo y sin previo aviso, y sin por ello incurrir en obligación alguna ante usted.
  DCM se reserva el derecho de negarse a prestar servicios a cualquier persona que visite el Sitio.
  El Contenido y los servicios provistos en este Sitio y esta Solución están disponibles únicamente para las personas que pueden celebrar contratos legalmente vinculantes.
  **Debe tener al menos 18 años para solicitar y recibir servicios de este Sitio.
  Si no cumple con los requisitos, no use el Sitio ni se comunique con este**.
  Usted acepta consultar periódicamente las Condiciones y, si continúa accediendo al Sitio y usándolo, acepta los cambios realizados en estas.

  2. Exclusión de garantías

  Cumplimos y superamos los protocolos de seguridad estándar del sector que tienen el objetivo de proteger su información y sus transacciones.
  Aun así, DCM no puede garantizar que el Sitio o la Solución estará disponible y podrá satisfacer sus necesidades; que el acceso no se interrumpirá; que no ocurrirán retrasos, fallos, errores, omisiones o pérdidas en la transmisión de información; que el Sitio está completamente protegido contra virus y otros elementos contaminantes o dañinos y que no se producirán daños en su sistema informático.
  Usted es el único responsable de garantizar la protección y el respaldo adecuado de sus datos o sus equipos y de tomar precauciones razonables y apropiadas con respecto a virus informáticos u otros elementos dañinos.

  DCM ofrece el Sitio y el Contenido “como está, según disponibilidad”, sin ningún tipo de garantía, declaración o condición, manifestación o disposición y, en particular, sin garantías ni condiciones de comerciabilidad, funcionalidad, cumplimiento de los derechos de terceros, vigencia, título, utilidad, integridad, precisión, viabilidad e idoneidad del Contenido para un fin específico, y sin condiciones ni garantías implícitas que surjan de las transacciones, el uso, el intercambio o el rendimiento.
  Usted acepta expresamente asumir todos los riesgos relacionados con el uso, la calidad y el rendimiento de las funciones del Sitio, y con la precisión o integridad del Contenido del Sitio.

  3. Cláusula de limitación de responsabilidad

  EN EL MÁXIMO GRADO PERMITIDO POR LAS LEYES APLICABLES, EN NINGUNA CIRCUNSTANCIA DCM Y SUS DIRECTIVOS, CONSEJEROS, PROPIETARIOS, AGENTES, EMPLEADOS, REPRESENTANTES Y LICENCIANTES (EN CONJUNTO, LAS “PARTES DE DCM”) SERÁN RESPONSABLES DE ALGÚN DAÑO DIRECTO, INDIRECTO, EMERGENTE, PUNITIVO, EJEMPLARIZANTE, ESPECIAL O PREVISTO INDIRECTO RELACIONADO CON EL USO DEL SITIO, LA IMPOSIBILIDAD DE USAR EL SITIO O EL CONTENIDO QUE SE INCLUYE EN ÉL, O QUE OCURRA DEBIDO AL USO DE PRODUCTOS O SERVICIOS DE CUALQUIER TIPO, O LA IMPOSIBILIDAD DE USARLOS, DEBIDO A LA ENTREGA PARCIAL O CON RETRASO, DEBIDO A LA EXTINCIÓN DE DERECHOS, EL LUCRO CESANTE O LA PÉRDIDA DE DATOS, ECONÓMICA O DE FONDO DE COMERCIO, YA SEA DE FORMA CONTRACTUAL O EXTRACONTRACTUAL, Y LAS PARTES DE LOS DATOS NO SERÁN RESPONSABLES DE INDEMNIZAR O BRINDAR OTRO RESARCIMIENTO A USTED NI A NINGÚN TERCERO.
  EN TODO CASO, LA RESPONSABILIDAD TOTAL DE LAS PARTES DE DCM ANTE USTED POR TODOS LOS DAÑOS, LAS PÉRDIDAS, LAS RECLAMACIONES Y LOS FUNDAMENTOS DE ACCIÓN, YA SEAN CONTRACTUALES, EXTRACONTRACTUALES (INCLUIDA, ENTRE OTRAS, LA NEGLIGENCIA) O DE OTRA ÍNDOLE, SE LIMITARÁ AL MONTO QUE USTED PAGÓ POR UN SERVICIO O PRODUCTO EN PARTICULAR QUE SE OFRECE MEDIANTE ESTE SITIO.
  Su único y exclusivo recurso será dejar de usar y acceder al Sitio o la Solución.
  Si alguna sección de esta limitación no está permitida en el lugar donde reside, dicha sección no será aplicable.
  Usted acepta que esta limitación de responsabilidad es integral y se aplica a todos los daños y todos los fundamentos de acción de cualquier naturaleza.

  4. Indemnidad

  Usted se compromete a eximir de responsabilidad a las Partes de DCM frente a toda reclamación, demanda, acción, obligación, pérdida, costo y gasto (incluidos los honorarios legales) que surja de los siguientes casos o se relacione con ellos: (i) todo uso real o presunto del Sitio o la Solución con su nombre de usuario o contraseña por parte de otra persona, independientemente de que usted lo haya autorizado, (ii) todo incumplimiento de estas Condiciones o (iii) su violación de las leyes o los derechos de un tercero derivada de su uso del Sitio o la Solución.
  DCM se reserva el derecho de asumir la exclusiva defensa y el control de toda reclamación, demanda y acción que surja en relación con esta cláusula y usted acepta cooperar con DCM en la defensa de estas reclamaciones, demandas y acciones.

  5. Información provista por medios electrónicos

  Reconoce que la información o el material que provea por medios electrónicos al acceder o usar el Sitio o la Solución no es confidencial ni exclusivo, salvo en la medida en que lo dispongan las leyes aplicables, y que todas las comunicaciones por correo electrónico mediante Internet no protegidas no son confidenciales, pueden interceptarse o perderse, o bien, estar sujetas a ciertas alteraciones.
  DCM no se hace responsable ante usted ni ante nadie de los daños ocasionados en el transcurso de la transmisión de información por medios electrónicos a DCM o que DCM le envíe a pedido suyo.

  6. Enlaces y software

  Se pueden proporcionar enlaces a otros sitios web únicamente por comodidad.
  DCM no asume ninguna responsabilidad por el contenido de los sitios web de terceros al que se pueda acceder mediante dichos enlaces ni por ningún producto o servicio que se ofrezca en ellos.
  DCM tampoco revisa, aprueba ni controla dichos contenidos, productos o servicios.
  Si usted accede a otro sitio mediante un enlace, lo hace por su cuenta y riesgo, y DCM no se hace responsable de los daños que pueda causar dicho enlace.
  Los enlaces a sitios desde los que se puede descargar software se brindan solo por comodidad, y DCM no se hace responsable de las dificultades o consecuencias que surjan de la descarga remota de software.
  El uso del software descargado está regulado por las disposiciones del acuerdo de licencia que acompaña al software, si lo hubiera.

  7. Daños a terceros

  Usted acepta no introducir en el Sitio o la Solución ni mediante estos ninguna información o material que pueda ocasionar daños a terceros.
  Entre otras cosas, acepta no introducir, a sabiendas o no, ningún error ni defecto en el material o la información que pueda, por ejemplo, constituir calumnia, injuria, difamación u obscenidad, o bien, promover el odio o provocar de otra manera un delito penal o una responsabilidad civil por parte de cualquier persona o entidad.

  8. Legislación aplicable y jurisdicción

  El Sitio y la Solución se regirán por las leyes de Ontario y las leyes aplicables de Canadá, con independencia de los principios de conflicto de leyes.
  Acepta someterse a la jurisdicción exclusiva de los tribunales de la provincia de Ontario en relación con la interpretación o aplicación de estas Condiciones y para la resolución o el fallo final de todo litigio, acción o procedimiento que surja del uso, o en relación con el uso, del Sitio o la Solución.
  DCM no hace ninguna declaración de que el material del Sitio o de la Solución sea adecuado o esté disponible para su uso en un lugar en particular, ni en cuanto al cumplimiento del Sitio y del Contenido con las leyes de los territorios desde donde se puede acceder al Sitio o la Solución.
  Quienes opten por acceder al Sitio o la Solución, lo hacen por iniciativa propia y son responsables del cumplimiento de las leyes locales, si estas resultan de aplicación.

  9. Privacidad

  Al usar el Sitio o la Solución, podrá presentar de manera voluntaria información personal a DCM.
  Nos comprometemos a garantizar prácticas responsables con respecto a la recopilación, el uso y la divulgación de su información personal en virtud de las leyes aplicables.
  Haga clic aquí para revisar la Política de Privacidad de DCM [la Política de Privacidad](https://www.datacm.com/privacy-policy/).
  AL ENVIAR INFORMACIÓN PERSONAL A DCM O SUS AGENTES Y PROVEEDORES DE SERVICIOS, ACEPTA QUE LA RECOPILEMOS, USEMOS Y DIVULGUEMOS CON LOS FINES INDICADOS, TAL Y COMO SE DESCRIBE EN NUESTRA POLÍTICA DE
  PRIVACIDAD Y SEGÚN LO PERMITA Y EXIJA LA LEY.
  Si nos proporciona información personal de otra persona, declara que tiene autorización para hacerlo o que obtuvo los permisos necesarios de esa persona para permitirnos recopilar, usar y divulgar dicha información para los fines indicados y para los fines establecidos en nuestra Política de Privacidad.
  Si no acepta la Política de Privacidad, no proporcione información personal a DCM.
  Los términos y condiciones de la Política de Privacidad se incorporan a modo de referencia y forman parte de estas Condiciones.

  10. Propiedad intelectual

  “DCM” y todas las demás marcas comerciales, marcas de servicio y logotipos, estén o no registrados, que se indican como propiedad de DCM pertenecen a DATA Communications Management Corp. y no se podrán usar sin la autorización por escrito de su propietario.
  Todas las demás marcas comerciales pertenecen a sus respectivos propietarios.

  Todo el Contenido está protegido por derechos de autor © 2020-2023.
  A menos que se indique lo contrario, DATA Communications Management Corp.es propiedad, o está bajo el control, de DCM o la parte acreditada como autor o proveedor del material.
  TODOS LOS DERECHOS RESERVADOS.

  11. Restricciones de uso

  Podrá acceder al Contenido en este Sitio, usarlo e imprimirlo desde su computadora personal para su uso particular.
  No podrá modificar, publicar, transmitir, transferir o vender, reproducir, distribuir, representar, exhibir ni explotar de ninguna manera el Contenido, de manera total o parcial, ni crear trabajos derivados de este, salvo que estas Condiciones lo autoricen de manera explícita.
  La modificación del Contenido o su uso con cualquier otro propósito que no sea el expresamente permitido en estas Condiciones (incluido el uso de todo este material en cualquier otro sitio web o entorno informático en red) constituye una violación de los derechos de propiedad intelectual y otros derechos de propiedad de DCM y sus licenciantes.
  Se prohíbe estrictamente todo uso o modificación del Contenido en su totalidad o en parte con cualquier otro propósito.
  Se prohíbe estrictamente toda tentativa no autorizada de cargar o modificar información en este Sitio.
  A menos que se especifique lo contrario, ninguna persona tiene autorización para copiar, redistribuir, reproducir, volver a publicar ni fijar en ningún tipo de formato, retransmitir, modificar o usar públicamente o con fines comerciales el Contenido de ninguna manera.

  Acepta que todas las copias que realice del Contenido conservarán todos los avisos de derechos de autor y de propiedad en el mismo formato y de la misma manera que el original.
  Las marcas comerciales no pueden usarse en relación con ningún producto o servicio que DCM no haya aprobado previamente por escrito, o de alguna manera que pueda generar confusión entre los clientes o el público, o de alguna manera que menosprecie o desacredite a DCM.

  12. Reserva de derechos

  DCM se reserva todos los derechos no conferidos explícitamente en estas Condiciones.
  Sin perjuicio de la generalidad de lo que antecede, ninguna parte de estas Condiciones se interpretará como una concesión implícita o de otro tipo de alguna licencia o algún derecho en virtud de algún derecho de autor, patente, marca comercial u otro derecho de propiedad intelectual de DCM o de cualquier otra persona o entidad.

  13. Subsistencia de las obligaciones

  Estas Condiciones se aplicarán cuando acceda al Sitio y permanecerán vigentes en lo sucesivo, sujetas al derecho de DCM de modificar o actualizar de otro modo oportunamente las Condiciones de uso del Sitio.
  Si deja de tener acceso al Sitio, las condiciones de las cláusulas denominadas “Exclusión de garantías”, “Cláusula de limitación de responsabilidad”, “Información provista por medios electrónicos”, “Indemnidad”, “Propiedad intelectual” y “Restricciones de uso” continuarán vigentes.

  14. Registro

  Para poder realizar un pedido en el Sitio, usted o su empresa deberá abrir una cuenta en el Sitio que tendrá asociados un nombre de usuario y una contraseña.
  Garantice la confidencialidad de estos, ya que usted será responsable si se usan de manera fraudulenta.
  No permita que nadie use su cuenta ni comparta su contraseña con nadie.
  No tiene permitido usar ni intentar usar la cuenta, la contraseña, el servicio, el sistema ni otros datos de otra persona provistos en este Sitio; ni dejar que otros usen su cuenta, contraseña, servicio o sistema sin previa autorización por escrito de DCM; ni crear o usar una identidad falsa en este Sitio.
  Tampoco tiene permitido intentar obtener acceso no autorizado a este Sitio o a partes de este con acceso restringido, ni dejar que otros lo intenten.

  #### Productos y servicios

  Todos los productos y servicios que ofrece DCM mediante la Solución, así como su uso, están sujetos a los términos y condiciones de todos los convenios o acuerdos que regulan su uso.

  #### Pedidos de productos y servicios
  Todos los pedidos de productos y servicios que ofrece DCM mediante la Solución, incluidos, de manera no taxativa, métodos de pago, precios, disponibilidad de productos, entrega y devolución de productos, están sujetos a las disposiciones de los acuerdos aplicables en vigencia entre usted y DCM.
  `;
};
