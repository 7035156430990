import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledCheckboxes: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0.625),
        marginLeft: theme.spacing(0.375),
        zIndex: 1,
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '1rem',
          height: '1rem',
          backgroundColor: theme.vars.palette.background.paper,
          borderRadius: '0.2rem',
          zIndex: '-1'
        }
      })
    }
  }
};
