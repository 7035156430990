import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledPopover: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiPopover: {
    defaultProps: {
      elevation: 1
    },
    styleOverrides: {
      root: ({ theme }) => ({
        zIndex: 1601,

        '&.include-arrow': {
          '.MuiPaper-root': {
            marginTop: theme.spacing(2.5),
            overflow: 'unset',
            marginLeft: '0.875rem',
            backgroundColor: theme.palette.background.paper,
            border: `2px solid ${theme.vars.palette.primary.shades[1]}`,

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-0.688rem',
              right: '1.6rem',
              height: '1.25rem',
              width: '1.25rem',
              backgroundColor: theme.vars.palette.background.paper,
              borderRadius: '0 0.25rem 0 0', //only one corner to have border radius
              borderWidth: '0.125rem 0.125rem 0 0', //only 2 sides are visible
              borderColor: `${theme.vars.palette.primary.shades[1]} ${theme.vars.palette.primary.shades[1]} transparent transparent`, //only 2 sides are visible
              borderStyle: 'solid',
              transform: 'rotate(-45deg)'
            }
          }
        },

        '&.header-action-menu': {
          '.MuiPaper-root': {
            padding: 'unset !important',
            gap: 'unset !important',
            minWidth: '256px',
            border: `1px solid ${theme.vars.palette.action.outlineBorder}`,
            marginTop: theme.spacing(1)
          },

          '.action-menu__item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
            color: theme.vars.palette.text.secondary,
            borderRadius: 0
          },

          '.link': {
            cursor: 'pointer',
            borderTop: `1px solid ${theme.palette.action.outlineBorder}`,
            gap: theme.spacing(1.5),
            transition: '150ms ease-in-out',

            '&:hover': {
              backgroundColor: theme.vars.palette.background.default,
              boxShadow: 'unset'
            }
          }
        }
      })
    }
  }
};
