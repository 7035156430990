import { Box, IconButton, Typography } from '@mui/material';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';
import { useTranslation } from 'react-i18next';
import { HeaderTitleIcon } from 'src/app/components/dataDisplay/icons/HeaderTitleIcon';

interface ISettingsHeader {
  labelledbyId?: string;
  title: string;
  iconName?: string;
  onCloseButtonClick?: () => void;
}

export const SettingsHeader = ({
  labelledbyId,
  iconName,
  title,
  onCloseButtonClick
}: ISettingsHeader) => {
  const [t] = useTranslation();
  return (
    <Box className={'settings__header'}>
      <Box className="settings__title">
        {iconName && <HeaderTitleIcon iconName={iconName} />}
        <Typography variant="h3" component="h1" id={labelledbyId}>
          {title}
        </Typography>
      </Box>
      {onCloseButtonClick && (
        <IconButton
          onClick={onCloseButtonClick}
          aria-label={t('icon__close')}
          className="settings__close"
        >
          <CustomIcons iconName="close" />
        </IconButton>
      )}
    </Box>
  );
};
