import { useRouteError } from 'react-router-dom';
import BaseError from './BaseError';

export interface IErrorBoundaryProps {
  goBackBtnText?: string;
  goBackBtnLink?: string;
}

export function ErrorBoundary(props: IErrorBoundaryProps) {
  const error: any = useRouteError();

  return (
    <BaseError
      errorType={error.response?.status}
      goBackBtnLink={props.goBackBtnLink}
      goBackBtnText={props.goBackBtnText}
    />
  );
}
