import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Typography, useTheme } from '@mui/material';

import routes from 'src/app/pages/home/home.routes';

import { Flexbox } from 'src/app/components/surfaces/box/Flexbox';
import SimpleHeader from 'src/app/components/surfaces/headers/simpleHeader/SimpleHeader';
import { SVGWithDynamicColors } from 'src/app/components/utils/svgImages/SVGWithDynamicColors';
import { Logo } from 'src/app/components/dataDisplay/brandImages/Logo';

import errorPageImg1x from 'src/assets/images/errorpage-1x.png';
import errorPageImg2x from 'src/assets/images/errorpage-2x.png';

import './base-error.scss';
export interface IBaseErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorType: any;
  goBackBtnLink?: string;
  goBackBtnText?: string;
}

export default function BaseError(props: IBaseErrorProps) {
  const [t] = useTranslation();
  const theme = useTheme();
  const errorTypesHandled = [401, 403, 404, 440, 503];

  // When no error type, error__generic is not really an errorType
  // but is used to make sure a traduction is returned :
  // error__generic__title and error__generic__text
  const errorType =
    !!props.errorType && errorTypesHandled.includes(props.errorType)
      ? props.errorType
      : 'error__generic';

  return (
    <>
      <Helmet>
        <title>{t('general__app_title')}</title>
      </Helmet>

      <SVGWithDynamicColors
        svgArrayMappingInfo={{ arrayKey: 'bgSquares', index: 10 }}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
      />
      <SVGWithDynamicColors
        svgArrayMappingInfo={{ arrayKey: 'bgSquares', index: 11 }}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
      />

      <Container
        maxWidth="xl"
        className="no-container-padding-below-xxs error-page__header"
        sx={{ zIndex: 1 }}
      >
        <SimpleHeader>
          <NavLink
            to={routes.paths[t('locale_key')]}
            style={{ display: 'flex' }}
          >
            <Logo
              sx={{
                maxWidth: '4.5rem',
                maxHeight: '4.5rem',
                marginLeft: '0.5rem'
              }}
            />
          </NavLink>
        </SimpleHeader>
      </Container>

      <main className="error-page">
        <Container maxWidth="xl" className="error-page__middle-container">
          <img
            srcSet={`${errorPageImg1x} 715w, ${errorPageImg2x} 1428w`}
            src={errorPageImg2x}
            className="error-page__main-img"
          />
          <Flexbox
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={theme.spacing(2)}
          >
            <Typography component="h2" variant="h2">
              {t(errorType + '__title')}
            </Typography>

            <Flexbox
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={theme.spacing(3)}
            >
              <Typography component="p" variant="body1">
                {t(errorType + '__text')}
              </Typography>

              <Button
                component={RouterLink}
                variant="contained"
                to={props.goBackBtnLink ?? routes.paths[t('locale_key')]}
              >
                {props.goBackBtnText ?? t('error__go_to_homepage')}
              </Button>
            </Flexbox>
          </Flexbox>
        </Container>
      </main>
    </>
  );
}
