import { useTranslation } from 'react-i18next';
import AccessibilityLink from 'src/app/components/navigation/accessibilityLink/AccessibilityLink';
import { TempDevHelpers } from 'src/app/components/z_temp_forDevUse/TempDevHelpers';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { BasicSwipeableDrawer } from '../../navigation/drawer/Swipeable/BasicSwipeableDrawer';

import './connected-layout.scss';

export default function ConnectedLayout() {
  const [t] = useTranslation();
  const showDebugBar = process.env.REACT_APP_DEBUG_BAR;

  return (
    <div className="connected-layout">
      <ScrollRestoration />
      <AccessibilityLink
        href="#main-content"
        title={t('general__skip_to_main_content')}
      />
      <AccessibilityLink
        href="#footer-nav"
        title={t('general__skip_to_navigation')}
      />
      <AccessibilityLink
        href={t('routes__accessibility_page_slug')}
        title={t('general__skip_to_accessibility_page')}
      />
      <Outlet />

      <BasicSwipeableDrawer />

      {showDebugBar === 'true' && <TempDevHelpers />}
    </div>
  );
}
