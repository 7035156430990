import {
  CssVarsThemeOptions,
  IAccentColor,
  PaletteMode,
  ShadeFactor
} from '@mui/material';
import {
  experimental_extendTheme as extendTheme,
  Shadows,
  SupportedColorScheme
} from '@mui/material/styles';

import { ColorSystemOptions } from '@mui/material/styles/experimental_extendTheme';
import { styledMuiComponents } from './_components/styledMuiComponents';
import {
  defaultDarkColorScheme,
  defaultLightColorScheme,
  greenDarkColorScheme,
  greenLightColorScheme,
  redLightColorScheme,
  redDarkColorScheme,
  blackDarkColorScheme,
  blackLightColorScheme,
  orangeDarkColorScheme,
  orangeLightColorScheme
} from './colorSchemes';
import { getHslValues } from './_components/utils/colors';
import { availableThemes } from './';

//We are using CSS theme variables by mui to theme this app :
//https://mui.com/material-ui/experimental-api/css-theme-variables/overview/
export type CustomColorSchemesWithoutMode =
  typeof availableThemes[keyof typeof availableThemes];

export interface IHSLcolor {
  hue: string;
  saturation: string;
  lightness: string;
}

export interface IThemeNaming {
  colorName: CustomColorSchemesWithoutMode;
  label: string;
}

export interface IBackgroundColorScheme {
  paper: string;
  default: string;
}

export interface IThemeCommonColorScheme {
  paletteMode: PaletteMode;
  shadeFactor: ShadeFactor;
  background: IBackgroundColorScheme;
  brightest: string;
  darkest: string;
  text: ITextColors;
  info: IBaseColor;
  error: IErrorColor;
  warning: IBaseColor;
  success: IBaseColor;
  action: IAction;
  accent: IAccentColor;
}

export interface IAction {
  active: string;
  disabled: string;
  disableBg: string;
  divider: string;
  hoverOpacity: number;
  outlineBorder: string;
  backdropOverlay: string;
  snackBarBg: string;
}

export interface IPrimaryColor extends IBaseColor {
  light: string;
  dark: string;
  variant: string;
}

export interface IErrorColor extends IBaseColor {
  light: string;
}

export interface IBaseColor {
  main: string;
  contrast: string;
  shades: string[];
}

export interface ICommonColors {
  main: string;
  light: string;
  dark: string;
  contrast: string;
}

export interface ITextColors {
  primary: string;
  secondary: string;
  disabled: string;
  contrast: string;
}

export interface IThemeColorScheme
  extends IThemeCommonColorScheme,
    IThemeNaming {
  primary: IPrimaryColor;
  secondary: ICommonColors;
  secondaryContrastText?: string;
  accent: IAccentColor;
  background: IBackgroundColorScheme;
}

export const getColorChannel = (colorValue: string) => {
  const colorChannelValues = colorValue.match(/(?<=\()([^\\)])+/g);
  if (!colorChannelValues || colorChannelValues.length === 0) return null;
  return String(...colorChannelValues)
    .split(',')
    .join(' ');
};

interface ICustomShadows {
  [prop: string]: string;
}

const customShadows: ICustomShadows = {
  shadow1:
    '0px 1px 3px 0px hsla(226, 91%, 5%, 0.1), 0px 3px 8px 0px hsla(226, 91%, 5%, 0.04)',
  shadow2:
    '0px 2px 4px 0px hsla(226, 91%, 5%, 0.15), 0px 1px 2px 0px hsla(226, 91%, 5%, 0.1)',
  shadow3: `0px 4px 34px rgb(222 228 248 / 0.5)`,
  shadow4: `0px 3px 11px 0px hsla(226, 91%, 5%, 0.2), 0px 1px 2px 1px hsla(226, 91%, 5%, 0.1)`,
  shadow5: `0px 5px 7px 4px hsla(226, 91%, 5%, 0.2), 0px 1px 11px 2px hsla(226, 91%, 5%, 0.08)`
};
const shadowsToReplace: Record<number, string> = {
  1: customShadows.shadow1,
  3: customShadows.shadow2,
  4: customShadows.shadow1,
  5: customShadows.shadow4,
  8: customShadows.shadow1,
  10: customShadows.shadow3,
  11: customShadows.shadow5
};

const getThemeColorSchemes = (
  allAvailableColorSchemes: IThemeColorScheme[]
): Partial<Record<SupportedColorScheme, ColorSystemOptions>> => {
  const themesColorSchemes: Partial<
    Record<SupportedColorScheme, ColorSystemOptions>
  > = {};
  allAvailableColorSchemes.forEach((colorScheme: IThemeColorScheme) => {
    const paletteColors: ColorSystemOptions = {
      palette: {
        paletteMode: colorScheme.paletteMode,
        shadeFactor: colorScheme.shadeFactor,
        basic: {
          brightest: colorScheme.brightest,
          darkest: colorScheme.darkest
        },
        //It was needed to add the grey palette to avoid some console errors
        grey: { 700: 'hsl(0, 0, 70%)' },
        primary: {
          main: colorScheme.primary.main,
          light: colorScheme.primary.light,
          dark: colorScheme.primary.dark,
          contrastText: colorScheme.primary.contrast,
          shades: colorScheme.primary.shades,
          shadesChannels: getHslValues(colorScheme.primary.shades)
        },
        primaryLight: {
          light: colorScheme.secondary.light,
          main: colorScheme.secondary.main,
          dark: colorScheme.secondary.dark,
          contrastText: colorScheme.secondary.contrast
        },
        secondary: {
          main: colorScheme.accent.main,
          contrastText:
            colorScheme.secondaryContrastText ?? colorScheme.text.contrast
        },
        accent: {
          main: colorScheme.accent.main,
          contrastText:
            colorScheme.accent.contrastText ?? colorScheme.text.contrast
        },
        error: {
          light: colorScheme.error.light,
          main: colorScheme.error.main,
          dark: colorScheme.error.contrast,
          contrastText: colorScheme.error.contrast,
          shades: colorScheme.error.shades
        },
        warning: {
          light: colorScheme.warning.shades[0],
          main: colorScheme.warning.main,
          dark: colorScheme.warning.contrast,
          contrastText: colorScheme.warning.contrast
        },
        success: {
          light: colorScheme.success.shades[0],
          main: colorScheme.success.main,
          dark: colorScheme.success.contrast,
          contrastText: colorScheme.success.contrast
        },

        info: {
          light: colorScheme.info.contrast,
          main: colorScheme.info.main,
          dark: colorScheme.info.shades[0],
          contrastText: colorScheme.info.contrast
        },
        text: {
          primary: colorScheme.text.primary,
          secondary: colorScheme.text.secondary,
          disabled: colorScheme.text.disabled,
          contrast: colorScheme.text.contrast
        },
        common: {
          background: colorScheme.background.default
        },
        background: {
          paper: colorScheme.background.paper,
          default: colorScheme.background.default,
          backdrop: colorScheme.action.backdropOverlay,
          snackbar: colorScheme.action.snackBarBg
        },
        action: {
          main: colorScheme.action.disabled,
          active: colorScheme.action.active,
          hover: colorScheme.action.active,
          hoverOpacity: colorScheme.action.hoverOpacity,
          selected: colorScheme.action.active,
          disabled: colorScheme.action.disabled,
          disabledBackground: colorScheme.action.disableBg,
          focus: colorScheme.action.active,
          outlineBorder: colorScheme.action.outlineBorder
        },
        divider: colorScheme.action.divider,
        SnackbarContent: {
          bg: colorScheme.action.snackBarBg,
          color: colorScheme.text.contrast
        },
        LinearProgress: {
          primaryBg: colorScheme.primary.shades[3]
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        Skeleton: {
          bg: colorScheme.background.paper
        },
        getContrastText() {
          return colorScheme.text.contrast;
        }
      }
    };
    themesColorSchemes[colorScheme.paletteMode] = paletteColors;
  });
  return themesColorSchemes;
};

const baseTheme: CssVarsThemeOptions = {
  typography: {
    fontFamily: 'NotoSansDisplay, arial, sans-serif',
    allVariants: {
      fontFamily: 'NotoSansDisplay, arial, sans-serif'
    },
    h1: {
      fontSize: '2.125rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 600
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 600
    },
    subtitle1: { fontSize: '1rem', fontWeight: 600, lineHeight: 1.4 },
    body1: { fontSize: '1rem', fontWeight: 400, lineHeight: 1.4 },
    body2: { fontSize: '0.875rem', fontWeight: 400, lineHeight: 1.43 },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.02em',
      textTransform: 'none'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.43',
      letterSpacing: '0.025rem'
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 'normal',
      textTransform: 'unset'
    },
    label: { fontSize: '0.75rem', fontWeight: 400, lineHeight: 'normal' },
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    htmlFontSize: 16,
    helperText: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 'normal'
    },
    subtitle2: { fontSize: '0.875rem', fontWeight: 500 },
    tooltip: { fontSize: '0.625rem', fontWeight: 500, lineHeight: 1.4 }
  },
  breakpoints: {
    //if you change something here, go change it in _variables.scss too please
    values: {
      xxxs: 0,
      xxs: 640,
      xs: 768,
      sm: 1024,
      md: 1280,
      lg: 1440,
      xl: 1920,
      xxl: 2560
    }
  },
  shape: { borderRadius: 4 }, //need to have it in px or errors in console
  shadows: [...Array(25).fill('none')].map((shadow: string, index) => {
    return index in shadowsToReplace ? shadowsToReplace[index] : shadow;
  }) as Shadows,
  spacing: (factor: number) => `${0.5 * factor}rem`,
  components: styledMuiComponents,
  zIndex: {
    tooltip: 1700,
    modal: 1750,
    snackbar: 1800
  }
};

export const defaultTheme = extendTheme({
  ...baseTheme,
  colorSchemes: getThemeColorSchemes([
    defaultLightColorScheme,
    defaultDarkColorScheme
  ])
});

export const blackTheme = extendTheme({
  ...baseTheme,
  colorSchemes: getThemeColorSchemes([
    blackLightColorScheme,
    blackDarkColorScheme
  ])
});

export const greenTheme = extendTheme({
  ...baseTheme,
  colorSchemes: getThemeColorSchemes([
    greenLightColorScheme,
    greenDarkColorScheme
  ])
});

export const orangeTheme = extendTheme({
  ...baseTheme,
  colorSchemes: getThemeColorSchemes([
    orangeLightColorScheme,
    orangeDarkColorScheme
  ])
});

export const redTheme = extendTheme({
  ...baseTheme,
  colorSchemes: getThemeColorSchemes([redLightColorScheme, redDarkColorScheme])
});
