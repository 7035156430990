import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTenant } from 'src/app/contexts/tenant';

export const TenantFavicon = () => {
  const { tenant } = useTenant();

  const tenantFaviconUri = useMemo(() => {
    if (tenant?.brandImages?.favicon) {
      return tenant.brandImages.favicon.imageUri || '/favicon.ico';
    }
  }, [tenant?.brandImages?.favicon]);

  return (
    <Helmet>
      <link rel={'icon'} href={tenantFaviconUri} />
    </Helmet>
  );
};
