import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'src/app/pages/error/ErrorBoundary';
import { adminRoutes } from './admin.routes';

export const AdminError = () => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary
      goBackBtnText={t('error__go_back_admin')}
      goBackBtnLink={adminRoutes.paths[t('locale_key')]}
    />
  );
};
