import { StateCreator } from 'zustand';
import { uploadResetters } from '../uploadStore';

//need to declare initial state like this to be able to reset it
const uploadPageInitialState = {
  uploadSessionId: undefined
};

export interface IUploadSlice {
  uploadSessionId?: string;
  setUploadingSessionId: (id?: string) => void;
}

export const createUploadSlice: StateCreator<IUploadSlice> = (set) => {
  uploadResetters.push(() => set(uploadPageInitialState));

  return {
    ...uploadPageInitialState,
    setUploadingSessionId: (id?: string) => set(() => ({ uploadSessionId: id }))
  };
};
