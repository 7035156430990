import { LoaderFunctionArgs } from 'react-router-dom';
import { getThemeFromShareId } from 'src/app/api/controllers/share';
import { ICustomizationInfo } from 'src/app/api/models/tenants';

export async function shareLoader({
  request
}: LoaderFunctionArgs): Promise<{ data: ICustomizationInfo }> {
  const url = new URL(request.url);
  const shareId = url.searchParams.get('id') as string;

  return await getThemeFromShareId(shareId, request.signal);
}
