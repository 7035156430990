import './accessibility-link.scss';

export interface IAccessibilityLink {
  href: string;
  title: string;
}

export default function AccessibilityLink({ href, title }: IAccessibilityLink) {
  return (
    <a href={href} className="accessibility-link">
      {title}
    </a>
  );
}
