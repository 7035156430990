export const returnTermsOfUseEn = () => {
  return `#### General Terms of Use

  1. Web Solutions

  The website datacm.com is owned by DATA Communications Management Corp. (“DCM”) (the “Site”). If you access this Site or any of DCM’s web solutions (the “Solution”), you consent to be bound by the following terms (the “Terms”) and to comply with them. If you do not accept these Terms, do not use the Site or Solution. All of the content on this Site, including, without limitation, all software, text, data, material, information, icons, phrases, sound, programming, visual images, graphics, site design and the selection, organization, functionality, performance and arrangement of the foregoing including access to any of DCM’s other web solutions (collectively, the “Content”) may be modified at any time without prior notice. Changes can be made to the Terms at any time without prior notice by a simple update to the Terms.

  In addition to the terms set out above, DCM reserves the right, in its sole discretion, to restrict, suspend or terminate your access to all or any part of this Site or Solution at any time for any reason without prior notice to you and without any liability to you for doing so. DCM reserves the right to refuse service to any Site visitor. The Content and services provided on this Site and Solution are only available to individuals who can form legally binding contracts.
  **You must be at least 18 years of age to request and receive services from this Site. If you do not qualify, please do not use or communicate with this Site.**
  You agree to periodically consult the Terms and by continuing to access the Site or to use it, you accept any changes thereto.

  2. Exclusion of Warranties

  We follow and exceed industry standard security protocols aimed at protecting your information and transactions. Nevertheless, DCM cannot warrant that the Site of Solution will be available and able to respond to your needs, that access will not be interrupted, that there will be no delay, no breakdown, error or omission or loss in the transmission of information, that the Site is completely secured against viruses and other contaminating or destructive elements or that no damage will not be caused to your computer system. You are solely responsible to ensure the protection and adequate back up of your data or of your equipment and to take reasonable and appropriate precautions in regards to computer viruses or other destructive elements.

  DCM offers the Site and the Content “as is, as available” without warranties, representations or conditions of any kind, declaration or provision whatsoever, and in particular, warranties and conditions of merchantability, functionality, non-infringement of third parties' rights, currentness, title, utility, completeness, exactness, viability and suitability of the Content for a particular purpose, and implied warranties and conditions arising from the course of dealing, usage, trade or performance. You expressly agree that the entire risk as to the use, quality and performance of the functions on this Site and the accuracy or completeness of the Content on the Site is assumed solely by you.

  3. Limitation of Liability Clause

  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL DCM AND ITS OFFICERS, DIRECTORS, OWNERS, AGENTS, EMPLOYEES, REPRESENTATIVES AND LICENSORS (COLLECTIVELY, THE “DCM PARTIES”) BE LIABLE FOR ANY DAMAGES WHATSOEVER, EITHER DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL OR CONSECUTIVE RELATING TO THE USE OF THE SITE, THE INABILITY TO USE THE SITE OR THE CONTENT IT CONTAINS, OR FOLLOWING FROM THE USE OF PRODUCTS OR SERVICES OF ANY KIND, OR THE INABILITY TO USE THEM, FROM DELAYS OF OR PARTIAL DELIVERY, FROM TERMINATION OF RIGHTS OR LOSS OF PROFITS, DATA, BUSINESS OR GOODWILL, WHETHER ON A CONTRACTUAL OR EXTRA CONTRACTUAL BASIS, AND THE DATA PARTIES WILL NOT BE LIABLE TO PROVIDE INDEMNIFICATION OR ANY OTHER REMEDY TO YOU OR ANY THIRD PARTY. IN ANY EVENT, THE DCM PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, CLAIMS AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, SHALL BE LIMITED TO THE AMOUNT PAID BY YOU FOR A PARTICULAR SERVICE OR PRODUCT OFFERED ON THIS SITE. Your sole and exclusive remedy is to discontinue using and accessing the Site or Solution. If a part of this limitation is not permitted in the territory where you reside, then this part is not applicable. You agree that this limitation of liability is comprehensive and applies to all damages and causes of action of any kind.

  4. Indemnity

  You agree to indemnify, defend and hold harmless the DCM Parties from and against any and all claims, demands, actions, liability, losses, costs and expenses (including legal fees) arising from or related to: (i) any use or alleged use of the Site or Solution under your user name or password by any other person, whether or not authorized by you, (ii) your breach of these Terms, or (iii) your violation of any law or the rights of a third party arising out of your use of the Site or Solution. DCM reserves the right to assume the exclusive defense and control of any claims, demands and actions arising in connection with this indemnity and you agree to cooperate with DACM’s defense of these claims, demands and actions.

  5. Information Provided Electronically

  You acknowledge that information or material which you provide electronically by accessing or using the Site or Solution is neither confidential nor exclusive, except as required by applicable laws, and that unprotected communications by e-mail through the Internet are not confidential, may be intercepted or lost, or be subject to certain alterations. DCM is not responsible, to you or to anyone, for damages caused in the course of the transmission of information by electronic means to DCM, or that DCM sends you at your request.

  6. Links and Software

  Links to other web sites may be provided as a convenience only. DCM does not assume any responsibility for the content of any third party websites accessible via such links and for any products or services offered thereon, nor does DCM review, endorse or control such content, products or services. Access to another site via a link is done at your own risk and DCM is not liable for damages that this link may cause. Links to sites from which it is possible to download software are provided as a convenience only and DCM is not liable for difficulties or consequences caused by the remote downloading of software. The use of downloaded software is regulated by the provision of the license agreement which accompanies the software, if any.

  7. Damage to Others

  You agree not to introduce into or through the Site or Solution any information or materials which may be harmful to others. Among other things, you agree not to include, knowingly or otherwise, any error or defect in material or information which may, among other things, be a libel, slander, defamation or obscenity, or promote hatred or otherwise give rise to a criminal offence or civil liability on the part of any person or entity.

  8. Governing Law and Jurisdiction

  The Site and/or Solution shall be governed by the laws of Ontario and the applicable laws of Canada without reference to principles of conflict of laws. You agree to submit to the exclusive jurisdiction of the courts of the Province of Ontario in connection with the interpretation or application of these Terms, as well as for the final settlement of or ruling in any lawsuit, action or proceeding arising from or in connection with the use of the Site or Solution. DCM makes no representation that materials on the Site or Solution are appropriate or available for use in any particular location, nor as to the conformity of the Site and the Content with the law in the territories from which the Site or Solution is accessible. Those who choose to access the Site or Solution do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.

  9. Privacy

  During your use of the Site or Solution, you may voluntarily submit personal information to DCM. We are committed to ensuring responsible practices in the collection, use and disclosure of your personal information in accordance with applicable law. Please click here to review DCM’s [Privacy Policy](https://www.datacm.com/privacy-policy/). BY SUBMITTING PERSONAL INFORMATION TO DCM OR ITS SERVICE PROVIDERS AND AGENTS, YOU AGREE TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH PERSONAL INFORMATION FOR THE PURPOSE(S) PROVIDED, AS DESCRIBED IN AND IN ACCORDANCE WITH OUR PRIVACY
  POLICY, AND AS PERMITTED AND REQUIRED BY LAW. To the extent you provide us with personal information of another individual, you represent that you have the authority and/or have obtained all necessary consents from such individual to enable us to collect, use and disclose such information for the purpose(s) provided, and for the purposes set out in our Privacy Policy. If you do not agree with the Privacy Policy, please do not provide DCM with any personal information. The terms and conditions of the Privacy Policy are incorporated by reference into and form part of these Terms.

  10. Intellectual Property

  “DCM” and all other trade-marks, service marks, and logos, whether registered or not, indicated as belonging to DCM belong to DATA Communications Management Corp. , and cannot be used without the written authorization of their owner. All other trade-marks are the property of their respective owners.

  All of the Content is copyright © 2020-2023. DATA Communications Management corp. , unless otherwise indicated, and is owned or controlled by DCM or the party credited as the author or provider of the materials. ALL RIGHTS RESERVED.

  11. Restrictions on Use

  You may access, use and print a copy of the Content on this Site from your personal computer for your own personal use. You shall not modify, publish, transmit, transfer or sell, reproduce, create derivative works from, distribute, perform, display, or in any way exploit any of the Content, in whole or in part, except as expressly permitted in these Terms. Modification of the Content or use of the Content for any purpose other than one expressly permitted in these Terms (including use of any such material on any other web site or networked computer environment) is a violation of DCM’s and its licensor’s intellectual property rights and other proprietary rights. Any use or modification of the Content in whole or in part for any other purpose is strictly prohibited. Unauthorized attempts to upload information or change information on this Site are strictly prohibited. Unless otherwise specified, no one is authorized to copy, redistribute, reproduce, republish, fix under any format whatsoever, retransmit, modify or publicly or commercially use the Content, under any form whatsoever.

  You agree that any copy of the Content that you make shall retain all copyright and other proprietary notices in the same form and manner as on the original. Trade-marks may not be used in connection with any product or service not approved in advance in writing by DCM or in any manner that is likely to cause confusion among customers or the public, or in any manner that disparages or discredits DCM.

  12. Reserve of Rights

  All rights not expressly granted in these Terms are reserved to DCM. Without limiting the generality of the foregoing, nothing contained in these Terms shall be construed as conferring by implication or otherwise any license or right under any copyright, patent, trade-mark or other intellectual property right of DCM or any other person or entity.

  13. Survival of Obligations

  These Terms apply while you access the Site and remain in force thereafter, subject to DCM’s right to modify or otherwise update the Terms of use of this Site from time to time. If you no longer have access to this Site, the terms of the clauses entitled “Exclusion of Warranties”, “Limitation of Liability Clause”, “Information Provided Electronically”, “Indemnity”, “Intellectual Property” and “Restrictions on Use” shall remain in force.

  14. Registration

  To be able to place an order on the Site, you/your company must open an account on the Site which will have an associated user name and password. Keep them confidential because you will be held responsible in the case of fraudulent use of them. Do not allow anybody to use your account and do not give your password to anybody. You may not use or attempt to use another's account, password, service, system or other information that may be provided on this Site, nor allow others to use your account, password, service, or system, without prior written authorization from DCM, or create or use a false identity on this Site. You may not, nor may you allow others to attempt to obtain unauthorized access to this Site or portions of this Site which are restricted from general access.

  #### Products and Services

  All products and services offered by DCM through their Solution, as well as their use, are subject to the terms and conditions of all conventions or contracts governing their use.

  #### Orders Products and Services

  All orders of products and services offered by DCM through their Solution, including, but not limited to, modes of payment, prices, availability of products, delivery and return of products, are subject to the provisions of applicable agreements in force between you and DCM.
  `;
};
