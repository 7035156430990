import { MenuProps, SxProps, Theme } from '@mui/material';

export function getSelectSxProps(theme: Theme) {
  return {
    '&.Mui-focused': {
      // Mui don't support the borderRadius: 'x x x x' syntax
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.vars.palette.primary.shades[2]}`,
        borderWidth: '0.0625rem'
      }
    },
    '&.open': {
      borderTopRightRadius: '0.25rem',
      borderTopLeftRadius: '0.25rem',
      borderBottomRightRadius: '0',
      borderBottomLeftRadius: '0'
    },
    '.MuiSelect-icon': {
      color: theme.vars.palette.text.secondary
    }
  } as SxProps<Theme>;
}

export function getSelectMenuProps(theme: Theme) {
  return {
    PaperProps: {
      sx: {
        // Mui don't support the borderRadius: 'x x x x' syntax
        borderTopRightRadius: '0',
        borderTopLeftRadius: '0',
        borderBottomRightRadius: '0.5rem',
        borderBottomLeftRadius: '0.5rem',
        borderWidth: '0.0625rem',
        borderColor: `${theme.vars.palette.primary.shades[2]}`,
        borderStyle: 'solid',
        borderTop: 'unset',
        // to counter overflow
        '.MuiMenuItem-root': {
          wordWrap: 'break-word',
          width: '100%',
          display: 'inherit',
          whiteSpace: 'pre-wrap'
        }
      }
    }
  } as Partial<MenuProps>;
}

export function getSelectSortSxProps(theme: Theme) {
  return {
    '&.MuiInputBase-root': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.vars.palette.primary.shades[2]}`,
        borderWidth: '2px',
        borderRadius: '8px'
      },
      '.MuiSelect-select': {
        padding: `${theme.spacing(0.5, 5, 0.625, 0.5)} !important`,
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '1.374rem'
      }
    },
    '&.opened': {
      '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px 8px 0 0'
      }
    }
  } as SxProps<Theme>;
}

export function getSelectSortMenuProps(theme: Theme) {
  return {
    PaperProps: {
      sx: {
        border: `2px solid ${theme.vars.palette.primary.shades[2]}`,
        borderTop: 0,
        borderRadius: '0 0 8px 8px',
        backgroundColor: theme.palette.background.default,
        padding: `${theme.spacing(1, 0, 0)} !important`,
        '&:focus-visible': {
          outline: '0 !important'
        },
        '.MuiMenuItem-root': {
          padding: theme.spacing(0.375, 1.375),
          margin: theme.spacing(0, 0.875),
          fontSize: '0.875rem',
          lineHeight: '1.251rem',
          minHeight: '26px',
          borderRadius: '4px',
          border: `1px solid transparent`,
          marginBottom: '6px',
          transition: `all 0.15s ease-in`,
          '&:hover': {
            padding: theme.spacing(0.375, 1.375),
            borderRadius: '4px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.vars.palette.primary.shades[1]}`
          },
          '&.Mui-selected': {
            fontWeight: 400,
            padding: theme.spacing(0.375, 1.375),
            borderRadius: '4px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.vars.palette.primary.shades[1]}`,
            '&:hover': {
              backgroundColor: theme.palette.background.paper
            },
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.background.paper
            }
          }
        }
      }
    }
  } as Partial<MenuProps>;
}
