import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import homeRoute from 'src/app/pages/home/home.routes';

export const EmptyRoute = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  // Don't redirect if we are in the login flow
  if (searchParams.has('idToken')) {
    return <></>;
  }

  return <Navigate to={homeRoute.paths[t('locale_key')]} replace />;
};
