import {
  getPrimaryDarkColorShade,
  getPrimaryLightColorShade
} from '../_components/utils/colors';
import { IThemeColorScheme, IThemeNaming } from '../customTheme';
import { baseDarkColorScheme, baseLightColorScheme } from './baseColorScheme';
import i18next from 'i18next';

const themeNaming: IThemeNaming = {
  colorName: 'red',
  label: i18next.t('settings__color_scheme_label_red')
};

export const redLightColorScheme: IThemeColorScheme = {
  ...baseLightColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(355, 69%, 45%)',
    light: 'hsl(355, 71%, 51%)',
    dark: 'hsl(355, 69%, 42%)',
    variant: 'hsl(355, 69%, 42%)',
    shades: getPrimaryLightColorShade('355'),
    contrast: baseLightColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(356, 47%, 94%)',
    light: 'hsl(354, 50%, 96%)',
    dark: 'hsl(355, 48%, 90%)',
    contrast: 'hsl(355, 68%, 42%)'
  },
  accent: {
    main: 'hsl(227, 80%, 38%)'
  },
  background: {
    ...baseLightColorScheme.background,
    default: 'hsl(0, 13%, 96%)'
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(355, 71%, 51%)',
    disabled: 'hsl(335, 15%, 86%)',
    disableBg: 'hsla(335, 18%, 9%, 0.5)',
    divider: 'hsl(335, 22%, 90%)',
    outlineBorder: 'hsl(355, 49%, 90%)',
    backdropOverlay: 'hsla(338, 18%, 9%, 0.5)'
  }
};
export const redDarkColorScheme: IThemeColorScheme = {
  ...baseDarkColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(0, 77%, 56%)',
    light: 'hsl(0, 100%, 62%)',
    dark: 'hsl(0, 68%, 53%)',
    variant: 'hsl(0, 68%, 53%)',
    shades: getPrimaryDarkColorShade('230', '5%', [
      '21%',
      '24%',
      '29%',
      '33%',
      '43%'
    ]),
    contrast: baseDarkColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(231, 6%, 24%)',
    light: 'hsl(231, 6%, 24%)',
    dark: 'hsl(230, 6%, 21%)',
    contrast: baseDarkColorScheme.text.contrast
  },
  accent: {
    main: 'hsl(225, 77%, 60%)'
  },
  error: {
    ...baseLightColorScheme.error,
    main: 'hsl(0, 78%, 37%)',
    light: 'hsl(0, 98%, 56%)',
    shades: ['hsla(360, 79%, 28%, 0.6)', 'hsl(0, 79%, 19%)']
  },
  action: {
    ...baseDarkColorScheme.action,
    active: 'hsl(0, 100%, 56%)',
    divider: 'hsl(0, 0%, 12%)',
    outlineBorder: 'hsl(0, 0%, 35%)',
    backdropOverlay: 'hsla(0, 6%, 20%, 0.8)'
  },
  warning: {
    main: 'hsl(39, 80%, 61%)',
    contrast: 'hsl(38, 31%, 25%)',
    shades: ['hsl(40, 74%, 70%)']
  }
};
