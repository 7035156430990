import axiosInstance, { AXIOS_CANCELED } from '../axiosInstance';
import { IJsonPatchOperation } from '../jsonPatch';
import { ESearchType } from '../models/search';
import {
  IRecentSuggestions,
  IVisibilityGroup,
  IRecentSearch,
  IUserActivities,
  IUserInfo
} from '../models/users';

const USERS_PREFIX = 'api/v1/users';
const VISIBILITY_GROUPS_SUFFIX = `myvisibilitygroups`;
const USERS_ME = `${USERS_PREFIX}/me`;
const USERS_ME_SEARCH = `${USERS_ME}/search`;
const USERS_ME_SEARCH_SUGGESTION = `${USERS_ME_SEARCH}/suggestion`;
const USER_ME_ACTIVITIES = `${USERS_PREFIX}/me/activities`;

export async function getMyUser(controller?: AbortController): Promise<{
  data: IUserInfo;
}> {
  return await axiosInstance.get(USERS_ME, { signal: controller?.signal });
}

export async function updateMyUser(
  jsonPatchDocument: IJsonPatchOperation[],
  lastModified: string,
  controller?: AbortController
): Promise<{
  data: IUserInfo;
}> {
  return await axiosInstance.patch(
    `${USERS_ME}?lastModified=${lastModified}`,
    jsonPatchDocument,
    {
      signal: controller?.signal
    }
  );
}

export async function getUserActivities(): Promise<{
  data: IUserActivities;
}> {
  return await axiosInstance.get(USER_ME_ACTIVITIES);
}

export async function getUserVisibilityGroups(
  controller?: AbortController
): Promise<{
  data: IVisibilityGroup[];
}> {
  return await axiosInstance.get(
    USERS_PREFIX + '/' + VISIBILITY_GROUPS_SUFFIX,
    { signal: controller?.signal }
  );
}

export async function getUserRecentSearches(
  searchType: ESearchType,
  signal?: AbortSignal
): Promise<{
  data: IRecentSearch[];
}> {
  return new Promise((resolve) =>
    axiosInstance
      .get(`${USERS_ME_SEARCH_SUGGESTION}?searchType=${searchType}`, {
        signal: signal
      })
      .then((result) => {
        const data: IRecentSuggestions = result.data;
        //TODO temporary mock until recentViewedAsset have a creationDate
        // Recent View Asset disabled for Beta
        //data.recentViewedAsset.map((rva) => ({
        //   ...rva,
        //   creationDate: new Date('2022-12-08T17:01:26.2799058+00:00')
        // }));
        const merged: IRecentSearch[] = data.recentSearch.filter(
          (item, index, arr) => {
            //remove duplicates from array
            return (
              arr.findIndex((el) => el.searchText === item.searchText) === index
            );
          }
        );
        // Recent View Asset disabled for Beta
        //...data.recentViewedAsset.map(
        //   (rva) => new RecentViewedAsset(rva.id, rva.name, rva.creationDate)
        // ),

        const sorted = merged.sort(
          (a, b) =>
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
        );
        resolve({ data: sorted });
      })
      .catch((err) => {
        if (err === AXIOS_CANCELED) return;
      })
  );
}
