import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStackableAlert } from 'src/app/components/feedback/alert/StackableAlert';
import { useAuth } from 'src/app/stores';
import { useUserLanguage } from 'src/app/utilities/hooks/useUserLanguage';
import { Language, getCurrentLanguage } from 'src/app/utilities/i18n';

export const useSettingLanguageForm = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const { enqueueStackableAlert } = useStackableAlert();
  const {
    allowedLanguages,
    getAllowedLanguages,
    setUserLanguage,
    changeInterfaceLanguage
  } = useUserLanguage();
  const [isLanguageLoading, setIsLanguageLoading] = useState<boolean>();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    userInfo?.preferredLanguage || getCurrentLanguage()
  );
  const [showSaveButton, setShowSetButton] = useState<boolean>(false);

  //sets the allowed languages and selectedLanguages
  // from the back-end
  useEffect(() => {
    getAllowedLanguages();

    return () => {
      setIsLanguageLoading(false);
    };
  }, [getAllowedLanguages]);

  const handleLanguageChange = async (e: SelectChangeEvent<Language>) => {
    const language = e.target.value as Language;
    setSelectedLanguage(language);
    if (language !== userInfo?.preferredLanguage) {
      setShowSetButton(true);
    } else {
      setShowSetButton(false);
    }
  };

  const handleLanguageSubmit = async () => {
    setIsLanguageLoading(true);
    try {
      await setUserLanguage(selectedLanguage);
      enqueueStackableAlert({
        message: t('settings__language-validation-message')
      });
      setShowSetButton(false);
      await changeInterfaceLanguage(selectedLanguage);
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err);
      }

      enqueueStackableAlert({
        message: t('error__generic__title'),
        customSeverity: 'error'
      });
    } finally {
      setIsLanguageLoading(false);
    }
  };

  return {
    selectedLanguage,
    handleLanguageChange,
    allowedLanguages,
    handleLanguageSubmit,
    showSaveButton,
    isLanguageLoading
  };
};
