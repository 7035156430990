import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledRadios: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1)
      })
    }
  }
};
