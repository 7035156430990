import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme
} from '@mui/material';
import './sidebar.scss';
import {
  adminRoutes,
  catalogsRoutes,
  attributesRoutes,
  usersAndGroupsRoutes,
  companySettingsRoutes,
  advancedFiltersRoutes
} from 'src/app/pages/admin/admin.routes';
import routes from 'src/app/pages/home/home.routes';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import clsx from 'clsx';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';
import { NavLink } from 'react-router-dom';
import { adminStore } from 'src/app/stores/admin/adminStore';
import {
  SIDEBAR_REM_WIDTH_FULL,
  SIDEBAR_REM_WIDTH_SMALL
} from 'src/app/utilities';
import { useAuth } from 'src/app/stores';
import { useSettings } from 'src/app/stores/settingsStore';
import { SettingsModal } from 'src/app/pages/settings/SettingsModal';
import { Logo } from 'src/app/components/dataDisplay/brandImages/Logo';
import { IAdminNavItems, NavigationItem } from './NavigationItem';
import { useAuthentication } from 'src/app/contexts/authentication';
import { getUserDisplayName } from 'src/app/utilities/user';

export const Sidebar = () => {
  const theme = useTheme();
  const [t] = useTranslation();
  const { changeSidebarWidth } = adminStore();
  const { userInfo } = useAuth();
  const { logout } = useAuthentication();

  const [open, setOpen] = useState<boolean>(true);
  const [showUserOptions, setShowUserOptions] = useState<boolean>(false);
  const { settingsModalOpen, setSettingsModalOpen } = useSettings();

  const navItems: IAdminNavItems[] = useMemo(
    () => [
      {
        title: t('admin__nav_dashboard'),
        icon: 'home',
        route: adminRoutes.paths[t('locale_key')]
      },
      {
        title: t('admin__nav_users_groups'),
        icon: 'admin-group',
        route: usersAndGroupsRoutes.paths[t('locale_key')]
      },
      {
        title: t('admin__nav_catalogs'),
        icon: 'catalog',
        route: catalogsRoutes.paths[t('locale_key')]
      },
      {
        title: t('admin__nav_feature_config'),
        icon: 'notebook',
        children: [
          {
            title: t('admin__nav_attributes'),
            icon: '-',
            route: attributesRoutes.paths[t('locale_key')]
          },
          {
            title: t('admin__nav_advanced_filters'),
            icon: '-',
            route: advancedFiltersRoutes.paths[t('locale_key')]
          }
        ]
      },
      {
        title: t('admin__nav_company_settings'),
        icon: 'home',
        route: companySettingsRoutes.paths[t('locale_key')]
      }
    ],
    [t]
  );

  //use this to change sidebar size
  const handleHover = () => {
    const newOpenValue = !open;
    setOpen(newOpenValue);

    changeSidebarWidth(
      !open ? SIDEBAR_REM_WIDTH_FULL : SIDEBAR_REM_WIDTH_SMALL
    );
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx('reversed-color', 'sidebar', {
          'sidebar-closed': !open,
          'dark-mode': theme.palette.mode === 'dark'
        })}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(4)}
          flexGrow={1}
          component={'nav'}
        >
          <Box className={clsx({ 'sidebar-logo': true }, { open: open })}>
            <NavLink to={routes.paths[t('locale_key')]}>
              <Logo forceWhiteLogo className="sidebar-logo-img" />
            </NavLink>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow={1}
          >
            <List className="sidebar__list">
              {navItems.map((item, index) => (
                <NavigationItem
                  key={`sidebar-nav-${index}`}
                  item={item}
                  smallNavigation={open}
                />
              ))}
            </List>
            <div className="sidebar__list">
              <List>
                <ListItemButton
                  href={t('general__support_external_link')}
                  target="_blank"
                  rel="noreferrer"
                  className={clsx('sidebar__account', { open: open })}
                >
                  <ListItemIcon className={clsx({ open: open })}>
                    <CustomIcons
                      iconName="help"
                      customStyles={{ fontSize: '1.25rem' }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={t('admin__nav_support')}
                    className={clsx({ open: open })}
                  />
                </ListItemButton>
              </List>

              <List
                className={clsx('sidebar__item-with-child', {
                  'show-child': showUserOptions
                })}
              >
                {/* User first name button */}
                <ListItemButton
                  onClick={() => setShowUserOptions(!showUserOptions)}
                  className={clsx('sidebar__item-with-child item', {
                    open: open
                  })}
                >
                  <Box
                    className={clsx('sidebar__item-with-child item', {
                      open: open
                    })}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                      width: '100%'
                    }}
                  >
                    {userInfo ? (
                      <ListItemText
                        primary={getUserDisplayName(userInfo)}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: theme.palette.text.primary
                        }}
                        secondary={userInfo.emailAddress}
                        secondaryTypographyProps={{
                          variant: 'tooltip'
                        }}
                        className={clsx({ open: open })}
                        sx={{ margin: 0 }}
                      />
                    ) : (
                      <Skeleton
                        animation={'wave'}
                        variant={'rectangular'}
                        height={theme.spacing(6.25)}
                      />
                    )}
                    <CustomIcons
                      iconName={
                        showUserOptions
                          ? 'arrow-dropdown-down'
                          : 'arrow-dropdown'
                      }
                      customStyles={{ fontSize: '1.25rem' }}
                    />
                  </Box>
                </ListItemButton>

                {/* collapsable user actions */}
                <Collapse in={showUserOptions}>
                  <ListItemButton
                    component={NavLink}
                    to={routes.paths[t('locale_key')]}
                    className={clsx(
                      'sidebar__item-with-child item item--child',
                      {
                        open: open
                      }
                    )}
                  >
                    <ListItemIcon className={clsx({ open: open })}>
                      <CustomIcons
                        iconName="arrow-down-up"
                        customStyles={{ rotate: '90deg' }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('admin__sidebar_change_platefform')}
                      className={clsx({ open: open })}
                    />
                  </ListItemButton>
                  <ListItemButton
                    className={clsx(
                      'sidebar__item-with-child item item--child',
                      {
                        open: open
                      }
                    )}
                    onClick={() => setSettingsModalOpen(true)}
                  >
                    <ListItemIcon className={clsx({ open: open })}>
                      <CustomIcons iconName="gear" />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('settings__nav_label')}
                      className={clsx({ open: open })}
                    />
                  </ListItemButton>
                  <ListItemButton
                    className={clsx(
                      'sidebar__item-with-child item item--child top-separator',
                      {
                        open: open,
                        dark: theme.palette.mode === 'dark'
                      }
                    )}
                    onClick={() => logout()}
                  >
                    <ListItemIcon className={clsx({ open: open })}>
                      <CustomIcons iconName="log-out" />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('icon__logout')}
                      className={clsx({ open: open })}
                    />
                  </ListItemButton>
                </Collapse>
              </List>
            </div>
          </Box>
        </Box>
      </Drawer>
      <SettingsModal
        open={settingsModalOpen}
        onClose={() => {
          setSettingsModalOpen(false);
        }}
      />
    </>
  );
};
