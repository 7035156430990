import { PaletteMode } from '@mui/material';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import { getMyUser, getUserActivities } from 'src/app/api/controllers/users';
import { useAuth } from 'src/app/stores';
import { logErrorInConsole } from 'src/app/utilities';
import { useUserLanguage } from 'src/app/utilities/hooks/useUserLanguage';
import { useCustomTheme } from 'src/styles/_theme';
import { useAuthentication } from '../authentication';

interface IUserProviderProps {
  children: ReactNode;
}

const UserContext = createContext({});

export const UserProvider = ({ children }: IUserProviderProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { isAuthenticated, isLoading } = useAuthentication();
  const { userInfo, idToken, setUserInfo, setClaims } = useAuth();
  const { setMode } = useCustomTheme();
  const {
    setUserLanguage,
    getDefaultTenantLanguage,
    changeInterfaceLanguage,
    getAllowedLanguages
  } = useUserLanguage();

  const setupUserInfo = useCallback(async () => {
    if (isInitialized || (!!userInfo && !!idToken)) {
      return;
    }

    const controller = new AbortController();

    try {
      const existingUser = await getMyUser(controller);

      const user = existingUser?.data;

      setMode(user.displayMode.toLocaleLowerCase() as PaletteMode);

      const activities = await getUserActivities();
      setClaims(activities.data);

      setUserInfo(user);

      const userLanguage = user?.preferredLanguage;
      if (userLanguage?.length === 0 || userLanguage === undefined) {
        // Get the AllowLanguages before trying to update the user settings
        await getAllowedLanguages();
        // Update the language in the database without any navigation
        setUserLanguage(getDefaultTenantLanguage());
      } else {
        await changeInterfaceLanguage(userLanguage);
      }
    } catch (err) {
      controller.abort();
      logErrorInConsole('login auth provider', err);
    } finally {
      setIsInitialized(true);
    }
    // Need to be done one time only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      setupUserInfo();
    }
  }, [isAuthenticated, isLoading, setupUserInfo]);

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
};
