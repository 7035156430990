import { IThemeCommonColorScheme } from '../customTheme';

export const baseLightColorScheme: IThemeCommonColorScheme = {
  paletteMode: 'light',
  shadeFactor: '1',
  background: {
    paper: 'hsl(0, 0%, 100%)',
    default: 'hsl(225, 50%, 96%)'
  },
  brightest: 'hsl(0, 0%, 100%)',
  darkest: 'hsl(0, 0%, 21%)',
  text: {
    primary: 'hsl(300, 1%, 19%)',
    secondary: 'hsl(206, 13%, 32%)',
    disabled: 'hsl(207, 6%, 66%)',
    contrast: 'hsl(0, 0%, 100%)'
  },
  warning: {
    main: 'hsl(37, 66%, 59%)',
    contrast: 'hsl(37, 46%, 31%)',
    shades: ['hsl(35, 60%, 96%)']
  },
  success: {
    main: 'hsl(97, 43%, 48%)',
    contrast: 'hsl(96, 29%, 20%)',
    shades: ['hsl(100, 29%, 94%)']
  },
  info: {
    main: 'hsl(201, 98%, 41%)',
    contrast: 'hsl(201, 69%, 94%)',
    shades: ['hsl(201, 97%, 17%)']
  },
  error: {
    main: 'hsl(16, 98%, 43%)',
    light: 'hsl(16, 98%, 48%)',
    contrast: 'hsl(0, 0%, 100%)',
    shades: ['hsl(13, 70%, 96%)', 'hsl(16, 71%, 20%)']
  },
  action: {
    active: 'hsl(227, 65%, 42%)',
    disabled: 'hsl(229, 18%, 88%)',
    disableBg: 'hsla(227, 81%, 13%, 0.5)',
    divider: 'hsl(227, 49%, 90%)',
    hoverOpacity: 0.04,
    outlineBorder: 'hsl(227, 49%, 90%)',
    backdropOverlay: 'hsla(300, 1%, 19%, 0.5)',
    snackBarBg: 'hsl(0, 0%, 20%)'
  },
  accent: {
    main: 'hsl(17, 95%, 58%)'
  }
};
export const baseDarkColorScheme: IThemeCommonColorScheme = {
  ...baseLightColorScheme,
  paletteMode: 'dark',
  shadeFactor: '-1',
  background: {
    paper: 'hsl(230, 5%, 15%)',
    default: 'hsl(230, 5%, 11%)'
  },
  text: {
    primary: 'hsl(0, 0%, 100%)',
    secondary: 'hsl(206, 13%, 76%)',
    disabled: 'hsl(206, 13%, 51%)',
    contrast: 'hsl(0, 0%, 100%)'
  },
  warning: {
    main: 'hsl(37, 66%, 59%)',
    contrast: 'hsl(37, 46%, 31%)',
    shades: ['hsl(35, 60%, 96%)']
  },
  success: {
    main: 'hsl(147, 54%, 39%)',
    contrast: 'hsl(0, 0%, 100%)',
    shades: ['hsl(148, 54%, 39%)']
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(225, 100%, 67%)',
    disabled: 'hsl(227, 15%, 40%)',
    disableBg: 'hsla(230, 5%, 22%, 0.7)',
    divider: 'hsl(227, 30%, 18%)',
    outlineBorder: 'hsl(226, 35%, 34%)',
    backdropOverlay: 'hsla(234, 11%, 19%, 0.8)',
    snackBarBg: 'hsl(0, 0%, 100%)'
  },
  accent: {
    main: 'hsl(17, 95%, 58%)'
  }
};
