export const returnTermsOfUseFr = () => {
  return `#### Modalités générales dutilisation

  1. Solutions Web

  Le site Web datacm.com appartient à DATA Communications Management Corp. (« DCM ») (le « site »).
  Si vous consultez le site ou l'une des solutions Web de DCM (la « solution »), vous acceptez d'être lié par les modalités suivantes (les « modalités ») et de vous y conformer. Si vous n'acceptez pas ces modalités, nous vous remercions de ne pas utiliser le site ou la solution. Tout le contenu du présent site, y compris, sans s'y limiter, les logiciels, les textes, les données, le matériel, l'information, les icônes, les phrases, les sons, la programmation, les images visuelles, les graphiques, la conception du site et la sélection, l'organisation, la fonctionnalité, le rendement et les dispositions de ce qui précède, y compris l'accès à toute autre solution Web de DCM (collectivement, le « contenu »), peut être modifié en tout temps sans avis préalable.
  Des modifications peuvent être apportées aux modalités en tout temps, et ce, sans préavis par voie d’une simple mise à jour.

  En plus des modalités énoncées ci-dessus, DCM se réserve le droit, à sa seule discrétion, de limiter, de suspendre ou d’annuler votre accès en tout ou en partie du site ou de la solution en tout temps, pour n’importe quelle raison, sans préavis ni responsabilité à votre égard.
  DCM se réserve le droit de refuser de servir tout visiteur qui a accès au site. Le contenu et les services fournis sur le présent site et la solution ne sont offerts qu'aux personnes qui peuvent conclure des contrats exécutoires en vertu du droit applicable.
  **Vous devez avoir au moins 18 ans pour demander des services auprès du présent site et les recevoir.
  Si vous ne répondez pas à cette exigence, veuillez quitter ce site et ne pas communiquer avec celui-ci.**
  Vous acceptez de consulter périodiquement les modalités et, en continuant d’accéder au site ou à l'utiliser, vous acceptez les modifications qui y sont apportées.

  2. Exclusion des garanties

  Nous respectons et dépassons les protocoles de sécurité normalisés de l'industrie qui visent à protéger vos renseignements et vos transactions. Néanmoins, DCM ne peut garantir que le site de la solution sera disponible, ni qu’il satisfera à vos exigences, ni que l’accès à celui-ci ne sera pas interrompu, qu’il n’y aura pas de délais, de défaillances, d’erreurs, d’omission ou de pertes de l’information transmise, ni qu’aucun virus ou autre élément contaminant ou destructeur ne sera transmis, ni qu’aucun dommage ne sera causé à votre système informatique.
  Vous êtes seul responsable d'assurer la protection et l’enregistrement adéquat de vos données ou de votre équipement, et de prendre les mesures de précaution qui s’imposent pour détecter la présente de virus informatiques ou d’autres éléments destructeurs.

  DCM offre le site et le contenu « en l’état, tel quel »; elle ne fait aucune déclaration ni ne donne aucune garantie ou condition expresse ou implicite, de quelque nature que ce soit, sans garantie de commercialité, de fonctionnalité, de non-violation des droits de tiers, d'actualité, de titre, d'utilité, d'exhaustivité, d'exactitude, de viabilité et d’aptitude à l’usage, et sans garantie et condition implicites découlant de la conduite habituelle des activités, de l'utilisation ou de pratiques du commerce. Vous convenez expressément que l’ensemble des risques quant à l'utilisation, à la qualité et au rendement des fonctions de ce site, ainsi qu'à l'exactitude et à l'exhaustivité du contenu du site relèvent de votre responsabilité.

  3. Clause de limitation de responsabilité

  DANS LA MESURE MAXIMALE PERMISE PAR LA LOI APPLICABLE, DCM ET SES DIRIGEANTS, ADMINISTRATEURS, PROPRIÉTAIRES, AGENTS, EMPLOYÉS, REPRÉSENTANTS ET CONCÉDANTS DE LICENCE (COLLECTIVEMENT, LES « PARTIES DE DCM ») NE SERONT NULLEMENT TENUS RESPONSABLES DE QUELQUE DOMMAGE QUE CE SOIT, DIRECT, INDIRECT, ACCESSOIRE, PUNITIF, EXEMPLAIRE, SPÉCIAL OU CONSÉCUTIF, DÉCOULANT DE L'UTILISATION DU SITE OU DE L’INCAPACITÉ D’UTILISER LE SITE OU SON CONTENU, OU DE L'UTILISATION DE PRODUITS OU DE SERVICES DE TOUTE NATURE, OU DE L’INCAPACITÉ DE LES UTILISER, DE RETARDS DANS LA LIVRAISON OU DE LIVRAISONS PARTIELLES, DE L’EXTINCTION DE DROITS OU DE LA PERTE DE PROFITS, DE DONNÉES, D'OCCASIONS D’AFFAIRES OU D’ACHALANDAGE, QUE CES DOMMAGES SOIENT FONDÉS SUR LA RESPONSABILITÉ CONTRACTUELLE OU NON CONTRACTUELLE, ET LES PARTIES NE SONT PAS TENUES DE VOUS ACCORDER OU D’ACCORDER À UN TIERS UNE INDEMNISATION OU UN AUTRE RECOURS. LA RESPONSABILITÉ GLOBALE DES PARTIES DE DCM CONCERNANT LES DOMMAGES, BLESSURES, PERTES, RÉCLAMATIONS OU CAUSES D'ACTION, QUE CE SOIT DE MANIÈRE CONTRACTUELLE, DÉLICTUELLE (Y COMPRIS, MAIS SANS S'Y LIMITER, LA NÉGLIGENCE) OU AUTRE, NE POURRA DÉPASSER LE MONTANT QUE VOUS AVEZ PAYÉ POUR UN SERVICE OU UN PRODUIT PARTICULIER OFFERT SUR CE SITE. Votre seule et unique réparation consiste à cesser d'utiliser le site ou la solution et à ne plus y accéder. Si une partie de cette limitation n'est pas permise dans votre territoire de résidence, cette partie ne s’appliquera pas. Vous convenez que cette limitation de responsabilités est exhaustive et qu’elle s'applique à tous les dommages et à toutes les causes d'action de quelque nature que ce soit.

  4. Indemnisation

  Vous convenez d'indemniser, de défendre et de dégager de toute responsabilité les parties des réclamations, demandes, actions, pertes, coûts et dépenses de tous genres (y compris les frais juridiques) entraînés ou liés à : (i) toute utilisation ou utilisation présumée du site ou de la solution sous votre nom d'utilisateur ou votre mot de passe par une autre personne, autorisée ou non par vous, (ii) votre violation des présentes modalités, ou (iii) votre violation de toute loi ou des droits d'un tiers découlant de votre utilisation du site ou de la solution. DCM se réserve le droit d'assumer la défense et le contrôle exclusifs de toute réclamation, demande et action découlant de la présente indemnisation et vous acceptez de coopérer à faire valoir les droits de DCM à l'égard de ces réclamations, demandes et actions.

  5. Renseignements fournis par voie électronique

  Vous reconnaissez que l'information ou le matériel que vous fournissez par voie électronique en accédant au site ou à la solution ou en les utilisant ne sont pas confidentiels ni exclusifs, sauf dans les cas prévus par les lois applicables, et que les communications non protégées par courrier électronique sur Internet ne sont pas confidentielles, qu’elles peuvent être interceptées ou perdues, ou être altérées. DCM n'est pas tenue responsable, envers vous ni envers quiconque, de quelque dommage que ce soit et quelle qu’en soit la cause lors de la transmission de renseignements par voie électronique à DCM, ou lorsque DCM vous transmet des renseignements à votre demande.

  6. Liens et logiciels

  Des liens vers d'autres sites Web peuvent être fournis au bénéfice de l’utilisateur seulement. DCM se dégage de toute responsabilité quant au contenu des sites Web de tiers accessibles par ces liens et aux produits ou services qui y sont offerts, et DCM n’examine, ni approuve ni ne contrôle ce contenu, ces produits ou ces services. L'accès à un autre site par voie d’un lien est à vos propres risques et DCM n'est pas responsable des dommages éventuels résultant de son utilisation. Les liens menant à des sites à partir desquels on peut télécharger des logiciels sont fournis uniquement pour la commodité des utilisateurs et DCM n'est pas responsable des problèmes ou conséquences causés par le téléchargement de logiciels à distance. L'utilisation des logiciels téléchargés est sous réserve des dispositions de l'accord de licence qui accompagne le logiciel, le cas échéant.

  7. Dommages à autrui

  Vous vous engagez à ne pas introduire sur le site ou la solution, ou par leur intermédiaire, des renseignements ou des éléments susceptibles de nuire à autrui. Vous convenez notamment de ne pas inclure, sciemment ou non, des renseignements ou des documents faux ou erronés qui pourraient constituer, entre autres choses, un élément de diffamation, ou donner lieu à une infraction criminelle ou engager la responsabilité de quiconque.

  8. Loi et instances compétentes

  Le site ou la solution sont régis par les lois de l'Ontario et les lois applicables au Canada, sans égard à aucun principe de conflit de lois. Vous consentez à vous soumettre à la compétence exclusive des tribunaux de la province de l'Ontario en ce qui a trait à l'interprétation ou à l'application des présentes modalités, ainsi qu'au règlement définitif ou à la décision finale dans le cadre de toute poursuite, action ou procédure découlant de l'utilisation du site ou de la solution ou s'y rapportant. DCM ne garantit nullement que le contenu du site ou de la solution soit approprié ou offert à des fins d'utilisation dans un endroit particulier, ni de conformité du site et du contenu avec la loi dans les territoires à partir desquels le site ou la solution est accessible. Les personnes qui choisissent d'accéder au site ou à la solution le font de leur propre initiative et doivent se conformer aux lois locales, si celles-ci sont applicables ou dans la mesure inhérente.

  9. Protection des renseignements personnels

  Au cours de votre utilisation du site ou de la solution, vous pouvez volontairement soumettre des renseignements personnels à DCM.
  Nous nous engageons à assurer des pratiques responsables dans la collecte, l'utilisation et la divulgation de vos renseignements personnels conformément aux lois applicables.
  Veuillez cliquer ici pour consulter la politique de protection des renseignements personnels de DCM [la politique de confidentialité](https://www.datacm.com/privacy-policy/).
  EN FOURNISSANT DES RENSEIGNEMENTS PERSONNELS À DCM OU À SES FOURNISSEURS DE SERVICES ET AGENTS, VOUS CONSENTEZ À CE QUE NOUS PUISSIONS RECUEILLIR, UTILISER OU DIVULGUER LESDITS RENSEIGNEMENTS PERSONNELS AUX FINS PRÉVUES, TEL QU'IL EST PRESCRIT DANS NOTRE POLITIQUE DE CONFIDENTIALITÉ ET CONFORMÉMENT À CETTE DERNIÈRE, ET TEL QU’IL EST PERMIS ET REQUIS PAR LA LOI.
  Lorsque vous nous fournissez des renseignements personnels concernant une autre personne, vous déclarez en avoir l'autorité ou avoir obtenu tous les consentements nécessaires de cette personne pour nous permettre de recueillir, d'utiliser et de divulguer ces renseignements aux fins prévues et à celles énoncées dans notre politique de protection de la confidentialité. Si vous n'êtes pas d'accord avec la politique de confidentialité, veuillez ne pas communiquer de renseignements personnels à DCM. Les modalités de la politique de confidentialité sont incorporées par renvoi aux présentes modalités et en font partie intégrante.

  10. Propriété intellectuelle

  « DCM » et toutes les autres marques de commerce, marques de service et logos, qu'ils soient enregistrés ou non, ou indiqués comme appartenant à DCM, appartiennent à DATA Communications Management Corp. et ne peuvent être utilisés sans l'autorisation écrite de leur détenteur. Toutes les autres marques de commerce appartiennent à leurs détenteurs respectifs.

  Tout le contenu est protégé par le droit d'auteur © 2020-2023 et DATA Communications Management Corp. sauf indication contraire, ou la partie citée comme source est titulaire et en assure le contrôle. TOUS DROITS RÉSERVÉS.

  11. Restrictions dutilisation

  Vous pouvez accéder au contenu du présent site, l'utiliser et en imprimer une copie à partir de votre ordinateur uniquement à des fins personnelles. Il est interdit de modifier, publier, transmettre, transférer ou vendre, reproduire, créer des produits dérivés, distribuer, exécuter, afficher ou exploiter de quelque manière que ce soit l’intégralité ou une partie du contenu, sauf dans les cas expressément autorisés aux présentes. La modification du contenu ou l'utilisation du contenu à des fins autres que celles expressément autorisées aux présentes (y compris l'utilisation de ce matériel sur un autre site Web ou dans un environnement informatique en réseau) constitue une violation des droits de propriété intellectuelle et de tout autre droit de propriété de DCM et de ses concédants de licence. Toute utilisation ou modification du contenu, en tout ou en partie, à d'autres fins, est strictement interdite. Toute tentative non autorisée de téléchargement ou de modification de l'information sur le présent site est strictement interdite. À moins d’avis contraire, personne n'est autorisé à copier, redistribuer, reproduire, republier, retransmettre, modifier ou utiliser publiquement ou commercialement le contenu, sous quelque format que ce soit.

  Vous convenez que toute copie du contenu que vous effectuez n’exonère en rien tout droit d’auteur et autres avis d’exclusivité sous le même format et de la même manière que le contenu original. Les marques de commerce ne peuvent être utilisées pour un produit ou un service qui n'a pas été préalablement approuvé par écrit par DCM, ni d'une autre manière susceptible d’entraîner une confusion chez les clients ou le public, ni d'une manière à dénigrer ou discréditer DCM.

  12. Droits réservés

  Tous les droits non expressément accordés dans les présentes modalités sont réservés à DCM. Sans restreindre la portée générale de ce qui précède, aucune disposition des présentes ne doit être interprétée comme conférant par voie de conséquence, ou autrement une licence ou un droit en vertu d'un droit d'auteur, d'un brevet, d'une marque de commerce ou d'un autre droit de propriété intellectuelle de DCM ou de toute autre personne ou entité.

  13. Survie des obligations

  Les présentes modalités s'appliquent lorsque vous accédez au site et demeurent en vigueur par la suite, sous réserve du droit de DCM de modifier ou de mettre à jour les modalités d'utilisation du présent site de temps à autre. Si vous n'avez plus accès au présent site, les modalités des clauses intitulées « Exclusion des garanties », « Clause de limitation de responsabilité », « Renseignements fournis par voie électronique », « Indemnisation », « Propriété intellectuelle » et « Restrictions d'utilisation » demeurent en vigueur.

  14. Enregistrement

  Pour pouvoir passer une commande sur le site, vous ou votre entreprise devez y ouvrir un compte auquel seront associés un nom d'utilisateur et un mot de passe. Veillez à en assurer la confidentialité, car vous serez tenu responsable de toute utilisation frauduleuse de ceux-ci. Ne permettez à personne d'utiliser votre compte et ne confiez votre mot de passe à personne. Il vous est interdit d’utiliser ou de tenter d'utiliser le compte, le mot de passe, le service, le système ou toute autre information d'une autre personne qui peut être fournie sur le présent site, ni de permettre à d'autres personnes d'utiliser votre compte, votre mot de passe, votre service ou votre système, sans avoir obtenu l'autorisation écrite préalable de DCM, ni créer ou utiliser une fausse identité sur le présent site. Vous ne pouvez permettre à quiconque d’autre de tenter d'obtenir un accès non autorisé au présent site ou à des parties du présent site dont l'accès est restreint.

  #### Produits et services

  Tous les produits et services offerts par DCM par l'entremise de sa solution, de même que leur utilisation, sont sous réserve des modalités de toutes les ententes ou de tous les contrats régissant leur utilisation.

  #### Commandes de produits et services

  Toutes les commandes de produits et services offerts par DCM par l'entremise de sa solution, y compris, mais sans s'y limiter, les modes de paiement, les prix, la disponibilité des produits, la livraison et le retour des produits, sont sous réserve des dispositions des ententes applicables en vigueur conclues entre vous et DCM.
  `;
};
