import { Components, CssVarsTheme, Theme, tooltipClasses } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledTooltip: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => {
        return {
          [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
            marginTop: theme.spacing(1)
          },
          [`.${tooltipClasses.popper}[data-popper-placement*="top"] &`]: {
            marginBottom: theme.spacing(0.5)
          },
          backgroundColor: theme.vars.palette.primary.shades[1],
          color: theme.vars.palette.text.primary,
          boxShadow: theme.vars.shadows[1],
          fontWeight: 400,
          zIndex: 1700,
          '&.high-contrast': {
            background:
              theme.palette.paletteMode === DEFAULT_COLOR_MODE
                ? theme.vars.palette.basic.darkest
                : theme.vars.palette.basic.brightest,
            color:
              theme.palette.paletteMode === DEFAULT_COLOR_MODE
                ? theme.vars.palette.basic.brightest
                : theme.vars.palette.basic.darkest
          }
        };
      },
      arrow: ({ theme }) => ({
        '&::before': {
          backgroundColor: theme.vars.palette.primary.shades[1]
        },
        '&.high-contrast': {
          '&::before': {
            backgroundColor:
              theme.palette.paletteMode === DEFAULT_COLOR_MODE
                ? theme.vars.palette.basic.darkest
                : theme.vars.palette.basic.brightest
          }
        }
      })
    }
  }
};
