import { lazy } from 'react';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';
import { IPaths } from 'src/app/routes/IPath';
import { EPageLocation } from 'src/app/routes/pageLocation';
import { EN_US, ES_ES, FR_CA } from 'src/app/utilities';
import { IRouteWithLoader } from 'src/app/routes/Routes';
import { shareLoader } from './loader';

const component = lazy(() => import('./Share'));

const paths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__share_page_slug}`,
  'es-ES': `/${ES_ES}/${esES.routes__share_page_slug}`,
  'fr-CA': `/${FR_CA}/${frCA.routes__share_page_slug}`
};

const location = EPageLocation.share;

const shareRoutes: IRouteWithLoader = {
  component,
  paths,
  location,
  loader: shareLoader
};

export default shareRoutes;

export const shareRedirectPath = [
  {
    oldUrl: `/en/${enUS.routes__share_page_slug}/`
  },
  {
    oldUrl: `/es/${esES.routes__share_page_slug}/`
  },
  {
    oldUrl: `/fr/${frCA.routes__share_page_slug}/`
  }
];
