import { lazy } from 'react';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';
import { IPaths } from 'src/app/routes/IPath';
import { EPageLocation } from 'src/app/routes/pageLocation';
import { Claim } from 'src/app/utilities/claims';
import { loadCatalogsAssets } from './loader';
import { IRoute, IRouteWithLoader } from '../../routes/Routes';
import { Language } from 'src/app/utilities/i18n';

//todo: add futur page components here and add them to correct route
const dashboardComponent = lazy(() => import('./Dashboard'));
const usersAndGroupsComponent = lazy(
  () => import('./UsersAndGroups/UsersAndGroups')
);
const catalogsComponent = lazy(() => import('./Catalogs/Catalogs'));
const attributesComponent = lazy(() => import('./Attributes/Attributes'));
const advancedFilterComponent = lazy(
  () => import('./AdvancedFilters/AdvancedFilters')
);
const companySettingsComponent = lazy(
  () => import('./CompanySettings/CompanySettings')
);

export const adminBaseRoute: IPaths = {
  'en-US': `/${enUS.routes__admin_slug}`,
  'es-ES': `/${esES.routes__admin_slug}`,
  'fr-CA': `/${frCA.routes__admin_slug}`
};

export const adminWithLocaleBaseRoute: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}`
};

const dashboardPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_dashboard_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_dashboard_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_dashboard_slug}`
};

const usersAndGroupsPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_users_groups_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_users_groups_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_users_groups_slug}`
};

const catalogsPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_catalogs_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_catalogs_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_catalogs_slug}`
};

const attributesPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_attributes_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_attributes_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_attributes_slug}`
};

const advancedFiltersPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_advanced_filters_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_advanced_filters_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_advanced_filters_slug}`
};

const companySettingsPaths: IPaths = {
  'en-US': `/${Language.enUS}/${enUS.routes__admin_slug}/${enUS.routes__admin_company_settings_slug}`,
  'es-ES': `/${Language.esES}/${esES.routes__admin_slug}/${esES.routes__admin_company_settings_slug}`,
  'fr-CA': `/${Language.frCA}/${frCA.routes__admin_slug}/${frCA.routes__admin_company_settings_slug}`
};

const location = EPageLocation.admin;

export const adminRoutes = {
  component: dashboardComponent,
  paths: dashboardPaths,
  location: location,
  requiredClaims: [Claim.Admin.Dashboard]
};

export const usersAndGroupsRoutes: IRoute = {
  component: usersAndGroupsComponent,
  paths: usersAndGroupsPaths,
  location: location,
  requiredClaims: [Claim.Admin.Dashboard]
};

export const catalogsRoutes: IRouteWithLoader = {
  component: catalogsComponent,
  paths: catalogsPaths,
  location: location,
  loader: loadCatalogsAssets,
  requiredClaims: [Claim.Admin.Dashboard]
};

export const attributesRoutes = {
  component: attributesComponent,
  paths: attributesPaths,
  location: location,
  requiredClaims: [Claim.Admin.Dashboard]
};

export const advancedFiltersRoutes = {
  component: advancedFilterComponent,
  paths: advancedFiltersPaths,
  location: location,
  requiredClaims: [Claim.Admin.Dashboard]
};

export const companySettingsRoutes = {
  component: companySettingsComponent,
  paths: companySettingsPaths,
  location: location,
  requiredClaims: [Claim.Admin.Dashboard]
};
