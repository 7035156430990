import { Box, BoxProps } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ETenantBrandImages } from 'src/app/api/models/tenants';
import { useTenant } from 'src/app/contexts/tenant';
import { useCustomTheme } from 'src/styles/_theme';

export interface ILogoProps extends Partial<BoxProps> {
  forceWhiteLogo?: boolean;
  isAlwaysASMBLLogo?: boolean;
}

export const Logo = ({
  forceWhiteLogo,
  isAlwaysASMBLLogo,
  ...props
}: ILogoProps) => {
  const [t] = useTranslation();
  const { tenant } = useTenant();
  const { currentMode } = useCustomTheme();

  const [logoInfo, setLogoInfo] = useState<{
    src: string;
    alt: string;
  }>({ src: '', alt: '' });

  const getLogoKey = useCallback(() => {
    if (forceWhiteLogo) return ETenantBrandImages.WHITE_LOGO;

    return currentMode === 'dark'
      ? ETenantBrandImages.WHITE_LOGO
      : ETenantBrandImages.COLOURED_LOGO;
  }, [forceWhiteLogo, currentMode]);

  const asmblLogoSrc = useMemo(
    () =>
      getLogoKey() === ETenantBrandImages.WHITE_LOGO
        ? '/asmbl-light.svg'
        : '/asmbl-logo.svg',
    [getLogoKey]
  );

  useEffect(() => {
    //wait for tenant brand images info to be loaded before setting the logo image
    //to avoid logo blink between ASMBL and tenant's logos.
    //if brandImages is ready, it will not be undefined.
    if (isAlwaysASMBLLogo) {
      setLogoInfo({ src: asmblLogoSrc, alt: t('general__logo_asmbl_alt') });
    } else if (tenant?.brandImages) {
      //select the the logo to show (coloured or white)
      let logoKey = getLogoKey();

      //if the selected logo is non existent (ex: color logo),
      //select the other version (ex: white logo).
      //if the other version is still non existent, default ASMBL logo will show
      if (!tenant.brandImages[logoKey]?.imageUri) {
        logoKey =
          logoKey === ETenantBrandImages.COLOURED_LOGO
            ? ETenantBrandImages.WHITE_LOGO
            : ETenantBrandImages.COLOURED_LOGO;
      }
      const src = tenant.brandImages[logoKey]?.imageUri ?? asmblLogoSrc;
      const alt =
        tenant.brandImages[logoKey]?.altText ?? t('general__logo_asmbl_alt');

      setLogoInfo({ src: src, alt: alt ?? 'logo' });
    }
  }, [
    t,
    tenant?.brandImages,
    forceWhiteLogo,
    isAlwaysASMBLLogo,
    currentMode,
    asmblLogoSrc,
    getLogoKey
  ]);

  return (
    <Box
      component={'img'}
      src={logoInfo.src}
      alt={logoInfo.alt}
      sx={{ maxWidth: '100%', objectFit: 'scale-down' }}
      {...props}
    />
  );
};
