import { MetadataValues } from '../controllers/metadata';

export interface IUploadAsset {
  id: string;
  name: string;
  extension: string;
  assetTypeCode: AssetGroupsKeys;
  originalName: string;
  isDuplicate: boolean;
  metadata: MetadataValues;
  manualKeywords: string[];
  catalogId: string;
  properties: Record<string, string>;
  createdDateTime?: string;
  modifiedDateTime?: string;
}

//*this Object keys are what we receive and send to the BackEnd. Value in lowercase is for translations.

export const ASSET_GROUPS = {
  Any: { groupName: 'any', supportsAI: false },
  Audio: { groupName: 'audio', supportsAI: true },
  Document: { groupName: 'document', supportsAI: true },
  Presentation: { groupName: 'presentation', supportsAI: true },
  CreativeFile: { groupName: 'creativefile', supportsAI: false },
  Image: { groupName: 'image', supportsAI: true },
  Video: { groupName: 'video', supportsAI: true },
  Other: { groupName: 'other', supportsAI: false }
} as const;

export type AssetGroups =
  typeof ASSET_GROUPS[keyof typeof ASSET_GROUPS]['groupName'];
export type AssetGroupsKeys = keyof typeof ASSET_GROUPS;
export type AssetPossibleTypesKeys = Exclude<AssetGroupsKeys, 'Any'>;

export type AssetProperty = {
  id: string;
  uncommonProperties: boolean;
  propKey: AssetPropertiesEnum;
};

export interface AssetExtensionItem {
  group: AssetGroupsKeys;
  uncommonProperties?: string[];
  [key: string]: unknown;
}

export type BEAssetProperty = {
  id: string;
  uncommonProperties: boolean;
  type: AssetPropertiesEnum;
};
export interface IBEAssetTypes {
  groups: AssetGroupsKeys[];
  properties: BEAssetProperty[];
  extensions: {
    [key: string]: AssetExtensionItem;
  };
}

export interface IAssetTypes {
  groups: AssetGroupsKeys[];
  properties: AssetProperty[];
  extensions: {
    [key: string]: AssetExtensionItem;
  };
}

export interface IAssetIdAndThumbnailInfo {
  id: string;
  thumbnailSrc: string;
  hasCustomThumbnail?: boolean;
}

export interface IAssetDuplicateReturn {
  filename: string;
  isDuplicated: boolean;
}

export enum AssetPropertiesEnum {
  KIND = 'Kind',
  SIZE = 'Size',
  DIMENSIONS = 'Dimensions',
  COLOR_SPACE = 'ColorSpace',
  DURATION = 'Duration',
  SAMPLE_RATE = 'SampleRate',
  PAGES = 'Pages',
  RESOLUTION = 'Resolution',
  CONTENT_CREATOR = 'ContentCreator',
  HAS_TRANSPARENCY = 'HasTransparency'
}
