import { IAssetTypes, IUploadAsset } from 'src/app/api/models/assets';
import { StateCreator } from 'zustand';
import { uploadResetters } from '../uploadStore';
import { produce } from 'immer';
import {
  AssetGroupsMetadataList,
  MetadataValue,
  MetadataValues
} from 'src/app/api/controllers/metadata';
import { checkPropertyErrorsInAssets } from 'src/app/utilities/assets';
import { TFunction } from 'react-i18next';
import { NestedKeyOf } from 'src/app/utilities/utilityTypes';

//need to declare initial state like this to be able to reset it
const uploadAssetInitialState = {
  assets: [],
  assetTypes: {
    groups: [],
    properties: [],
    extensions: {}
  },
  errors: {}
};

export interface IAssetsSlice {
  assets: IUploadAsset[]; //setAssets is done in UploadStep2EnterInfo using previousState
  assetTypes: IAssetTypes;
  errors: Record<string, string[]>;
  setSingleAssetMetadata: (assetIndex: number, value: MetadataValues) => void;
  changeAssetCatalog: (assetIndex: number, catalogId: string) => void;
  changeAssetIsDuplicate: (assetIndex: number, isDuplicate: boolean) => void;
  changeAssetName: (assetIndex: number, name: string) => void;
  changeAssetMetadata: (
    assetIndex: number,
    fieldId: string,
    value: MetadataValue
  ) => void;
  changeErrorFromProperty: (
    assetIndex: number,
    property: NestedKeyOf<IUploadAsset>,
    assetTypes: IAssetTypes,
    metadata: AssetGroupsMetadataList,
    t: TFunction<'translation', undefined>
  ) => void;
  setAssetTypes: (types: IAssetTypes) => void;
  setErrors: (errors: Record<string, string[]>) => void;
}

export const createAssetsSlice: StateCreator<IAssetsSlice> = (set, get) => {
  uploadResetters.push(() => set(uploadAssetInitialState));

  return {
    ...uploadAssetInitialState,
    setSingleAssetMetadata: (assetIndex: number, value: MetadataValues) =>
      set(
        produce((state: IAssetsSlice) => {
          state.assets[assetIndex].metadata = value;
        })
      ),
    changeAssetCatalog: (assetIndex: number, catalogId: string) => {
      set(
        produce((state: IAssetsSlice) => {
          state.assets[assetIndex].catalogId = catalogId;
        })
      );
    },
    changeAssetIsDuplicate: (assetIndex: number, isDuplicate: boolean) => {
      set(
        produce((state: IAssetsSlice) => {
          state.assets[assetIndex].isDuplicate = isDuplicate;
        })
      );
    },
    changeAssetName: (assetIndex: number, name: string) =>
      set(
        produce((state: IAssetsSlice) => {
          state.assets[assetIndex].name = name;
        })
      ),
    changeAssetMetadata: (
      assetIndex: number,
      fieldId: string,
      value: MetadataValue
    ) =>
      set(
        produce((state: IAssetsSlice) => {
          state.assets[assetIndex].metadata[fieldId] = value;
        })
      ),
    changeErrorFromProperty: (
      assetIndex: number,
      property: NestedKeyOf<IUploadAsset>,
      assetTypes: IAssetTypes,
      metadata: AssetGroupsMetadataList,
      t: TFunction<'translation', undefined>
    ) =>
      set(() => ({
        errors: checkPropertyErrorsInAssets(
          property,
          assetTypes,
          metadata,
          t,
          get().errors,
          get().assets[assetIndex]
        )
      })),
    setAssetTypes: (types: IAssetTypes) => set(() => ({ assetTypes: types })),
    setErrors: (value: Record<string, string[]>) =>
      set(() => ({ errors: value }))
  };
};
