import ReactDOM from 'react-dom/client';
import App from './app/App';
import { unregister } from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);

unregister();
