import { Box, useTheme } from '@mui/material';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';
import { EDrawerDepthLevel, useDrawer } from '../../../../stores/drawerStore';
import { CSSProperties } from 'react';

interface IAdminIcon {
  iconName: string;
  header?: boolean;
  iconStyles?: CSSProperties;
  containerStyles?: CSSProperties;
}

export const AdminIcon = ({
  iconName,
  header,
  iconStyles,
  containerStyles
}: IAdminIcon) => {
  const theme = useTheme();
  const { drawerDepthLevel } = useDrawer();

  return (
    <Box
      display="flex"
      padding={theme.spacing(1)}
      borderRadius="0.25rem"
      sx={{
        backgroundColor:
          drawerDepthLevel === EDrawerDepthLevel.SECONDARY && !header
            ? theme.vars.palette.primary.shades[1]
            : drawerDepthLevel === EDrawerDepthLevel.TERTIARY && !header
            ? theme.vars.palette.error.shades[0]
            : theme.vars.palette.primary.shades[1],
        color:
          drawerDepthLevel === EDrawerDepthLevel.SECONDARY && !header
            ? theme.vars.palette.primary.main
            : drawerDepthLevel === EDrawerDepthLevel.TERTIARY && !header
            ? theme.vars.palette.error.light
            : theme.vars.palette.text.primary,
        ...containerStyles
      }}
    >
      <CustomIcons
        iconName={iconName}
        customStyles={{
          fontSize: '1.5rem',
          margin: 'auto',
          ...iconStyles
        }}
      />
    </Box>
  );
};
