import { isEqual } from 'date-fns';
import { useCallback, useState } from 'react';

interface IStaticConfig {
  isMaintenanceMode: boolean;
}

const lastModifiedHeader = 'Last-Modified';
const configLastModifiedKey = 'cdn-config-last-modified';
const configKey = 'cdn-config';
const configFilePath = 'resources/config.json';

export const useCdnConfig = () => {
  const [cdnConfig, setCdnConfig] = useState<IStaticConfig>({
    isMaintenanceMode: false
  });

  const loadConfiguration = useCallback(async () => {
    try {
      const lookupFileInformation = await fetch(
        `${process.env.REACT_APP_STATIC_CDN_URL}/${configFilePath}`,
        {
          method: 'HEAD'
        }
      );
      const lastModified = window.localStorage.getItem(configLastModifiedKey);
      const lastModifiedValue =
        lookupFileInformation.headers.get(lastModifiedHeader);

      const isModified =
        lookupFileInformation.ok &&
        !isEqual(
          new Date(lastModifiedValue ?? ''),
          new Date(lastModified ?? '')
        );

      if (isModified) {
        const staticConfigResponse = await fetch(
          `${process.env.REACT_APP_STATIC_CDN_URL}/${configFilePath}`
        );
        const staticConfig = await staticConfigResponse.json();

        setCdnConfig(staticConfig);

        window.localStorage.setItem(configKey, JSON.stringify(staticConfig));
        window.localStorage.setItem(
          configLastModifiedKey,
          lastModifiedValue ?? ''
        );
      } else {
        const staticConfigSetting = JSON.parse(
          window.localStorage.getItem(configKey) ?? '{}'
        );
        setCdnConfig(staticConfigSetting);
      }
    } catch {
      // do nothing
    }
  }, []);

  return {
    loadConfiguration,
    cdnConfig
  };
};

export default useCdnConfig;
