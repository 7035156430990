import { PaletteMode } from '@mui/material';
import { useEffect, useCallback } from 'react';
import { updateMyUser } from 'src/app/api/controllers/users';
import {
  IJsonPatchOperation,
  JsonPatchOperationEnum
} from 'src/app/api/jsonPatch';
import { useAuth } from 'src/app/stores';
import { useCustomTheme } from 'src/styles/_theme';

export const useSettingAppearanceForm = () => {
  const { userInfo, setUserInfo } = useAuth();
  const { currentMode, setMode } = useCustomTheme();

  const handleThemeModeChange = async (value: string) => {
    setMode(value as PaletteMode);
  };

  const updateMyUserDisplayMode = useCallback(
    async (
      displayMode: string,
      lastModifiedDate: string,
      controller: AbortController
    ) => {
      const jsonPatchOperations: IJsonPatchOperation[] = [
        {
          op: JsonPatchOperationEnum.REPLACE,
          path: `/displaymode`,
          value: displayMode
        }
      ];

      try {
        const updatedUser = await updateMyUser(
          jsonPatchOperations,
          encodeURIComponent(lastModifiedDate),
          controller
        );
        setUserInfo(updatedUser.data);
      } catch (error) {
        if (process.env.REACT_APP_ENV !== 'production') {
          console.error(error);
        }
      }
    },
    [setUserInfo]
  );

  useEffect(() => {
    const canUpdate =
      currentMode && userInfo && currentMode !== userInfo.displayMode;

    let controller: AbortController | null = null;

    if (canUpdate) {
      const lastModifiedDate =
        userInfo.modifiedDateTime ?? userInfo.createdDateTime;

      if (!lastModifiedDate) {
        return;
      }

      controller = new AbortController();

      updateMyUserDisplayMode(currentMode, lastModifiedDate, controller);
    }

    return () => {
      controller?.abort();
    };
  }, [currentMode, setMode, updateMyUserDisplayMode, userInfo]);

  return {
    currentMode,
    handleThemeModeChange
  };
};
