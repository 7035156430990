import { useColorScheme } from '@mui/material';
import { useContext } from 'react';
import {
  CustomThemeContext,
  availableThemes,
  allAvailableThemes
} from './customThemeProvider';

export const useCustomTheme = () => {
  const { theme, setTheme } = useContext(CustomThemeContext);
  const { mode, setMode } = useColorScheme();
  const { name } = theme;

  const setCustomTheme = (theme: string) => {
    if (Object.values(availableThemes).includes(theme)) {
      setTheme(
        allAvailableThemes.find((t) => t.name === theme) ??
          allAvailableThemes[0]
      );
    }
  };

  return {
    currentThemeName: name,
    setCustomTheme,
    currentMode: mode,
    setMode
  };
};
