import Lottie from 'lottie-react';
import loadingAnimation from './loading.json';

import './loading.scss';

export function Loading() {
  return (
    <div className="loading-anim-wrapper">
      <Lottie animationData={loadingAnimation} className="lottie-loading" />
    </div>
  );
}
