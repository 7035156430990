import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledStepper } from './stepper';
import { styledTabs } from './tabs';
import { styledDrawer } from './drawer';

export const styledNavigationAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledStepper,
  ...styledTabs,
  ...styledDrawer
};
