import { forwardRef, ReactNode, useCallback } from 'react';
//we use notistack Provider in App root https://notistack.com/ to manage alerts and snackbars, and control their persistance and stacking.
import {
  useSnackbar as useNotistack, //renamed to avoid confusion with our custom Snackbar vs notistack package
  SnackbarContent as NotistackContent,
  OptionsObject as NotistackOptions
} from 'notistack';
import { CustomIcons } from '../../dataDisplay/icons/CustomIcons';
import {
  IconButton,
  Alert as MuiAlert,
  AlertTitle as MuiAlertTitle,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import './stackable-alert.scss';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities/constants';

type Variant = 'standard' | 'filled' | 'outlined';
type Severity = 'error' | 'success' | 'warning' | 'info';

interface IAlertProps {
  id: string | number;
  customVariant?: Variant;
  customSeverity?: Severity;
  title?: ReactNode;
  message: ReactNode;
  action?: ReactNode;
  hasCloseBtn?: boolean;
}
export interface IStackableAlertProps extends Omit<IAlertProps, 'id'> {
  options?: NotistackOptions;
}

export const useStackableAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();
  const enqueueStackableAlert = useCallback(
    (params: IStackableAlertProps) => {
      return enqueueSnackbar(params.message, {
        content: params.options?.content
          ? params.options.content
          : (key, message) => (
              <StackableAlert
                id={key}
                message={message}
                title={params.title}
                customVariant={
                  params.customVariant ? params.customVariant : 'standard'
                }
                customSeverity={
                  params.customSeverity ? params.customSeverity : 'success'
                }
                action={params.action}
                hasCloseBtn={params.hasCloseBtn}
              />
            ),
        ...params.options
      });
    },
    [enqueueSnackbar]
  );
  const closeStackableAlert = closeSnackbar;
  return { enqueueStackableAlert, closeStackableAlert };
};

const StackableAlert = forwardRef<HTMLDivElement, IAlertProps>(
  (
    {
      customVariant = 'standard',
      customSeverity = 'success',
      hasCloseBtn = true,
      ...props
    },
    ref
  ) => {
    const { closeStackableAlert } = useStackableAlert();
    const [t] = useTranslation();
    const theme = useTheme();

    const getIcon = (customSeverity: Severity) => {
      let iconName = 'circle-check';
      let iconColor = theme.vars.palette[customSeverity].main;
      switch (customSeverity) {
        case 'error':
          iconName = 'circle-warning';
          iconColor =
            theme.palette.mode === DEFAULT_COLOR_MODE
              ? theme.vars.palette[customSeverity].main
              : theme.vars.palette[customSeverity].contrastText;
          break;
        case 'warning':
          iconName = 'circle-warning';
          iconColor = theme.vars.palette[customSeverity].dark;
          break;
        case 'success':
          iconName = 'circle-check';
          iconColor =
            theme.palette.mode === DEFAULT_COLOR_MODE
              ? theme.vars.palette[customSeverity].main
              : theme.vars.palette.success.contrastText;
          break;
        case 'info':
          iconName = 'info';
          break;
      }
      return (
        <CustomIcons
          iconName={iconName}
          extraClassName="icon-md"
          label={t(`icon__${iconName}`)}
          customStyles={{
            color: iconColor
          }}
        />
      );
    };

    return (
      <NotistackContent ref={ref}>
        <MuiAlert
          className="stack-alert"
          variant={customVariant}
          severity={customSeverity}
          icon={getIcon(customSeverity)}
          action={
            <>
              {props.action}
              {hasCloseBtn && (
                <IconButton
                  onMouseDown={() => closeStackableAlert(props.id)}
                  onKeyDown={() => closeStackableAlert(props.id)}
                  aria-label={`${t('icon__close')}`}
                  size="small"
                  color={customSeverity}
                  className={'close-btn'}
                >
                  <CustomIcons iconName="close" />
                </IconButton>
              )}
            </>
          }
        >
          {props.title && <MuiAlertTitle>{props.title}</MuiAlertTitle>}
          {props.message}
        </MuiAlert>
      </NotistackContent>
    );
  }
);

StackableAlert.displayName = 'StackableAlert';

export default StackableAlert;
