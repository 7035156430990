import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-CA';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LoadingWrapper from './components/feedback/loading/LoadingWrapper';
import { Router } from './routes/Router';
import { TenantFavicon } from './components/dataDisplay/brandImages/TenantFavicon';
import './utilities/i18n';
import 'src/styles/app.scss';

interface ILocaleMap {
  [key: string]: Locale;
}

const localeMap: ILocaleMap = {
  en: enLocale,
  fr: frLocale,
  es: esLocale
};

function App() {
  const [t] = useTranslation();

  return (
    <>
      <Helmet htmlAttributes={{ lang: t('locale_key') }} />
      <TenantFavicon />
      <StyledEngineProvider injectFirst>
        <LoadingWrapper>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[t('locale_key')]}
          >
            <Router />
          </LocalizationProvider>
        </LoadingWrapper>
      </StyledEngineProvider>
    </>
  );
}

export default App;
