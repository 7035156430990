import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Claim } from 'src/app/utilities/claims';
import homeRoute from 'src/app/pages/home/home.routes';
import { useAuth } from 'src/app/stores/authStore';
import IdleTimer from 'src/app/components/utils/idleTimer/IdleTimer';
import { useAuthentication } from '../contexts/authentication';
import { useCallback, useEffect } from 'react';

export interface IGuardedRouteProps {
  children: React.ReactNode;
  claims?: Claim[];
}

export default function GuardedRoute(props: IGuardedRouteProps) {
  const [t] = useTranslation();
  const { claims } = useAuth();
  const { isAuthenticated, isLoading, login } = useAuthentication();

  const canAccessPage =
    props.claims === undefined ||
    claims?.hasClaims(props.claims) ||
    props.claims.reduce(
      (acc, c) => acc || claims?.hasClaimInAtLeastOneCatalog(c),
      false
    );

  const validate = useCallback(() => {
    if (!isAuthenticated && !isLoading) {
      login();
    }
  }, [isAuthenticated, isLoading, login]);

  useEffect(() => {
    validate();
  }, [validate]);

  // is the page is not validated or
  if (!isAuthenticated || isLoading || claims === undefined) {
    return null;
  }

  if (!canAccessPage) {
    return <Navigate to={homeRoute.paths[t('locale_key')]} replace />;
  }

  return (
    <>
      <IdleTimer />
      {props.children}
    </>
  );
}
