import { removeTrailingSlash } from 'src/app/utilities/string';
import axiosInstance, { noAuthAxiosInstance } from '../axiosInstance';
import { IDisplayAssetData } from '../models/search';
import {
  IShareByEmailQuery,
  IShareByEmailResponse,
  IShareQuery,
  IShareResponse
} from '../models/share';
import { ICustomizationInfo } from '../models/tenants';

const SHARE_PREFIX = 'api/v1/share';
const PUBLIC_SHARE_PREFIX = 'share/v1';
const SHARE_EMAIL = `${SHARE_PREFIX}/send-email`;

export async function createShare(
  query: IShareQuery,
  signal?: AbortSignal
): Promise<{ data: IShareResponse }> {
  return await axiosInstance.post(
    `${SHARE_PREFIX}`,
    {
      ...query
    },
    { signal: signal }
  );
}

export async function sendEmail(
  query: IShareByEmailQuery,
  signal?: AbortSignal
): Promise<{ data: IShareByEmailResponse }> {
  return await axiosInstance.post(
    `${SHARE_EMAIL}`,
    {
      ...query
    },
    { signal: signal }
  );
}

export async function getAssetsFromShareId(
  shareId: string,
  signal?: AbortSignal
): Promise<{ data: IDisplayAssetData[] }> {
  return await noAuthAxiosInstance.get(`${PUBLIC_SHARE_PREFIX}/${shareId}`, {
    signal: signal
  });
}

export async function getThemeFromShareId(
  shareId: string,
  signal?: AbortSignal
): Promise<{ data: ICustomizationInfo }> {
  return await noAuthAxiosInstance.get(
    `${PUBLIC_SHARE_PREFIX}/${shareId}/theme`,
    {
      signal: signal
    }
  );
}

export function getShareAssetDownloadUrl(shareId: string, assetId: string) {
  return `${removeTrailingSlash(
    process.env.REACT_APP_API_URL ?? ''
  )}/${PUBLIC_SHARE_PREFIX}/${shareId}/${assetId}/download`;
}
