import { lazy } from 'react';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';
import { IPaths } from 'src/app/routes/IPath';
import { EPageLocation } from 'src/app/routes/pageLocation';
import { EN_US, ES_ES, FR_CA } from 'src/app/utilities';
import { IRoute } from 'src/app/routes/Routes';

const component = lazy(() => import('./HomePageWrapper'));

const paths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__home_page_slug}`,
  'es-ES': `/${ES_ES}/${esES.routes__home_page_slug}`,
  'fr-CA': `/${FR_CA}/${frCA.routes__home_page_slug}`
};

const location = EPageLocation.home;

const routes: IRoute = {
  component,
  paths,
  location
};

export default routes;
