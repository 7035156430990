import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledChips: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        alignItems: 'center',
        backgroundColor: theme.vars.palette.primary.shades[1],
        border: `2px solid ${theme.vars.palette.primary.shades[1]}`,
        fontSize: '0.875rem',
        height: '2.5rem',
        overflow: 'hidden',
        padding: theme.spacing(0.5),
        borderRadius: theme.vars.shape.borderRadius,
        color: theme.vars.palette.text.primary,
        '& .MuiIconButton-root': {
          backgroundColor: 'inherit',
          border: 0,
          borderRadius: '50%',
          color: theme.vars.palette.text.primary,
          padding: theme.spacing(0.5),

          '&.MuiChip-deleteIcon': {
            fontSize: '1.5rem',
            margin: theme.spacing(0, -0.25, 0, 0),
            '&:hover': {
              background: `transparent`,
              color: theme.vars.palette.primary.light
            }
          },
          '&:hover': {
            background: `radial-gradient(circle, ${theme.vars.palette.primary.contrastText} 50%, ${theme.vars.palette.primary.contrastText} 51%)`,
            color: theme.vars.palette.primary.dark,
            backgroundClip: 'content-box',
            borderRadius: '28%',

            ...(ownerState.variant === 'outlined' && {
              background: `radial-gradient(circle, ${theme.vars.palette.primary.shades[2]} 50%, ${theme.vars.palette.primary.shades[2]} 51%)`
            })
          }
        },
        '&.MuiChip-clickable': {
          '&:hover': {
            backgroundColor: theme.vars.palette.primary.shades[2]
          }
        },
        '&.Mui-focusVisible': {
          backgroundColor: theme.vars.palette.primary.shades[1],
          borderColor: theme.vars.palette.primary.main
        },
        '& > svg': {
          margin: theme.spacing(0.5, 0.5, 0.5, 1),
          minWidth: '1em'
        },
        ...(ownerState.variant === 'clickable' && {
          padding: theme.spacing(0, 0.5),
          cursor: 'pointer',
          height: 'auto'
        })
      }),
      label: ({ theme }) => ({
        padding: theme.spacing(0.5)
      }),
      outlined: ({ theme }) => ({
        backgroundColor: theme.vars.palette.background.paper,
        border: `1px solid ${theme.vars.palette.primary.shades[2]}`,
        '&:hover': {
          backgroundColor: theme.vars.palette.primary.shades[0]
        },

        '& .MuiIconButton-root': {
          transition: 'none'
        },

        '&.Mui-focusVisible': {
          backgroundColor: theme.vars.palette.background.paper
        }
      }),
      borderless: ({ theme }) => ({
        backgroundColor: theme.vars.palette.background.paper,
        border: 'unset',
        transition: ['all', '150ms', 'ease-in-out'],
        '> svg': {
          marginLeft: theme.spacing(1.5)
        },
        '&:hover': {
          backgroundColor: theme.vars.palette.primary.shades[2]
        }
      }),
      error: ({ theme }) => ({
        backgroundColor: theme.vars.palette.background.paper,
        border: `1px solid ${theme.vars.palette.error.main}`
      }),
      biggerDelete: () => ({
        '.MuiIconButton-root': {
          '&.MuiChip-deleteIcon': {
            padding: '0.2rem'
          }
        }
      })
    }
  }
};
