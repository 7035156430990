import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIdle } from '../../../stores/idleTimerStore';
import { useUpload } from '../../../stores/upload/uploadStore';
import { DialogLogout } from '../../feedback/dialog/DialogLogout';
import { DEFAULT_IDLE_TIMER, PROMPT_BEFORE_IDLE } from '../../../utilities';
import { useAuthentication } from 'src/app/contexts/authentication';

const IdleTimer = () => {
  const { timer, pausing, setPausing, setTimer } = useIdle();
  const promptBeforeIdle = PROMPT_BEFORE_IDLE;
  const timeout = timer + promptBeforeIdle;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const { uploadProgress } = useUpload();
  const { logout } = useAuthentication();

  const logOut = () => {
    // logout reset the UserAuth Store
    setTimer(DEFAULT_IDLE_TIMER);
    setShowDialog(false);
    logout();
  };

  const onIdle = () => {
    logOut();
  };

  const onActive = () => {
    setShowDialog(false);
  };

  const onPrompt = () => {
    setShowDialog(true);
  };

  useEffect(() => {
    const uploadingInProgress =
      uploadProgress.findIndex((file) => !file.progress?.uploadComplete) > -1;

    if (uploadingInProgress) {
      if (!pausing) {
        setUploading(true);
        setPausing(true);
      }
    } else {
      if (uploading) {
        setUploading(false);
        setPausing(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress]);

  useEffect(() => {
    if (pausing) {
      pause();
    } else {
      activate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pausing]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (showDialog) {
        logOut();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  const { pause, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  });

  return (
    <DialogLogout
      open={showDialog}
      //this prevents user from closing the dialog by clicking outside
      onClose={() => setShowDialog(true)}
    />
  );
};

export default IdleTimer;
