import { BoxProps } from '@mui/material';
import {
  SVGArrayKey,
  SVGS_KEY_ARRAY_MAPPING
} from './svgWithDynamicColorsRefs';
import { Flexbox } from '../../surfaces/box/Flexbox';

import './svg-colors.scss';
import clsx from 'clsx';

export interface ISVGWithDynamicColors extends BoxProps {
  svgArrayMappingInfo: ISvgArrayMappingInfo;
}

export interface ISvgArrayMappingInfo {
  arrayKey: SVGArrayKey;
  index: number;
}

export const SVGWithDynamicColors = ({
  svgArrayMappingInfo,
  ...otherProperties
}: ISVGWithDynamicColors) => {
  return (
    <Flexbox
      aria-hidden
      {...otherProperties}
      className={clsx(
        'svg-colors',
        otherProperties.className && otherProperties.className
      )}
    >
      {svgArrayMappingInfo &&
        SVGS_KEY_ARRAY_MAPPING[svgArrayMappingInfo.arrayKey][
          svgArrayMappingInfo.index
        ]}
    </Flexbox>
  );
};
