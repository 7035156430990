import create from 'zustand';
import {
  IThumbnailSlice,
  createThumbnailSlice
} from './slices/uploadThumbnailStore';
import { IStep2Slice, createStep2Slice } from './slices/uploadStep2Store';
import { IUploadSlice, createUploadSlice } from './slices/uploadPageStore';
import { IStep1Slice, createStep1Slice } from './slices/uploadStep1Store';
import { IAssetsSlice, createAssetsSlice } from './slices/uploadAssetsStore';

export const uploadResetters: (() => void)[] = [];

export const useUpload = create<
  IThumbnailSlice & IStep1Slice & IStep2Slice & IUploadSlice & IAssetsSlice
>()((...a) => ({
  ...createThumbnailSlice(...a),
  ...createStep1Slice(...a),
  ...createStep2Slice(...a),
  ...createUploadSlice(...a),
  ...createAssetsSlice(...a)
}));

export const resetAllUploadSlices = () =>
  uploadResetters.forEach((resetter) => resetter());
