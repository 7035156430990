import { baseLightColorScheme, baseDarkColorScheme } from './baseColorScheme';
import { IThemeColorScheme, IThemeNaming } from '../customTheme';
import i18next from 'i18next';
import {
  getPrimaryDarkColorShade,
  getPrimaryLightColorShade
} from '../_components/utils/colors';

const themeNaming: IThemeNaming = {
  colorName: 'orange',
  label: i18next.t('settings__color_scheme_label_orange')
};

export const orangeLightColorScheme: IThemeColorScheme = {
  ...baseLightColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(28, 100%, 46%)',
    light: 'hsl(28, 100%, 50%)',
    dark: 'hsl(28, 100%, 44%)',
    variant: 'hsl(28, 100%, 44%)',
    shades: getPrimaryLightColorShade('28'),
    contrast: baseLightColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(28, 48%, 94%)',
    light: 'hsl(24, 50%, 96%)',
    dark: 'hsl(28, 48%, 90%)',
    contrast: 'hsl(28, 100%, 44%)'
  },
  accent: {
    main: 'hsl(209, 94%, 55%)'
  },
  background: {
    ...baseLightColorScheme.background,
    default: 'hsl(28, 20%, 98%)'
  },
  warning: {
    ...baseLightColorScheme.warning,
    main: 'hsl(40, 100%, 56%)',
    shades: ['hsl(40, 82%, 89%)']
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(28, 100%, 50%)',
    disabled: 'hsl(28, 15%, 86%)',
    disableBg: 'hsla(28, 98%, 5%, 0.5)',
    divider: 'hsl(28, 48%, 90%)',
    outlineBorder: 'hsl(28, 48%, 90%)',
    backdropOverlay: 'hsla(28, 100%, 5%, 0.5)'
  }
};
export const orangeDarkColorScheme: IThemeColorScheme = {
  ...baseDarkColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(28, 98%, 62%)',
    light: 'hsl(28, 100%, 60%)',
    dark: 'hsl(28, 87%, 55%)',
    variant: 'hsl(28, 87%, 55%)',
    contrast: 'hsl(28, 10%, 14%)',
    shades: getPrimaryDarkColorShade('230', '5%', [
      '21%',
      '24%',
      '29%',
      '33%',
      '43%'
    ])
  },
  secondary: {
    main: 'hsl(231, 6%, 24%)',
    light: 'hsl(233, 5%, 29%)',
    dark: 'hsl(230, 6%, 21%)',
    contrast: 'hsl(28, 10%, 14%)'
  },
  accent: {
    main: 'hsl(225, 77%, 60%)'
  },
  text: {
    ...baseDarkColorScheme.text,
    contrast: baseDarkColorScheme.text.contrast
  },
  action: {
    ...baseDarkColorScheme.action,
    active: 'hsl(28, 97%, 57%)',
    divider: 'hsl(220, 5%, 12%)',
    outlineBorder: 'hsl(233, 5%, 29%)',
    backdropOverlay: 'hsla(235, 11%, 19%, 0.8)'
  },

  error: {
    ...baseDarkColorScheme.error,
    main: 'hsl(359, 55%, 56%)',
    light: 'hsl(0, 100%, 70%)',
    shades: ['hsla(360, 42%, 35%, 0.6)', 'hsl(0, 43%, 24%)']
  },
  warning: {
    ...baseDarkColorScheme.warning,
    main: 'hsl(39, 80%, 61%)',
    shades: ['hsl(40, 74%, 70%)']
  }
};
