import { Box, Typography } from '@mui/material';
import { ElementType } from 'react';
export interface ISettingsMainContent {
  title?: string;
  sectionComponent?: ElementType;
}

export const SettingsMainContent = ({
  title,
  sectionComponent
}: ISettingsMainContent) => {
  return (
    <Box component="section" className="settings__main_content">
      {title && (
        <Typography component="h2" variant="h3">
          {title}
        </Typography>
      )}
      {sectionComponent && <Box component={sectionComponent} />}
    </Box>
  );
};
