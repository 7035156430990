import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

import './dialog.scss';
import { Box, Typography, useTheme } from '@mui/material';

export interface IDialogLogout {
  open: boolean;
  onClose: (yes: boolean) => void;
}

export const DialogLogout = ({ open, onClose }: IDialogLogout) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const handleClose = (yes: boolean) => {
    onClose(yes);
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={open}
      sx={{ zIndex: '2000' }}
    >
      <Box sx={{ padding: theme.spacing(3) }} className={'logout-dialog'}>
        <Typography
          variant={'h6'}
          className={'logout-dialog-title'}
          sx={{ marginBottom: theme.spacing(2), fontWeight: '600' }}
        >
          {t('general__session_timeout')}
        </Typography>
        <Typography variant={'body2'} className={'logout-dialog-body'}>
          {t('general__inactivity_logout')}
        </Typography>
      </Box>
    </Dialog>
  );
};
