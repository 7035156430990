import { Box, Paper } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { CSSProperties } from 'react';

import './simpleHeader.scss';
import { TenantFavicon } from '../../../dataDisplay/brandImages/TenantFavicon';

interface ISimpleHeader {
  leftChildren?: ReactJSXElement;
  rightChildren?: ReactJSXElement | null;
  children: ReactJSXElement;
  customStyles?: IsimpleHeaderStyles;
  classes?: ISimpleHeaderClasses;
}

interface IsimpleHeaderStyles {
  header?: CSSProperties;
  itemLeft?: CSSProperties;
  itemCenter?: CSSProperties;
  itemRight?: CSSProperties;
}
interface ISimpleHeaderClasses {
  header?: string;
  itemLeft?: string;
  itemCenter?: string;
  itemRight?: string;
}

const SimpleHeader = ({
  leftChildren,
  rightChildren,
  children,
  customStyles,
  classes = {
    header: '',
    itemLeft: '',
    itemCenter: '',
    itemRight: ''
  }
}: ISimpleHeader) => {
  return (
    <Paper
      component="header"
      className={`simple-header ${classes.header}`}
      sx={{ ...customStyles?.header }}
    >
      <TenantFavicon />
      <Box
        className={`simple-header__item --left  ${classes.itemLeft}`}
        sx={{ ...customStyles?.itemLeft }}
      >
        {leftChildren}
      </Box>
      <Box
        className={`simple-header__item --center  ${classes.itemCenter}`}
        sx={{ ...customStyles?.itemCenter }}
      >
        {children}
      </Box>
      <Box
        className={`simple-header__item --right  ${classes?.itemRight}`}
        sx={{ ...customStyles?.itemRight }}
      >
        {rightChildren}
      </Box>
    </Paper>
  );
};

export default SimpleHeader;
