import { CustomColorSchemesWithoutMode } from 'src/styles/_theme/customTheme';
import { Language } from 'src/app/utilities/i18n';
import { INewImgData } from 'src/app/components/inputs/previewReplaceBox/previewReplaceBox';

export interface ITenantLanguage {
  key: Language;
  isDefaultInterfaceLanguage: boolean;
  isToolingLanguage: boolean;
}

export interface ITenantBrandImageMetadata {
  contentType: string;
  createdBy: string;
  createdDateTime: string;
  extension: string;
  modifiedBy: string;
  modifiedDateTime: string;
}

export interface ITenantBasicInfo {
  id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  isSmartKeywordEnabled: boolean;
  createdDateTime: string;
  modifiedDateTime?: string;
  colouredLogoMetadata?: ITenantBrandImageMetadata;
  whiteLogoMetadata?: ITenantBrandImageMetadata;
  faviconMetadata?: ITenantBrandImageMetadata;
}

export enum ETenantBrandImages {
  COLOURED_LOGO = 'colouredLogo',
  WHITE_LOGO = 'whiteLogo',
  FAVICON = 'favicon'
}

//type when we upload a new image
export type TenantBrandImagesData = {
  [key in ETenantBrandImages]?: INewImgData;
};

//type when image is already existent in api
export type TenantBrandImagesInfo = {
  [key in ETenantBrandImages]: ITenantBrandImageInfo | undefined;
};

export interface ITenantInfoApi extends ITenantBasicInfo {
  themeColor: string;
}
export interface ITenantInfo extends ITenantBasicInfo {
  themeColor?: CustomColorSchemesWithoutMode;
  brandImages?: Partial<TenantBrandImagesInfo>;
}

export interface ICustomizationInfo {
  themeColor?: string | undefined;
  brandImages: Partial<TenantBrandImagesInfo>;
}

export interface ITenantInfoWithBranding extends ICustomizationInfo {
  name: string;
  isSmartKeywordEnabled: boolean;
  createdDateTime: string;
  modifiedDateTime?: string;
}

export interface ITenantBrandImagePropsForAPI {
  imgFormData?: FormData;
  brandImageType: ETenantBrandImages;
  controller?: AbortController;
}

export interface ITenantBrandImageApiInfo {
  imageUri: string;
  imageMetadata: ITenantBrandImageMetadata;
}
export interface ITenantBrandImageInfo extends ITenantBrandImageApiInfo {
  altText?: string;
}
