import {
  getPrimaryDarkColorShade,
  getPrimaryLightColorShade
} from '../_components/utils/colors';
import { IThemeColorScheme, IThemeNaming } from '../customTheme';
import { baseDarkColorScheme, baseLightColorScheme } from './baseColorScheme';
import i18next from 'i18next';

const themeNaming: IThemeNaming = {
  colorName: 'green',
  label: i18next.t('settings__color_scheme_label_green')
};

export const greenLightColorScheme: IThemeColorScheme = {
  ...baseLightColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(140, 80%, 22%)',
    light: 'hsl(140, 80%, 28%)',
    dark: 'hsl(139, 79%, 20%)',
    variant: 'hsl(139, 79%, 20%)',
    shades: getPrimaryLightColorShade('140'),
    contrast: baseLightColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(137, 47%, 94%)',
    light: 'hsl(138, 50%, 96%)',
    dark: 'hsl(140, 48%, 90%)',
    contrast: 'hsl(140, 80%, 19%)'
  },
  accent: {
    main: 'hsl(43, 95%, 57%)'
  },
  text: {
    ...baseLightColorScheme.text,
    primary: 'hsl(141, 46%, 11%)',
    secondary: 'hsl(144, 3%, 32%)',
    disabled: 'hsl(135, 2%, 64%)'
  },
  background: {
    ...baseLightColorScheme.background,
    default: 'hsl(135, 5%, 97%)'
  },
  success: {
    main: 'hsl(140, 80%, 30%)',
    contrast: 'hsl(96, 29%, 20%)',
    shades: ['hsl(140, 29%, 94%)']
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(139, 80%, 28%)',
    disabled: 'hsl(139, 15%, 86%)',
    disableBg: 'hsla(139, 18%, 9%, 0.5)',
    divider: 'hsl(142, 22%, 90%)',
    outlineBorder: 'hsl(140, 49%, 90%)',
    backdropOverlay: 'hsla(140, 82%, 13%, 0.5)'
  }
};
export const greenDarkColorScheme: IThemeColorScheme = {
  ...baseDarkColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(137, 50%, 47%)',
    light: 'hsl(137, 57%, 53%)',
    dark: 'hsl(137, 51%, 43%)',
    variant: 'hsl(137, 51%, 43%)',
    contrast: 'hsl(231, 10%, 14%)',
    shades: getPrimaryDarkColorShade('200', '23%', [
      '18%',
      '20%',
      '24%',
      '29%',
      '37%'
    ])
  },
  secondary: {
    main: 'hsl(201, 23%, 25%)',
    light: 'hsl(200, 23%, 20%)',
    dark: 'hsl(200, 23%, 18%)',
    contrast: 'hsl(231, 10%, 14%)'
  },
  accent: {
    main: 'hsl(43, 99%, 53%)'
  },
  text: {
    ...baseDarkColorScheme.text,
    secondary: 'hsl(137, 10%, 76%)',
    disabled: 'hsl(137, 10%, 34%)'
  },
  background: {
    paper: 'hsl(220, 23%, 13%)',
    default: 'hsl(220, 25%, 9%)'
  },
  success: {
    ...baseDarkColorScheme.success,
    main: 'hsl(142, 74%, 35%)',
    shades: ['hsl(142, 74%, 40%)']
  },
  action: {
    ...baseDarkColorScheme.action,
    active: 'hsl(137, 57%, 53%)',
    disabled: 'hsl(140, 5%, 40%)',
    disableBg: 'hsla(200, 43%, 16%, 0.7)',
    divider: 'hsl(220, 25%, 9%)',
    outlineBorder: 'hsl(200, 23%, 28%)',
    backdropOverlay: 'hsla(202, 11%, 19%, 0.8)'
  },
  error: {
    ...baseDarkColorScheme.error,
    main: 'hsl(360, 55%, 56%)',
    light: 'hsl(0, 100%, 70%)',
    shades: ['hsla(0, 42%, 32%, 0.6)', 'hsl(0, 43%, 24%)']
  },
  warning: {
    main: 'hsl(40, 80%, 61%)',
    contrast: 'hsl(40, 31%, 24%)',
    shades: ['hsl(40, 73%, 70%)']
  }
};
