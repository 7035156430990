import { AnySchema, ValidationError, string } from 'yup';
import { TFunction } from 'react-i18next';

export const unauthorizedCharactersRegex = /[#!%/\\]/;

export interface IErrors {
  [key: string]: string[];
}

const isNullOrUndefined = (value: string | null | undefined) =>
  value === null || value === undefined;

export const passwordSchema = (t: TFunction<'translation', undefined>) => {
  return string()
    .trim()
    .required(t('general__field_required'))
    .min(8, t('general__password_length_error'))
    .test({
      name: 'minLowercase',
      exclusive: true,
      message: t('general__password_lowercase_error'),
      test: (value: string | null | undefined) =>
        isNullOrUndefined(value) || (value?.match(/[a-z]/g) || []).length >= 1
    })
    .test({
      name: 'minUppercase',
      exclusive: true,
      message: t('general__password_uppercase_error'),
      test: (value: string | null | undefined) =>
        isNullOrUndefined(value) || (value?.match(/[A-Z]/) || []).length >= 1
    })
    .test({
      name: 'minNumber',
      exclusive: true,
      message: t('general__password_number_error'),
      test: (value: string | null | undefined) =>
        isNullOrUndefined(value) || (value?.match(/[0-9]/) || []).length >= 1
    })
    .test({
      name: 'minSpecial',
      exclusive: true,
      message: t('general__password_special_error'),
      test: (value: string | null | undefined) =>
        isNullOrUndefined(value) ||
        (value?.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/) || []).length >= 1
    });
};

export const isFieldValid = (
  yupSchema: AnySchema,
  values: unknown,
  fieldName: string
) => {
  let isValid = true;
  try {
    yupSchema.validateSync(values, { abortEarly: false });
  } catch (err: unknown) {
    if (err instanceof ValidationError) {
      err?.inner?.every((err) => {
        if (err && err.path === fieldName) {
          isValid = false;
          return false;
        }
        return true;
      });
    }
  }
  return isValid;
};

export const getFieldErrors = (
  yupSchema: AnySchema,
  values: unknown,
  fieldName?: string
) => {
  const errors: string[] = [];
  try {
    yupSchema.validateSync(values, { abortEarly: false });
  } catch (err) {
    if (err instanceof ValidationError) {
      err?.inner?.map((err) => {
        if (
          (err && err.path === fieldName) ||
          typeof fieldName === 'undefined'
        ) {
          errors.push(err.message);
        }
      });
    }
  }
  return errors;
};

export const isFormValid = (yupSchema: AnySchema, values: unknown) => {
  let isValid = true;
  try {
    yupSchema.validateSync(values);
  } catch (err: unknown) {
    if (err instanceof ValidationError) {
      isValid = false;
    }
  }
  return isValid;
};

export const getSchemaErrors = (
  yupSchema: AnySchema,
  values: unknown
): IErrors => {
  const errors: IErrors = {};
  try {
    yupSchema.validateSync(values, { abortEarly: false });
  } catch (err) {
    if (err instanceof ValidationError) {
      err?.inner?.map((err) => {
        if (err && err.path) {
          if (errors[err.path] === undefined) {
            errors[err.path] = [err.message];
          } else {
            errors[err.path].push(err.message);
          }
        }
      });
    }
  }
  return errors;
};

export const getSchemaPathErrors = (
  path: string,
  yupSchema: AnySchema,
  rootValue: unknown
) => {
  const errors: IErrors = {};
  try {
    yupSchema.validateSyncAt(path, rootValue, { abortEarly: false });
  } catch (err) {
    if (err instanceof ValidationError) {
      err?.inner?.map((err) => {
        if (err && err.path) {
          if (errors[err.path] === undefined) {
            errors[err.path] = [err.message];
          } else {
            errors[err.path].push(err.message);
          }
        }
      });
    }
  }
  return errors;
};

export const requiredStringSchema = (
  t: TFunction<'translation', undefined>
) => {
  return string().trim().required(t('general__field_required'));
};

export const requiredEmailSchema = (t: TFunction<'translation', undefined>) => {
  return string()
    .trim()
    .email(t('general__email_error'))
    .required(t('general__field_required'));
};

export const stringSchema = () => {
  return string();
};

export const adminNameSchema = (t: TFunction<'translation', undefined>) => {
  return string()
    .trim()
    .required(t('general__field_required'))
    .test({
      name: 'noSpecialChars',
      exclusive: true,
      message: t('general__admin_form_name_error'),
      test: (value) => {
        if (!value) return true;

        return !unauthorizedCharactersRegex.test(value);
      }
    });
};

export const fieldsTextInputSchema = (
  t: TFunction<'translation', undefined>,
  required: boolean,
  length: number
) => {
  return string()
    .trim()
    .test({
      name: 'requiredFromParameter',
      exclusive: true,
      message: t('general__field_required'),
      test: (value) => {
        if (required && !value?.length) return false;

        return true;
      }
    })
    .test({
      name: 'noSpecialChars',
      exclusive: true,
      message: t('general__admin_form_name_error'),
      test: (value) => {
        if (!value) return true;

        return !unauthorizedCharactersRegex.test(value);
      }
    })
    .test({
      name: 'lengthRange',
      exclusive: true,
      message: t('general__admin_form_text_range_error'),
      test: (value) => {
        if (value && value.length > length) return false;

        return true;
      }
    });
};
