import { ReactNode } from 'react';
import create from 'zustand';

interface ISwipeableDrawerStore {
  swipeableDrawerisOpen: boolean;
  swipeableDrawercontent: ReactNode;
  swipeableDrawerOnClose: () => void;
  swipeableDrawerChangeOpen: (value: boolean) => void;
  swipeableDrawerSetContent: (value: ReactNode) => void;
  swipeableDrawerSetOnClose: (value: () => void) => void;
}

export const useSwipeableDrawer = create<ISwipeableDrawerStore>((set) => ({
  swipeableDrawerisOpen: false,
  swipeableDrawercontent: null,
  swipeableDrawerOnClose: () => undefined,
  swipeableDrawerChangeOpen: (value: boolean) =>
    set({ swipeableDrawerisOpen: value }),
  swipeableDrawerSetContent: (value: ReactNode) =>
    set({ swipeableDrawercontent: value }),
  swipeableDrawerSetOnClose: (value: () => void) =>
    set({ swipeableDrawerOnClose: value })
}));
