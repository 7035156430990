import { Box, useTheme } from '@mui/material';
import { CustomIcons } from 'src/app/components/dataDisplay/icons/CustomIcons';

interface IHeaderTitleIcon {
  iconName: string;
}

export const HeaderTitleIcon = ({ iconName }: IHeaderTitleIcon) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      padding={theme.spacing(1)}
      borderRadius="0.25rem"
      sx={{
        backgroundColor: theme.vars.palette.background.default,
        color: theme.vars.palette.text.primary
      }}
    >
      <CustomIcons
        iconName={iconName}
        customStyles={{
          fontSize: '1.25rem',
          margin: 'auto'
        }}
      />
    </Box>
  );
};
