import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledIconButtons: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        backgroundColor: theme.vars.palette.background.paper,
        borderRadius: '0.5rem',
        border: `0.125rem solid ${theme.vars.palette.action.outlineBorder}`,
        padding: theme.spacing(1),
        color: theme.vars.palette.text.primary,
        transition: 'all 150ms ease-in-out',
        fontSize: '1.5rem',

        '&:hover': {
          backgroundColor: theme.vars.palette.primary.shades[0],
          color: theme.vars.palette.primary.dark
        },
        '&.Mui-disabled': {
          backgroundColor: `${theme.vars.palette.action.disabled}`,
          borderColor: theme.vars.palette.action.disabled,
          color: theme.vars.palette.text.secondary
        },
        '& .MuiTouchRipple-child': {
          backgroundColor: `hsl(${theme.vars.palette.primary.shadesChannels[4]} / 0.2 )`
        },
        '&.hover-border': {
          '&:hover, &.selected': {
            borderColor: theme.vars.palette.primary.main
          }
        },
        '&.tooltip-icon-btn': {
          backgroundColor: 'transparent',
          border: 0,
          borderRadius: '50%',

          '&:hover': {
            color: theme.vars.palette.text.primary
          }
        },
        ...(ownerState.color === 'inherit' && {
          border: 0,
          minWidth: '2.5rem',
          minHeight: '2.5rem',
          color: theme.vars.palette.text.primary,
          '&:hover': {
            background: `radial-gradient(circle, hsl(${theme.vars.palette.primary.shadesChannels[2]} / 1) 50%, hsl(${theme.vars.palette.primary.shadesChannels[2]} / 0) 51%)`,
            color: theme.vars.palette.primary.dark
          }
        }),
        ...(ownerState.color === 'secondary' && {
          border: 0,
          backgroundColor: theme.vars.palette.primary.main,
          borderRadius: '0.25rem',
          minWidth: '2rem',
          minHeight: '2rem',
          color: theme.vars.palette.primary.contrastText,
          fontSize: '2rem',

          '&:hover': {
            backgroundColor: theme.vars.palette.primary.dark,
            color: theme.vars.palette.primary.contrastText
          },
          '& .MuiTouchRipple-child': {
            background: `radial-gradient(circle, hsl(${theme.vars.palette.primary.shadesChannels[2]} / 1) 96.65%, hsl(${theme.vars.palette.primary.shadesChannels[2]} / 0) 100%)`
          }
        }),
        ...(ownerState.color === 'primary' && {
          border: 'none',
          '&:hover': {
            background: 'transparent',
            color: theme.vars.palette.primary.dark
          },
          '&.Mui-disabled': {
            background: 'transparent',
            color: theme.vars.palette.action.disabled
          }
        })
      })
    },
    variants: [
      {
        props: { color: 'primaryLight' },
        style: ({ theme }) => ({
          border: 0,
          backgroundColor: theme.vars.palette.primaryLight.main,
          color: theme.vars.palette.primaryLight.contrastText,

          '&:hover': {
            backgroundColor: theme.vars.palette.primaryLight.dark,
            color: theme.vars.palette.primaryLight.contrastText
          },
          '& .MuiTouchRipple-child': {
            background: `radial-gradient(circle, hsl(${theme.vars.palette.primaryLight.lightChannel} / 1) 96.65%, hsl(${theme.vars.palette.primaryLight.lightChannel} / 0) 100%)`
          }
        })
      },
      {
        props: { size: 'small' },
        style: () => ({
          borderRadius: '0.25rem',
          fontSize: '1.25rem',
          minWidth: '2rem',
          minHeight: '2rem'
        })
      },
      {
        props: { size: 'smallMedium' },
        style: () => ({
          borderRadius: '0.25rem',
          minWidth: '2rem',
          minHeight: '2rem',
          fontSize: '2rem'
        })
      }
    ]
  }
};
