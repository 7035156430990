import { SwipeableDrawer } from '@mui/material';
import { useSwipeableDrawer } from 'src/app/stores/swipeableDrawerStore';

import './basic-swipeable-drawer.scss';
import { SyntheticEvent } from 'react';

export const BasicSwipeableDrawer = () => {
  const {
    swipeableDrawerisOpen,
    swipeableDrawerChangeOpen,
    swipeableDrawercontent,
    swipeableDrawerOnClose
  } = useSwipeableDrawer();

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (
    value: boolean,
    event?: SyntheticEvent<unknown, Event>
  ) => {
    // allows navigation in drawer with keyboard
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    swipeableDrawerChangeOpen(value);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={swipeableDrawerisOpen}
      onClose={(e) => {
        toggleDrawer(false, e);
        swipeableDrawerOnClose();
      }}
      onOpen={() => toggleDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      PaperProps={{ className: 'swipeable-drawer' }}
      sx={{ zIndex: (theme) => `${theme.zIndex.drawer + 1}` }}
    >
      <div className="swipeable-drawer__puller"></div>
      {swipeableDrawercontent}
    </SwipeableDrawer>
  );
};
