import { Box } from '@mui/material';
import './settingTermsOfUse.scss';
import { Language } from '../../../../../../utilities';
import { returnTermsOfUseEn } from './TermsOfUse/TermsOfUseEn';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { returnTermsOfUseEs } from './TermsOfUse/TermsOfUseEs';
import { useSettingLanguageForm } from '../SettingLanguage/useSettingLanguageForm';
import { returnTermsOfUseFr } from './TermsOfUse/TermsOfUseFr';

interface ITermsOfUseByLanguage {
  text: string;
}
export const SettingTermsOfUse = () => {
  const { selectedLanguage } = useSettingLanguageForm();

  const TERMS_USE_BY_LANGUAGE: Record<Language, ITermsOfUseByLanguage> = {
    [Language.enUS]: {
      text: returnTermsOfUseEn()
    },
    [Language.esES]: {
      text: returnTermsOfUseEs()
    },
    [Language.frCA]: {
      text: returnTermsOfUseFr()
    }
  };

  return (
    <Box className="setting__container setting__terms-use-container">
      <Markdown remarkPlugins={[remarkGfm]}>
        {TERMS_USE_BY_LANGUAGE[selectedLanguage].text}
      </Markdown>
    </Box>
  );
};
