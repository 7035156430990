import { baseDarkColorScheme, baseLightColorScheme } from './baseColorScheme';
import { IThemeColorScheme, IThemeNaming } from '../customTheme';
import i18next from 'i18next';
import { getPrimaryDarkColorShade } from '../_components/utils/colors';

const themeNaming: IThemeNaming = {
  colorName: 'black',
  label: i18next.t('settings__color_scheme_label_black')
};

export const blackLightColorScheme: IThemeColorScheme = {
  ...baseLightColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(230, 5%, 19%)',
    light: 'hsl(227, 80%, 43%)',
    dark: 'hsl(230, 5%, 14%)',
    variant: 'hsl(230, 5%, 14%)',
    shades: [
      'hsl(0, 0%, 98%)',
      'hsl(230, 24%, 96%)',
      'hsl(230, 16%, 94%)',
      'hsl(230, 18%, 88%)',
      'hsl(230, 12%, 83%)'
    ],
    contrast: baseLightColorScheme.text.contrast
  },
  secondary: {
    main: 'hsl(228, 24%, 96%)',
    light: 'hsl(0, 0%, 98%)',
    dark: 'hsl(228, 16%, 94%)',
    contrast: baseLightColorScheme.text.contrast
  },
  accent: {
    main: 'hsl(230, 5%, 19%)'
  },
  text: {
    ...baseLightColorScheme.text,
    primary: 'hsl(230, 5%, 19%)',
    secondary: 'hsl(230, 5%, 35%)',
    disabled: 'hsl(230, 6%, 55%)'
  },
  background: {
    ...baseLightColorScheme.background,
    default: 'hsl(230, 33%, 97%)'
  },
  warning: {
    ...baseLightColorScheme.warning,
    main: 'hsl(40, 100%, 56%)',
    shades: ['hsl(40, 82%, 89%)']
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(230, 5%, 24%)',
    disabled: 'hsl(230, 27%, 84%)',
    disableBg: 'hsla(230, 5%, 10%, 0.6)',
    divider: 'hsl(230, 16%, 94%)',
    outlineBorder: 'hsl(230, 12%, 92%)',
    backdropOverlay: 'hsla(240, 1%, 19%, 0.54)'
  }
};
export const blackDarkColorScheme: IThemeColorScheme = {
  ...baseDarkColorScheme,
  ...themeNaming,
  primary: {
    main: 'hsl(0, 0%, 100%)',
    light: 'hsl(225, 100%, 71%)',
    dark: 'hsl(225, 32%, 93%)',
    variant: 'hsl(230, 5%, 14%)',
    shades: [
      ...getPrimaryDarkColorShade('230', '5%', ['33%', '38%', '43%', '52%']),
      'hsl(229, 8%, 62%)'
    ],
    contrast: 'hsl(220, 3%, 19%)'
  },
  secondary: {
    main: 'hsl(234, 5%, 38%)',
    light: 'hsl(230, 6%, 43%)',
    dark: 'hsl(227, 5%, 33%)',
    contrast: 'hsl(220, 3%, 19%)'
  },
  secondaryContrastText: 'hsl(228, 5%, 19%)',
  accent: {
    main: 'hsl(0, 0%, 100%)',
    contrastText: 'hsl(230, 5%, 43%)'
  },
  text: {
    ...baseDarkColorScheme.text,
    secondary: 'hsl(230, 16%, 75%)',
    disabled: 'hsl(230, 6%, 55%)'
  },
  background: {
    paper: 'hsl(230, 5%, 24%)',
    default: 'hsl(230, 5%, 15%)'
  },
  action: {
    ...baseLightColorScheme.action,
    active: 'hsl(210, 3%, 24%)',
    disabled: 'hsl(230, 3%, 45%)',
    disableBg: 'hsla(240, 4%, 10%, 0.6)',
    divider: 'hsl(231, 5%, 26%)',
    outlineBorder: 'hsl(230, 3%, 45%)',
    backdropOverlay: 'hsla(220, 3%, 21%, 0.8)'
  },
  error: {
    ...baseLightColorScheme.error,
    main: 'hsl(359, 55%, 56%)',
    light: 'hsl(0, 100%, 70%)',
    shades: ['hsla(360, 42%, 35%, 0.6)', 'hsl(0, 43%, 24%)']
  },
  warning: {
    ...baseDarkColorScheme.warning,
    main: 'hsl(39, 80%, 61%)',
    shades: ['hsl(40, 74%, 70%)']
  }
};
