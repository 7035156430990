import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledStepper: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiStepper: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: '100%',
        height: '100%',
        margin: 'auto',
        alignItems: 'stretch',

        [theme.breakpoints.up(theme.breakpoints.values.xxs)]: {
          justifyContent: 'center'
        }
      })
    }
  },
  MuiStep: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRight: `1px solid ${theme.palette.primary.shades[2]}`,

        '&:last-of-type': {
          borderRight: 'none'
        },

        '&.--active': {
          fontWeight: 600,
          '.stepper-steps-icon': {
            backgroundColor: theme.vars.palette.accent.main,
            border: '1px solid',
            borderColor: theme.vars.palette.accent.main,
            color: theme.vars.palette.primary.contrastText
          },
          '.MuiStepLabel-label': {
            display: 'inline-block'
          }
        },

        [theme.breakpoints.up(theme.breakpoints.values.xxs)]: {
          padding: theme.spacing(3)
        },

        [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
          '&:first-of-type': {
            borderLeft: `1px solid ${theme.palette.primary.shades[2]}`
          },
          '&:last-of-type': {
            borderRight: `1px solid ${theme.palette.primary.shades[2]}`
          }
        },

        '.MuiStepLabel-labelContainer': {
          display: 'inline-grid',
          width: 'auto'
        }
      })
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.875rem',
        minWidth: 0,
        flex: 1,

        [theme.breakpoints.up(theme.breakpoints.values.xxs)]: {
          fontSize: '1rem'
        },

        '.MuiStepLabel-label ': {
          flex: 1,
          display: 'none',
          paddingLeft: theme.spacing(1),
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          [theme.breakpoints.up(theme.breakpoints.values.xxs)]: {
            display: 'inline-block'
          }
        },

        '.Mui-active': {
          '.MuiStepLabel-label ': {
            fontWeight: 600
          }
        },

        '.Mui-completed &': {
          backgroundColor: theme.vars.palette.primary.shades[0],
          color: theme.vars.palette.primary.light,
          fontSize: '0.875rem',

          '.stepper-steps-icon': {
            border: '1px solid',
            borderColor: 'transparent',
            color: 'inherit'
          },

          [theme.breakpoints.up(theme.breakpoints.values.xxs)]: {
            padding: `${theme.spacing(0.25)} ${theme.spacing(
              1
            )} ${theme.spacing(0.25)} 0`
          }
        },

        '> .MuiStepLabel-iconContainer': {
          padding: 'unset'
        }
      })
    }
  }
};
