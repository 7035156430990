import axiosInstance from '../axiosInstance';
import { AssetGroups, AssetGroupsKeys } from '../models/assets';

const METADATA_PREFIX = 'api/v2/metadata';
const METADATA_FIELDS = `${METADATA_PREFIX}/fields`;

export type MetadataValue = string | string[] | Date;
export type MetadataValues = { [key: string]: MetadataValue };

export type FieldOptionModel = {
  label: string;
  id: string;
  displayOrder?: number;
};

export enum MetaDataFieldEnum {
  SingleLineTextbox = 'singleLineTextbox',
  MultiLineTextbox = 'multiLineTextbox',
  RadioButton = 'radioButton',
  SingleValueDropdown = 'singleValueDropdown',
  MultiValueDropdown = 'multiValueDropdown',
  Checkbox = 'checkbox',
  Date = 'date',
  CustomKeywords = 'customKeywords'
}

export interface IMetadata {
  id: string;
  required: boolean;
  displayOrder: number;
  fieldType?: MetaDataFieldEnum;
  assetType: AssetGroupsKeys;
  assetTypeExtensions: string[];
  label?: string;
  placeHolder?: string;
  options?: IMetadataOptions[];
  [key: string]: unknown;
}

export interface IMetadataOptions {
  label: string;
  id: string;
  displayOrder: number;
  [key: string]: unknown;
}

export interface IAdvancedFilterSelection {
  [key: string]: string[];
}
export type AssetGroupsMetadataList = Record<AssetGroups | string, IMetadata[]>;

export async function getMetadataFields(
  assetTypeCodes?: AssetGroupsKeys[],
  controller?: AbortController
): Promise<{
  data: IMetadata[];
}> {
  return await axiosInstance.get(METADATA_FIELDS, {
    params: { assetTypeCodes: assetTypeCodes },
    signal: controller?.signal
  });
}

export async function getAdvancedFilters(
  controller?: AbortController
): Promise<{
  data: IMetadata[];
}> {
  return await axiosInstance.get(METADATA_FIELDS, {
    params: { onlyAdvancedFilters: true },
    signal: controller?.signal
  });
}
