import create from 'zustand';
import { ITenantInfoWithBranding } from '../api/models/tenants';
import { persist } from 'zustand/middleware';

interface ITenantStore {
  tenant?: ITenantInfoWithBranding | undefined;
  lastFetchDate?: string | undefined;
  setTenant(tenant: ITenantInfoWithBranding | undefined): void;
}

export const useTenantStore = create<ITenantStore>()(
  persist(
    (set) => ({
      tenant: undefined,
      lastFetchDate: undefined,
      setTenant: (tenant: ITenantInfoWithBranding | undefined) => {
        set(() => ({
          lastFetchDate:
            tenant !== undefined ? new Date().toISOString() : undefined,
          tenant: tenant
        }));
      }
    }),
    { name: 'tenant' }
  )
);
