import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledChips } from './chips';
import { styledDivider } from './divider';
import { styledTypography } from './typography';
import { styledTooltip } from './tooltip';
import { styledList } from './list';
import { styledTable } from './table';

export const styledDataDisplayAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledDivider,
  ...styledChips,
  ...styledTypography,
  ...styledTooltip,
  ...styledList,
  ...styledTable
};
