import create, { StateCreator } from 'zustand';

interface ISettingsModalSlice {
  settingsModalOpen: boolean;
  setSettingsModalOpen: (value: boolean) => void;
}

const createSettingsModalSlice: StateCreator<ISettingsModalSlice> = (set) => ({
  settingsModalOpen: false,
  setSettingsModalOpen: (open: boolean) =>
    set(() => ({ settingsModalOpen: open }))
});

interface ISettingsSlice {
  selectedSectionIndex: number;
  setSelectedSectionIndex: (index: number) => void;
}

const createSettingsSlice: StateCreator<ISettingsSlice> = (set) => ({
  selectedSectionIndex: 0,
  setSelectedSectionIndex: (index: number) =>
    set(() => ({ selectedSectionIndex: index }))
});

export const useSettings = create<ISettingsModalSlice & ISettingsSlice>()(
  (...a) => ({
    ...createSettingsModalSlice(...a),
    ...createSettingsSlice(...a)
  })
);
