import { lazy } from 'react';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';
import { IPaths } from 'src/app/routes/IPath';
import { EPageLocation } from 'src/app/routes/pageLocation';
import { IRoute } from 'src/app/routes/Routes';
import { EN_US, ES_ES, FR_CA } from 'src/app/utilities';

const loginComponent = lazy(() => import('./login/Login'));
const forgotPasswordComponent = lazy(
  () => import('./forgotPassword/ForgotPassword')
);
const newPasswordRequiredComponent = lazy(
  () => import('./newPasswordRequired/NewPasswordRequired')
);

const loginPaths: IPaths = {
  'en-US': `/${EN_US}/old/${enUS.routes__login_page_slug}`,
  'es-ES': `/${ES_ES}/old/${esES.routes__login_page_slug}`,
  'fr-CA': `/${FR_CA}/old/${frCA.routes__login_page_slug}`
};

const forgotPasswordPaths: IPaths = {
  'en-US': `/${EN_US}/old/${enUS.routes__forgot_password_slug}`,
  'es-ES': `/${ES_ES}/old/${esES.routes__forgot_password_slug}`,
  'fr-CA': `/${FR_CA}/old/${frCA.routes__forgot_password_slug}`
};

const newPasswordRequiredPaths: IPaths = {
  'en-US': `/${EN_US}/old/${enUS.routes__new_password_required_slug}`,
  'es-ES': `/${ES_ES}/old/${esES.routes__new_password_required_slug}`,
  'fr-CA': `/${FR_CA}/old/${frCA.routes__new_password_required_slug}`
};

const loginLocation = EPageLocation.login;
const forgotPasswordLocation = EPageLocation.forgotPassword;
const newPasswordRequiredLocation = EPageLocation.newPasswordRequired;

export const loginRoute: IRoute = {
  component: loginComponent,
  paths: loginPaths,
  location: loginLocation
};

export const forgotPasswordRoute: IRoute = {
  component: forgotPasswordComponent,
  paths: forgotPasswordPaths,
  location: forgotPasswordLocation
};

export const newPasswordRequiredRoute: IRoute = {
  component: newPasswordRequiredComponent,
  paths: newPasswordRequiredPaths,
  location: newPasswordRequiredLocation
};
