import { Dispatch, createContext, useReducer } from 'react';
import {
  ISearchState,
  SEARCH_INITIALSTATE,
  TSearchAction,
  searchReducer
} from './searchReducer';

export interface ISearchProviderProps {
  children: React.ReactNode;
}

export const SearchStateContext =
  createContext<ISearchState>(SEARCH_INITIALSTATE);
export const SearchDispatchContex = createContext<Dispatch<TSearchAction>>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (action: TSearchAction) => undefined
);

export const SearchProvider = ({ children }: ISearchProviderProps) => {
  const [searchState, dispatch] = useReducer(
    searchReducer,
    SEARCH_INITIALSTATE
  );

  return (
    <SearchStateContext.Provider value={searchState}>
      <SearchDispatchContex.Provider value={dispatch}>
        {children}
      </SearchDispatchContex.Provider>
    </SearchStateContext.Provider>
  );
};
