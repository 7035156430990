import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomThemeProvider } from 'src/styles/_theme';
import { CustomColorSchemesWithoutMode } from 'src/styles/_theme/customTheme';
import { AuthenticationProvider } from '../contexts/authentication';
import { TenantProvider } from '../contexts/tenant';
import { ALERT_AUTO_HIDE_DURATION } from '../utilities';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { UserProvider } from 'src/app/contexts/user/UserProvider';
import useCdnConfig from 'src/app/utilities/hooks/useCdnConfig';
import Maintenance from '../pages/error/Maintenance';

export const AsmblRouterWrapper = () => {
  const [customTenantTheme, setCustomTenantTheme] = useState<
    CustomColorSchemesWithoutMode | undefined
  >(undefined);
  const { loadConfiguration, cdnConfig } = useCdnConfig();

  useEffect(() => {
    loadConfiguration();
  }, [loadConfiguration]);

  return (
    <CustomThemeProvider themeFromTenant={customTenantTheme}>
      {cdnConfig.isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <NotistackSnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          autoHideDuration={ALERT_AUTO_HIDE_DURATION}
          disableWindowBlurListener
        >
          <AuthenticationProvider>
            <UserProvider>
              <TenantProvider setCustomTheme={setCustomTenantTheme}>
                <Outlet />
              </TenantProvider>
            </UserProvider>
          </AuthenticationProvider>
        </NotistackSnackbarProvider>
      )}
    </CustomThemeProvider>
  );
};

export default AsmblRouterWrapper;
