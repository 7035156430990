import create from 'zustand';
import { DEFAULT_IDLE_TIMER } from '../utilities';

interface IIdleTimerStore {
  timer: number;
  setTimer: (value: number) => void;
  pausing: boolean;
  setPausing: (value: boolean) => void;
}

export const useIdle = create<IIdleTimerStore>((set) => ({
  timer: DEFAULT_IDLE_TIMER,
  setTimer: (value) => set(() => ({ timer: value })),
  pausing: false,
  setPausing: (value) => set(() => ({ pausing: value }))
}));
