import { NavigateFunction } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import {
  IRecentViewedAsset,
  IRecentItem,
  IRecentSearch
} from 'src/app/api/models/users';
import {
  IDisplayAssetData,
  ISearchResultAsset,
  RecentSearchTypeEnum,
  IAssetSuggestionDisplay,
  ESearchViews,
  SearchSortingEnum,
  SearchSortingOrderEnum,
  SearchAssetTypeFiltersEnum,
  SearchDateFiltersEnum
} from 'src/app/api/models/search';
import { Claim, Claims } from 'src/app/utilities/claims';
import { ISearchState } from './searchReducer';
import { DEFAULT_QUERY } from './searchConstants';

const getUserPermissions = (catalogId: string, claims?: Claims) => {
  const userCanEdit = claims?.hasCatalogClaim(catalogId, Claim.Asset.Update);
  const userCanDelete = claims?.hasCatalogClaim(catalogId, Claim.Asset.Delete);
  return { canEdit: userCanEdit, canDelete: userCanDelete };
};

const isRecentViewedAsset = (item: IRecentItem): item is IRecentViewedAsset => {
  return 'id' in item;
};

const isRecentSearch = (item: IRecentItem): item is IRecentSearch => {
  return 'searchText' in item;
};

const combineRecentTextAndMetadata = (text: string, metadata: string[]) => {
  const searchTerms = [...metadata, text];
  return searchTerms.join(' ');
};

export const mergeRecentSearches = (
  recentSearches: IRecentItem[],
  navigate: NavigateFunction,
  navigatePath: string
) => {
  return [
    ...recentSearches.map((rs) => {
      if (isRecentViewedAsset(rs)) {
        return {
          label: rs.name,
          type: RecentSearchTypeEnum.ASSET,
          onClick: () => navigate(`${navigatePath}?id=${rs.id}`)
        };
      }

      if (isRecentSearch(rs)) {
        return {
          label: combineRecentTextAndMetadata(rs.searchText, rs.metadata ?? []),
          type:
            rs.searchText === ''
              ? RecentSearchTypeEnum.METADATA
              : RecentSearchTypeEnum.TEXT,
          query: {
            metadata: rs.metadata ?? [],
            searchText: rs.searchText
          }
        };
      }

      return {
        label: '',
        type: RecentSearchTypeEnum.TEXT,
        query: {
          metadata: [],
          searchText: ''
        }
      };
    })
  ];
};

export const mapSuggestions = (
  assets: IAssetSuggestionDisplay[],
  navigate: NavigateFunction,
  t: TFunction<'translation', undefined>,
  claims?: Claims
) => {
  return assets.map((a) => {
    const permissions = getUserPermissions(a.catalogId || '', claims);
    return {
      thumbnailSrc: a.thumbnailSrc,
      label: a.name,
      onClick: () =>
        navigate(
          `/${t('locale_key')}/${
            permissions.canEdit
              ? t('routes__edit_page_slug')
              : t('routes__assets_slug')
          }?id=${a.id}`
        )
    };
  });
};

export const transformAssetsForDisplay = (
  newResults: ISearchResultAsset[],
  claims?: Claims
): IDisplayAssetData[] => {
  return newResults.map((resultItem: ISearchResultAsset): IDisplayAssetData => {
    return {
      ...resultItem,
      userPermissions: getUserPermissions(resultItem.catalog.id, claims)
    };
  });
};

interface ISerializeSearchQuery extends ISearchState {
  searchText: string;
}
export const serializeSearchQuery = (sq: ISerializeSearchQuery) => {
  const params = new URLSearchParams();

  if (sq) {
    Object.entries(sq).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (key)
          value.forEach((paramValue) =>
            params.append(key, paramValue.toString())
          );
      } else if (typeof value === 'string') {
        params.append(key, value);
      } else if (typeof value === 'object' && Object.values(value).length > 0) {
        params.append(key, window?.btoa(JSON.stringify(value)));
      } else if (typeof value !== 'object') {
        params.append(key, value?.toString() ?? '');
      }
    });
  }

  return params;
};

export const getParamsFromUrlSearchParams = (
  searchParams: URLSearchParams
): ISearchState => {
  const searchView =
    (searchParams.get('searchView') as ESearchViews) ?? ESearchViews.grid;

  const sorting =
    (searchParams.get('sorting') as SearchSortingEnum) ??
    SearchSortingEnum.DATE;

  const orderBy =
    (searchParams.get('orderBy') as SearchSortingOrderEnum) ??
    SearchSortingOrderEnum.DESCENDING;

  const metadata = searchParams.getAll('metadata') ?? ([] as string[]);

  let assetTypeFilters = searchParams.getAll(
    'assetTypeFilters'
  ) as SearchAssetTypeFiltersEnum[];

  if (!assetTypeFilters?.length) {
    assetTypeFilters = [SearchAssetTypeFiltersEnum.ALL];
  }

  const searchText = (searchParams.get('searchText') as string) ?? '';

  const page = searchParams.get('page') ?? DEFAULT_QUERY.page;

  const dateFilter =
    (searchParams.get('dateFilter') as SearchDateFiltersEnum) ??
    DEFAULT_QUERY.dateFilter;

  const limit = searchParams.get('limit') ?? DEFAULT_QUERY.limit;

  const advancedFilterOptionsParam = searchParams.get('advancedFilterOptions');
  const advancedFilterOptions = advancedFilterOptionsParam
    ? JSON.parse(window?.atob(advancedFilterOptionsParam))
    : {};

  const isVectorSearch =
    (searchParams.get('isVectorSearch') ?? 'false') === 'true';

  return {
    searchView,
    sorting,
    orderBy,
    assetTypeFilters,
    metadata,
    searchText,
    page: Number(page) as number,
    dateFilter,
    limit: Number(limit) as number,
    advancedFilterOptions,
    isVectorSearch
  };
};
