import axiosInstance from '../axiosInstance';
import {
  IAssetBatchResultError,
  IAssetBatchResultSuccess,
  IAssetDetailWithMetadata
} from '../models/assetDetailWithMetadata';
import {
  IAssetTypes,
  IAssetIdAndThumbnailInfo,
  IAssetDuplicateReturn,
  IBEAssetTypes
} from '../models/assets';
import { IJsonPatchOperation } from '../jsonPatch';
import { IAssetProhibitedRules } from '../models/assetProhibitedRules';
import { AxiosError } from 'axios';

const ASSETS_PREFIX = 'api/v1/assets';
const ASSETS_TYPES = `${ASSETS_PREFIX}/types`;
const ASSETS_DUPLICATES = `${ASSETS_PREFIX}/duplicate`;
const ASSETS_SMART_KEYWORDS_SUFFIX = `smartkeywords`;
const ASSETS_DETAILS_WITH_METADATA_SUFFIX = `details-with-metadata`;
const ASSETS_BULK = `${ASSETS_PREFIX}/bulk`;
const ASSETS_THUMBNAILS = `${ASSETS_PREFIX}/thumbnails`;
const ASSETS_DUPLICATE_SUFFIX = 'duplicate';
const ASSETS_THUMBNAIL_SUFFIX = 'thumbnail';
const ASSETS_VERSION_SUFFIX = 'version';
const ASSETS_PROHIBITED_RULES = `${ASSETS_PREFIX}/prohibited-rules`;

export async function getAssetTypes(
  controller?: AbortController
): Promise<IAssetTypes> {
  try {
    const assetTypes: {
      data: IBEAssetTypes;
    } = await axiosInstance.get(ASSETS_TYPES, {
      signal: controller?.signal
    });

    const mappedProperties = Object.entries(assetTypes.data.properties).map(
      ([, value]) => {
        return {
          id: value.id,
          uncommonProperties: value.uncommonProperties,
          propKey: value.type
        };
      }
    );
    const mappedAssetTypes = {
      ...assetTypes.data,
      properties: mappedProperties
    };
    return mappedAssetTypes;
  } catch (error: unknown) {
    throw error as AxiosError;
  }
}

export async function deleteAsset(
  uploadId: string,
  controller?: AbortController
) {
  return await axiosInstance.delete(ASSETS_PREFIX + '/' + uploadId, {
    signal: controller?.signal
  });
}

export async function deleteBulkAsset(
  assetIds: string[],
  signal?: AbortSignal
) {
  return await axiosInstance.delete(ASSETS_BULK, {
    data: {
      ids: assetIds
    },
    signal: signal
  });
}

export async function getAssetSmartKeywords(
  assetId: string,
  controller?: AbortController
): Promise<{ data: string[] }> {
  return await axiosInstance.get(
    `${ASSETS_PREFIX}/${assetId}/${ASSETS_SMART_KEYWORDS_SUFFIX}`,
    { signal: controller?.signal }
  );
}

export async function getDuplicateNameValidation(
  assetId: string,
  filename: string,
  includeTemporary: boolean,
  controller?: AbortController
): Promise<{ data: IAssetDuplicateReturn }> {
  return await axiosInstance.get(
    `${ASSETS_PREFIX}/${assetId}/${ASSETS_DUPLICATE_SUFFIX}/${filename}`,
    {
      params: { includeTemporaryAsset: includeTemporary },
      signal: controller?.signal
    }
  );
}

export async function getDuplicatedFilenames(
  filenames: string[],
  includeTemporary: boolean,
  controller?: AbortController
): Promise<{ data: IAssetDuplicateReturn[] }> {
  return await axiosInstance.post(
    `${ASSETS_DUPLICATES}`,
    {
      filenames,
      includeTemporary
    },
    { signal: controller?.signal }
  );
}

export async function getAssetDetailWithMetadata(
  assetId: string,
  signal?: AbortSignal
): Promise<{ data: IAssetDetailWithMetadata }> {
  return await axiosInstance.get(
    `${ASSETS_PREFIX}/${assetId}/${ASSETS_DETAILS_WITH_METADATA_SUFFIX}`,
    { signal: signal }
  );
}

export async function getDetailedAssets(
  assetIds: string[],
  signal?: AbortSignal
): Promise<{ data: IAssetDetailWithMetadata }[]> {
  const endpoints: string[] = [];
  assetIds.map((assetId) =>
    endpoints.push(
      `${ASSETS_PREFIX}/${assetId}/${ASSETS_DETAILS_WITH_METADATA_SUFFIX}`
    )
  );

  return await Promise.all<{ data: IAssetDetailWithMetadata }>(
    endpoints.map((endpoint) => axiosInstance.get(endpoint, { signal: signal }))
  );
}

export async function updateBulkAsset(
  assetPatchDocuments: {
    patchDocumentsAssets: Record<string, IJsonPatchOperation[]>;
  },
  signal?: AbortSignal
): Promise<{
  data: (IAssetBatchResultSuccess | IAssetBatchResultError)[];
  status: number;
}> {
  return await axiosInstance.patch(ASSETS_BULK, assetPatchDocuments, {
    signal: signal
  });
}

export function getAssetDownloadUrl(assetId: string) {
  return `${process.env.REACT_APP_API_URL}/${ASSETS_PREFIX}/${assetId}/download`;
}

export async function getAssetsThumbnails(
  assetIds: string[],
  signal?: AbortSignal
): Promise<{ data: IAssetIdAndThumbnailInfo[] }> {
  return await axiosInstance.get(`${ASSETS_THUMBNAILS}`, {
    params: {
      id: assetIds
    },
    signal: signal
  });
}

export async function replaceAssetThumbnail(
  props: {
    assetId: string;
    thumbnailFormData: FormData;
  },
  signal?: AbortSignal
): Promise<{
  data: Partial<IAssetDetailWithMetadata> &
    Required<Pick<IAssetDetailWithMetadata, 'thumbnailSrc'>>;
}> {
  return await axiosInstance.post(
    `${ASSETS_PREFIX}/${props.assetId}/${ASSETS_THUMBNAIL_SUFFIX}`,
    props.thumbnailFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      signal: signal
    }
  );
}

export async function resetAssetThumbnail(
  props: { assetId: string; lastModified?: string },
  signal?: AbortSignal
): Promise<{
  data: Partial<IAssetDetailWithMetadata>;
}> {
  const url = `${ASSETS_PREFIX}/${props.assetId}/${ASSETS_THUMBNAIL_SUFFIX}${
    props.lastModified ? `?lastModified=${props.lastModified}` : ''
  }`;

  return await axiosInstance.delete(url, {
    signal: signal
  });
}

export async function deleteAssetTempVersion(
  assetId: string,
  signal?: AbortSignal
) {
  return await axiosInstance.delete(
    `${ASSETS_PREFIX}/${assetId}/${ASSETS_VERSION_SUFFIX}`,
    {
      signal: signal
    }
  );
}

export const postNewAssetVersion = async (
  props: {
    assetId: string;
    properties: Record<string, string>;
    comment?: string;
  },
  signal?: AbortSignal
): Promise<{ data: IAssetDetailWithMetadata }> => {
  const jsonProps = JSON.stringify({
    comment: props.comment ?? '',
    properties: props.properties
  });
  return await axiosInstance.post(
    `${ASSETS_PREFIX}/${props.assetId}/${ASSETS_VERSION_SUFFIX}`,
    jsonProps,
    {
      signal: signal
    }
  );
};

export async function getAssetProhibitedRules(
  controller?: AbortController
): Promise<{
  data: IAssetProhibitedRules;
}> {
  return await axiosInstance.get(ASSETS_PROHIBITED_RULES, {
    signal: controller?.signal
  });
}
