// IMPORTANT This is a Static "Class" the _uppy and _uploadSession are the same when the method are call

import Uppy, { IndexedObject, State, UppyFile } from '@uppy/core';
import Tus from '@uppy/tus';
import { HttpRequest } from 'tus-js-client';
import { deleteAsset } from 'src/app/api/controllers/assets';
import { useAuth } from '../stores';
import { useUpload } from '../stores/upload/uploadStore';
import { removeTrailingSlash } from './string';

let _uppy: Uppy | null = null;

export const UPPY_MAX_FILE_SIZE = 128 * 1024 * 1024 * 1024; //128 Gb

const generateUppy = () => {
  return new Uppy({
    autoProceed: true,
    onBeforeFileAdded: (currentFile) => ({
      ...currentFile,
      meta: {
        ...currentFile.meta,
        contentType: currentFile.type || 'application/octet-stream'
      }
    }),
    restrictions: {
      maxFileSize: UPPY_MAX_FILE_SIZE
    }
  });
};

export const getUppy = (assetId?: string) => {
  if (_uppy === null) {
    _uppy = generateUppy();
    _uppy.use(Tus, getTusOptions(assetId));
  }

  return _uppy as Uppy;
};

const getTusOptions = (assetId?: string) => {
  return {
    limit: 3,
    removeFingerprintOnSuccess: true,
    endpoint: `${removeTrailingSlash(
      process.env.REACT_APP_API_URL ?? ''
    )}/api/v1/assets/uploads`,
    chunkSize: 52_428_800, // 50 MB
    async onBeforeRequest(req: HttpRequest) {
      const token = await useAuth.getState().getIdTokenWithRefresh();
      req.setHeader('Authorization', `Bearer ${token}`);

      if (assetId) {
        req.setHeader('asset-id', assetId);
      } else {
        const uploadSessionId = useUpload.getState().uploadSessionId;
        if (!uploadSessionId) {
          throw new Error('Upload Session Id is undefined');
        }

        req.setHeader('upload-session', uploadSessionId as string);
      }
    }
  };
};

export const cleanupUppy = () => {
  _uppy?.cancelAll();
  _uppy?.close();
  if (useUpload.getState().uploadSessionId)
    useUpload.getState().setUploadingSessionId(undefined);
  _uppy = null;
};

//data contains the original file name
//name contains the renamed file name
export const addFilesToUppy = (
  filesList: { name: string; type: string; data: File; isDuplicate: boolean }[]
) => {
  filesList.forEach((file) => {
    try {
      getUppy().addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file.data,
        meta: {
          originalFilename: file.data.name,
          isDuplicate: file.isDuplicate
        }
      });
    } catch (err) {
      console.error(err);
    }
  });
};

export const handleRemoveFile = (assetId: string, uploadId?: string) => {
  return new Promise<void>((resolve, reject) => {
    if (uploadId) {
      deleteAsset(uploadId)
        .then(() => {
          getUppy().removeFile(assetId, 'removed-by-user');
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    } else {
      getUppy().removeFile(assetId, 'removed-by-user');
      resolve();
    }
  });
};

export type UppyFileType = UppyFile<
  Record<string, unknown>,
  Record<string, unknown>
>;

export const getFileUploadId = (file: UppyFileType) => {
  const uploadURL = file.response?.uploadURL;
  const split = uploadURL?.split('/');
  const uploadId = split ? split[split?.length - 1] : undefined;
  return uploadId;
};

export const isUppyValid = () => {
  const uppyState: State<IndexedObject<unknown>> = getUppy().getState();
  let hasUncompleted = false;
  Object.values(uppyState.files).forEach((file) => {
    if (!file.progress?.uploadComplete) {
      hasUncompleted = true;
    }
  });
  return (
    !hasUncompleted && !uppyState.error && getUppy().getFiles().length !== 0
  );
};

export enum UppyEventsEnum {
  FILES_ADDED = 'files-added',
  FILE_REMOVED = 'file-removed',
  COMPLETE = 'complete',
  UPLOAD_PROGRESS = 'upload-progress', //for individual files
  PROGRESS = 'progress', //for total upload progress
  CANCEL_ALL = 'cancel-all'
}
