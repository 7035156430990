import { produce } from 'immer';
import { getAssetsThumbnails } from 'src/app/api/controllers/assets';
import { IAssetIdAndThumbnailInfo } from 'src/app/api/models/assets';
import { guidToUploadId } from 'src/app/utilities/string';
import { IErrors } from 'src/app/utilities/yup';
import { StateCreator } from 'zustand';
import { uploadResetters } from '../uploadStore';

//need to declare initial state like this to be able to reset it
const uploadThumbnailInitialState = {
  thumbnailsInfo: [],
  thumbnailErrors: {}
};

export interface IThumbnailSlice {
  thumbnailsInfo: IAssetIdAndThumbnailInfo[];
  thumbnailErrors: Record<string, IErrors>;
  getThumbnailsInfo: (ids: string[]) => void;
  setThumbnailErrors: (assetIndex: number, errors: IErrors | undefined) => void;
}

export const createThumbnailSlice: StateCreator<IThumbnailSlice> = (set) => {
  uploadResetters.push(() => set(uploadThumbnailInitialState));

  return {
    ...uploadThumbnailInitialState,
    getThumbnailsInfo: async (ids: string[]) => {
      getAssetsThumbnails(ids)
        .then((response) => {
          set(() => ({
            thumbnailsInfo: response.data.map((t) => ({
              id: guidToUploadId(t.id),
              thumbnailSrc: t.thumbnailSrc,
              hasCustomThumbnail: t.hasCustomThumbnail
            }))
          }));
        })
        .catch((error) => {
          console.error(error);
          set(() => ({ thumbnailsInfo: [] }));
        });
    },
    setThumbnailErrors: (assetIndex: number, errors: IErrors | undefined) => {
      set(
        produce((state) => {
          state.thumbnailErrors[assetIndex] = errors;
        })
      );
    }
  };
};
