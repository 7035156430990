import { useContext } from 'react';
import { TenantContext } from './TenantProvider';
import {
  ICustomizationInfo,
  ITenantInfoWithBranding
} from 'src/app/api/models/tenants';

export const useTenant = () => {
  const { tenant, setTenant, refreshCurrentTenantAndBrandImages } =
    useContext(TenantContext);

  /**
   * Set the Customization Info to the Tenant Context while in the Share Page
   * @param customization
   */
  const setShareTenant = (customization: ICustomizationInfo) => {
    setTenant({
      ...customization
    } as ITenantInfoWithBranding);
  };

  return {
    tenant,
    setShareTenant,
    refreshTenant: refreshCurrentTenantAndBrandImages
  };
};
