import { ROUTES } from '../routes/Router';
import { IRoute } from '../routes/Routes';
import { Language } from './i18n';

interface IlocaleRouteFromUrl {
  url: string;
  locale: Language;
}

export const getLocaleRouteFromUrl = ({
  url,
  locale
}: IlocaleRouteFromUrl): string => {
  let newRoute: string | undefined = undefined;

  for (const routes of Object.values(ROUTES)) {
    routes.forEach((route: IRoute) => {
      const { paths } = route;

      for (const path of Object.values(paths)) {
        if (path === url) {
          newRoute = paths[locale];
        }
      }
    });
  }

  // Return the new route if it exists, otherwise return the original url
  return newRoute ?? url;
};
