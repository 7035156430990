import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledTabs: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 'unset',
        '.MuiTabs-flexContainer': {
          gap: theme.spacing(2)
        },
        '.MuiTabs-indicator': {
          backgroundColor: theme.vars.palette.secondary.main,
          height: '0.125rem',
          borderRadius: '0.063rem'
        },
        '.MuiTabs-pillContainer &': {
          gap: 0,

          '.MuiTabs-flexContainer': {
            gap: 0
          },

          '.MuiTabs-indicator': {
            display: 'none'
          }
        }
      })
    }
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 'unset',
        textTransform: 'none',
        minWidth: 'unset',
        minHeight: 'unset',
        lineHeight: '1.57',
        fontWeight: '400',
        '&.Mui-selected': {
          color: theme.vars.palette.text.primary,
          fontWeight: 600
        },
        '&:focus': {
          outline: 'none !important'
        },
        '&.MuiTabs-pill': {
          padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
          color: theme.vars.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          lineHeight: '140%',
          fontSize: '1rem',
          borderRadius: theme.shape.borderRadius,

          '&.Mui-selected': {
            backgroundColor: theme.vars.palette.primary.shades[1],
            border: `1px solid ${theme.vars.palette.primary.shades[2]}`,
            underline: 'none',
            fontWeight: 600
          }
        }
      })
    }
  }
};
