import { lazy } from 'react';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';
import { IPaths } from 'src/app/routes/IPath';
import { EPageLocation } from 'src/app/routes/pageLocation';
import { IRoute, IRouteWithLoader } from 'src/app/routes/Routes';
import { EN_US, ES_ES, FR_CA } from 'src/app/utilities';
import { loadSingleAsset } from './loader';
import { Claim } from 'src/app/utilities/claims';

const viewAssetsComponent = lazy(() => import('./view/ViewAsset'));
const editAssetsComponent = lazy(() => import('./edit/EditAsset'));
const uploadAssetsComponent = lazy(() => import('./upload/Upload'));
const aiReviewAssetsComponent = lazy(
  () => import('./aiReview/AIReviewElements')
);

const viewAssetsPaths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__assets_slug}/`,
  'es-ES': `/${ES_ES}/${esES.routes__assets_slug}/`,
  'fr-CA': `/${FR_CA}/${frCA.routes__assets_slug}/`
};

const editAssetsPaths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__edit_page_slug}/`,
  'es-ES': `/${ES_ES}/${esES.routes__edit_page_slug}/`,
  'fr-CA': `/${FR_CA}/${frCA.routes__edit_page_slug}/`
};

const uploadAssetsPaths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__upload_page_slug}/:id`,
  'es-ES': `/${ES_ES}/${esES.routes__upload_page_slug}/:id`,
  'fr-CA': `/${FR_CA}/${frCA.routes__upload_page_slug}/:id`
};

//TODO in https://datacmcorp.visualstudio.com/ASMBL/_workitems/edit/5232
const aiReviewAssetsPaths: IPaths = {
  'en-US': `/${EN_US}/${enUS.routes__ai_review}/`,
  'es-ES': `/${ES_ES}/${esES.routes__ai_review}/`,
  'fr-CA': `/${FR_CA}/${frCA.routes__ai_review}/`
};

const viewAssetsLocation = EPageLocation.assets;
const editAssetsLocation = EPageLocation.assets;
const uploadAssetsLocation = EPageLocation.upload;
const aiReviewAssetsLocation = EPageLocation.assets;

export const viewAssetsRoute: IRouteWithLoader = {
  component: viewAssetsComponent,
  paths: viewAssetsPaths,
  location: viewAssetsLocation,
  loader: loadSingleAsset
};

export const editAssetsRoute: IRouteWithLoader = {
  component: editAssetsComponent,
  paths: editAssetsPaths,
  location: editAssetsLocation,
  loader: loadSingleAsset, //we load the first asset, to avoid a big loading time, the rest will boe loaded once in the page
  requiredClaims: [Claim.Asset.Update]
};

export const uploadAssetsRoute: IRoute = {
  component: uploadAssetsComponent,
  paths: uploadAssetsPaths,
  location: uploadAssetsLocation,
  requiredClaims: [Claim.Asset.Create]
};

export const aiReviewAssetsRoute: IRoute = {
  component: aiReviewAssetsComponent,
  paths: aiReviewAssetsPaths,
  location: aiReviewAssetsLocation,
  requiredClaims: [Claim.Asset.Update]
};
