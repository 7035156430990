import { Language } from 'src/app/utilities';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export enum EAuthenticationActionType {
  initialize = 'INITIALIZE',
  login = 'LOGIN',
  logout = 'LOGOUT'
}

export interface IAuthenticationState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: CognitoUserSession;
  login(): void;
  logout(): void;
}

export const AUTHENTICATION_INITIAL_STATE: IAuthenticationState = {
  isLoading: true,
  isAuthenticated: false,
  user: undefined,
  login: () => Promise.resolve(Language.enUS),
  logout: () => undefined
};

export interface IAuthenticationReducerPayload {
  user?: CognitoUserSession;
}

export type TAuthenticationAction =
  | {
      type: EAuthenticationActionType.initialize;
      payload: IAuthenticationReducerPayload;
    }
  | {
      type: EAuthenticationActionType.login;
      payload: IAuthenticationReducerPayload;
    }
  | { type: EAuthenticationActionType.logout };

export const authenticationReducer = (
  state: IAuthenticationState,
  action: TAuthenticationAction
) => {
  switch (action.type) {
    case EAuthenticationActionType.initialize:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: !!action.payload.user,
        user: action.payload.user
      };
    case EAuthenticationActionType.logout:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        user: undefined
      };
    case EAuthenticationActionType.login:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: !!action.payload.user,
        user: action.payload.user
      };
    default:
      return state;
  }
};
