import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledAppBar: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.vars.palette.background.paper,
        borderRadius: '0.5rem',
        padding: theme.spacing(2.5),
        color: theme.vars.palette.text.primary,
        gap: 0,
        '.app-bar__decorative-squares': {
          // bottom position has to be the same as the appBar bottom padding
          bottom: theme.spacing(3)
        }
      })
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: theme.spacing(8),
        padding: 0
      })
    }
  }
};
