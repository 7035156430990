import { Components, CssVarsTheme, Theme } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledTable: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiTableCell: {
    styleOverrides: {
      head: ({ theme }) => ({
        backgroundColor:
          theme.palette.mode === DEFAULT_COLOR_MODE
            ? theme.vars.palette.background.default
            : theme.vars.palette.primary.shades[0]
      }),
      stickyHeader: () => ({ top: '-1px' }) //fix to make sure NO table body content is visible above the table head when scrolling
    }
  }
};
