import { ContainerProps, Container, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import './copyright.scss';
import { Logo } from '../brandImages/Logo';
import { CustomIcons } from '../icons/CustomIcons';

export interface ICopyright extends ContainerProps {
  isFooter?: boolean;
}

export const Copyright = ({ isFooter, ...props }: ICopyright) => {
  const [t] = useTranslation();

  return (
    <Container
      {...(isFooter && { component: 'footer' })}
      {...props}
      className={clsx('copyright__wrapper', props.className)}
    >
      <span className="copyright__logo">
        <Logo isAlwaysASMBLLogo height="1rem" />
      </span>

      <Typography variant={'caption'}>&copy;2020 - 2024</Typography>
      <Typography variant={'caption'}>
        {t('general__copyright_all_rights_reserved')}
      </Typography>
      <Link
        component={RouterLink}
        to={t('general__copyright_external_link')}
        variant="caption"
        target="_blank"
        rel="noreferrer"
        className={'link-external'}
      >
        <CustomIcons iconName="external-link" />
        DCM
      </Link>
    </Container>
  );
};
