import {
  AssetPropertiesEnum,
  AssetProperty,
  IUploadAsset,
  IAssetTypes,
  ASSET_GROUPS,
  AssetGroups
} from 'src/app/api/models/assets';
import {
  DEFAULT_CONTENT_CREATOR,
  DEFAULT_PAGE_COUNT,
  DEFAULT_RESOLUTION,
  DIMENSION_SEPARATOR
} from './constants';
import {
  readImage,
  readPdf,
  readVideoDuration,
  readVideoSampleRate
} from './files';
import { formatSeconds } from './string';
import prettyBytes from 'pretty-bytes';
import { getFileUploadId, getUppy } from './uppy';
import { IVisibilityGroup } from 'src/app/api/models/users';
import { TFunction } from 'react-i18next';
import { NestedKeyOf } from './utilityTypes';
import { IErrors } from './yup';
import { validatePropertyInAssets } from '../pages/assets/upload/uploadForm/upload-form-validation';
import { AssetGroupsMetadataList } from '../api/controllers/metadata';
import { SearchAssetTypeFiltersEnum } from '../api/models/search';
import { IAssetDetailWithMetadata } from '../api/models/assetDetailWithMetadata';
import {
  ASSET_AI_MODULES,
  IAssetAIContentBE,
  IAssetAIContentSectionProps,
  TAssetAIContent,
  TAssetAIModules
} from '../api/models/assetsAI';

export enum EAssetIcon {
  AUDIO = 'audio',
  CREATIVE_FILE = 'creative',
  DOCUMENT = 'file-document',
  IMAGE = 'image',
  PRESENTATION = 'presentation',
  VIDEO = 'video',
  OTHER = 'other'
}

export const getTranslationKeyForAssetProperty = (
  prop?: AssetPropertiesEnum
) => {
  switch (prop) {
    case AssetPropertiesEnum.KIND:
      return 'upload__properties_kind';
    case AssetPropertiesEnum.SIZE:
      return 'upload__properties_size';
    case AssetPropertiesEnum.DIMENSIONS:
      return 'upload__properties_dimensions';
    case AssetPropertiesEnum.DURATION:
      return 'upload__properties_duration';
    case AssetPropertiesEnum.SAMPLE_RATE:
      return 'upload__properties_sample_rate';
    case AssetPropertiesEnum.PAGES:
      return 'upload__properties_pages';
    case AssetPropertiesEnum.RESOLUTION:
      return 'upload__properties_resolution';
    case AssetPropertiesEnum.CONTENT_CREATOR:
      return 'upload__properties_content_creator';
    case AssetPropertiesEnum.COLOR_SPACE:
      return 'upload__properties_color_space';
    case AssetPropertiesEnum.HAS_TRANSPARENCY:
      return 'upload__properties_has_transparency';
    default:
      return '';
  }
};

export const getAssetGroupKeyFromExtension = (
  assetTypes: IAssetTypes,
  extension: string
): keyof typeof ASSET_GROUPS => {
  return assetTypes.extensions[`.${extension.toLowerCase()}`]?.group ?? 'Other';
};

export const getAssetGroupNameFromExtension = (
  assetTypes: IAssetTypes,
  extension: string
): AssetGroups => {
  return ASSET_GROUPS[getAssetGroupKeyFromExtension(assetTypes, extension)]
    .groupName;
};

export const getAssetPropertiesFromExtension = (
  assetTypes: IAssetTypes,
  extension: string
): AssetProperty[] => {
  const props: AssetProperty[] = [];
  assetTypes.properties.forEach((p) => {
    if (!p.uncommonProperties) props.push(p);
  });
  assetTypes.extensions[
    '.' + extension.toLowerCase()
  ]?.uncommonProperties?.forEach((uP) => {
    const property = assetTypes.properties.find((p) => p.id === uP);
    if (property) props.push(property);
  });
  return props;
};

export const getAssetPropertiesForUploadView = (
  possibleProps: AssetProperty[],
  assetProps: Record<string, string>,
  t: TFunction<'translation', undefined>
): {
  label: string;
  value: string;
}[] => {
  return Object.entries(assetProps).flatMap(([propId, value]) => {
    const assetProperty: AssetProperty | undefined = possibleProps.find(
      (prop) => prop.id === propId
    );
    if (!assetProperty) return [];
    const assetPropKey = assetProperty.propKey;
    return {
      label: t(getTranslationKeyForAssetProperty(assetPropKey)),
      value: value
    };
  });
};

export const getAssetPropertiesForUpload = (
  assetTypes: IAssetTypes
): Promise<Record<string, Record<string, string>>> => {
  return new Promise<Record<string, Record<string, string>>>((resolve) => {
    const assetPropsObj: Record<string, Record<string, string>> = {};
    const promises: Promise<unknown>[] = [];

    //build assetPropsObj
    getUppy()
      .getFiles()
      .forEach((file) => {
        const fileUploadId = getFileUploadId(file);
        if (fileUploadId) {
          if (!assetPropsObj[fileUploadId]) assetPropsObj[fileUploadId] = {};
          const assetPropsArray = getAssetPropertiesFromExtension(
            assetTypes,
            file.extension
          );
          assetPropsArray.forEach((prop) => {
            switch (prop.propKey) {
              case AssetPropertiesEnum.KIND:
                assetPropsObj[fileUploadId][prop.id] =
                  file.extension.toUpperCase();
                break;
              case AssetPropertiesEnum.SIZE:
                assetPropsObj[fileUploadId][prop.id] = prettyBytes(file.size);
                break;
              case AssetPropertiesEnum.DIMENSIONS:
                promises.push(
                  readImage(file.data).then((imgProps) => {
                    assetPropsObj[fileUploadId][prop.id] =
                      imgProps.width + DIMENSION_SEPARATOR + imgProps.height;
                  })
                );
                break;
              case AssetPropertiesEnum.DURATION:
                promises.push(
                  readVideoDuration(file.data).then((data) => {
                    assetPropsObj[fileUploadId][prop.id] = formatSeconds(
                      data.duration
                    ).toString();
                  })
                );
                break;
              case AssetPropertiesEnum.SAMPLE_RATE:
                promises.push(
                  readVideoSampleRate(file.data).then((data) => {
                    assetPropsObj[fileUploadId][prop.id] =
                      Math.round(data.sampleRate / 1000).toString() + ' kHz';
                  })
                );
                break;
              case AssetPropertiesEnum.PAGES:
              case AssetPropertiesEnum.RESOLUTION:
              case AssetPropertiesEnum.CONTENT_CREATOR:
                {
                  const pagesId = assetPropsArray.find(
                    (apa) => apa.propKey === AssetPropertiesEnum.PAGES
                  )?.id;
                  const resolutionId = assetPropsArray.find(
                    (apa) => apa.propKey === AssetPropertiesEnum.RESOLUTION
                  )?.id;
                  const contentCreatorId = assetPropsArray.find(
                    (apa) => apa.propKey === AssetPropertiesEnum.CONTENT_CREATOR
                  )?.id;
                  if (
                    pagesId &&
                    !assetPropsObj[fileUploadId][pagesId] &&
                    resolutionId &&
                    !assetPropsObj[fileUploadId][resolutionId] &&
                    contentCreatorId &&
                    !assetPropsObj[fileUploadId][contentCreatorId]
                  ) {
                    assetPropsObj[fileUploadId][pagesId] = DEFAULT_PAGE_COUNT;
                    assetPropsObj[fileUploadId][resolutionId] =
                      DEFAULT_RESOLUTION;
                    assetPropsObj[fileUploadId][contentCreatorId] =
                      DEFAULT_CONTENT_CREATOR;
                    promises.push(
                      readPdf(file.data)
                        .then((pdf) => {
                          if (pagesId)
                            assetPropsObj[fileUploadId][pagesId] =
                              pdf.pages.toString();
                          if (resolutionId)
                            assetPropsObj[fileUploadId][resolutionId] =
                              pdf.resolution;
                          if (contentCreatorId)
                            assetPropsObj[fileUploadId][contentCreatorId] =
                              pdf.contentCreator;
                        })
                        .catch((e) => console.error(e))
                    );
                  }
                }
                break;
            }
          });
        }
      });

    Promise.all(promises).then(() => {
      resolve(assetPropsObj);
    });
  });
};

export const patchAssetsFromUppy = (
  assets: IUploadAsset[],
  userAssetCollections: IVisibilityGroup[],
  assetPropsObj: Record<string, Record<string, string>>,
  assetTypes: IAssetTypes
): IUploadAsset[] => {
  return getUppy()
    .getFiles()
    .map((file) => {
      const fileUploadId = getFileUploadId(file);
      const ofn = file.meta.originalFilename as string;
      const ofnNoExt = ofn.replace(/\.[^/.]+$/, '');
      let asset: IUploadAsset = {
        id: fileUploadId ?? '',
        name: ofnNoExt,
        extension: file.extension,
        originalName: ofnNoExt,
        assetTypeCode: getAssetGroupKeyFromExtension(
          assetTypes,
          file.extension.replaceAll('.', '')
        ),
        isDuplicate:
          typeof file.meta.isDuplicate === 'boolean'
            ? file.meta.isDuplicate
            : false,
        metadata: {},
        manualKeywords: [],
        catalogId:
          userAssetCollections.length === 1 ? userAssetCollections[0].id : '',
        properties: fileUploadId ? assetPropsObj[fileUploadId] : {}
      };
      assets.forEach((a) => {
        if (a.id === fileUploadId) {
          asset = a;
        }
      });
      return asset;
    });
};

export const getFileExtension = (fileName: string): string => {
  return fileName.split('.').pop() || '';
};

export const checkPropertyErrorsInAssets = (
  property: NestedKeyOf<IUploadAsset>,
  assetTypes: IAssetTypes,
  metadata: AssetGroupsMetadataList,
  t: TFunction<'translation', undefined>,
  errors: Record<string, string[]>,
  ...assets: IUploadAsset[]
): IErrors => {
  const errorKeys = assets.map((asset) => `${asset.id}.${property}`);

  const fieldValidationErrors = validatePropertyInAssets(
    assetTypes,
    metadata,
    t,
    property,
    ...assets
  );

  const remainingErrors = Object.fromEntries(
    Object.entries(errors).filter(([key]) => !errorKeys.includes(key))
  );
  return { ...remainingErrors, ...fieldValidationErrors };
};

export const matchIconWithGroup = (group: string) => {
  switch (group?.toLowerCase()) {
    case SearchAssetTypeFiltersEnum.AUDIO:
      return EAssetIcon.AUDIO;
    case SearchAssetTypeFiltersEnum.CREATIVE_FILE.toLowerCase():
      return EAssetIcon.CREATIVE_FILE;
    case SearchAssetTypeFiltersEnum.DOCUMENT:
      return EAssetIcon.DOCUMENT;
    case SearchAssetTypeFiltersEnum.IMAGE:
      return EAssetIcon.IMAGE;
    case SearchAssetTypeFiltersEnum.PRESENTATION:
      return EAssetIcon.PRESENTATION;
    case SearchAssetTypeFiltersEnum.VIDEO:
      return EAssetIcon.VIDEO;
    default:
      return EAssetIcon.OTHER;
  }
};

const getFormattedPropValue = (
  propVal: string,
  t: TFunction<'translation', undefined>,
  propKey?: AssetPropertiesEnum
): string => {
  switch (propKey) {
    case AssetPropertiesEnum.KIND:
      return propVal.toUpperCase();
    case AssetPropertiesEnum.DIMENSIONS:
      return propVal.replace('X', DIMENSION_SEPARATOR);
    case AssetPropertiesEnum.RESOLUTION:
      return propVal || t('upload__properties_resolution_not_detected');
    case AssetPropertiesEnum.COLOR_SPACE:
      return propVal || t('upload__properties_color_space_not_detected');
    case AssetPropertiesEnum.HAS_TRANSPARENCY:
      if (propVal.toLowerCase() === 'true') {
        return t('general__yes');
      } else if (propVal.toLowerCase() === 'false') {
        return t('general__no');
      } else {
        return t('upload__properties_value_not_detected');
      }
    default:
      return propVal || t('upload__properties_value_not_detected');
  }
};

export const getAssetPropertyId = (
  allProperties: AssetProperty[],
  propKey: AssetPropertiesEnum
): string => {
  const propId = allProperties.find((prop) => prop.propKey === propKey)?.id;
  return propId || '';
};

export const formatAssetProperties = (
  asset: IAssetDetailWithMetadata,
  allPossibleProperties: AssetProperty[],
  t: TFunction<'translation', undefined>
) => {
  const formattedAssetProperties = Object.entries(asset.properties).map(
    ([propId, propValue]) => {
      const assetPropertyKey: AssetPropertiesEnum | undefined =
        allPossibleProperties.find((prop) => prop.id === propId)?.propKey;

      return {
        label: t(getTranslationKeyForAssetProperty(assetPropertyKey)),
        value: getFormattedPropValue(propValue, t, assetPropertyKey)
      };
    }
  );
  return formattedAssetProperties;
};

export const mapAssetAIContentBEToFE = (
  aiContentBE: IAssetAIContentBE
): TAssetAIContent => {
  const mappedAIContent: TAssetAIContent = Object.entries(aiContentBE).reduce(
    (allMappedAIContent, [sectionKey, sectionContent]) => {
      if (!sectionKey || sectionContent.length < 1) return allMappedAIContent;

      return {
        ...allMappedAIContent,
        [sectionKey as TAssetAIModules]: {
          aiValues: sectionContent
        } as IAssetAIContentSectionProps
      };
    },
    {}
  );

  return mappedAIContent;
};

export const hasSomeAIContent = (aiContent?: IAssetAIContentBE): boolean => {
  if (!aiContent) return false;
  return Object.values(aiContent).some((sectionContent) => {
    return sectionContent && sectionContent.length > 0;
  });
};

export const orderAIContent = (
  aiContent: IAssetAIContentBE
): IAssetAIContentBE => {
  const orderedAIContent = [];
  for (const [aiModuleKey, aiModuleContent] of Object.entries(aiContent)) {
    const moduleIndex = Object.values(ASSET_AI_MODULES).findIndex(
      (aiModule) => aiModule === aiModuleKey
    );

    orderedAIContent[moduleIndex] = [aiModuleKey, aiModuleContent];
  }

  const filteredAIContent = orderedAIContent.filter((aiContent) => aiContent);

  const orderedAIContentObj = Object.fromEntries(filteredAIContent);
  return orderedAIContentObj;
};
