import { alertClasses, Components, CssVarsTheme, Theme } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledAlert: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiAlert: {
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const paletteToApply =
          theme.vars.palette[ownerState.severity || 'success'];
        return {
          pointerEvents: 'auto',
          padding: theme.spacing(0.75, 2),
          boxShadow: theme.shadows[1],
          color: paletteToApply.dark,
          [`.${alertClasses.icon}`]: {
            margin: 0,
            color:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? paletteToApply.main
                : paletteToApply.contrastText
          },

          ...(ownerState.variant === 'standard' && {
            backgroundColor: paletteToApply.light
          }),
          ...(ownerState.variant === 'outlined' && {
            borderColor: paletteToApply.main
          }),
          ...(ownerState.variant === 'filled' && {
            color: paletteToApply.contrastText,
            [`.${alertClasses.icon}`]: {
              color: paletteToApply.contrastText
            },
            backgroundColor: paletteToApply.main
          }),
          ...(ownerState.severity === 'error' && {
            color:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? paletteToApply.shades[1]
                : paletteToApply.contrastText,
            backgroundColor:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? paletteToApply.shades[0]
                : paletteToApply.main
          })
        };
      },
      message: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        maxWidth: '17.5rem',
        ul: {
          margin: theme.spacing(0.5, 0),
          paddingLeft: theme.spacing(2.5)
        },
        button: {
          color: 'inherit'
        }
      }),
      action: ({ theme }) => {
        return {
          height: '100%',
          paddingTop: '0',
          '.close-btn': {
            backgroundColor: 'inherit',
            border: '0',
            color: 'inherit',
            padding: theme.spacing(0.5),
            '&:hover': {
              color: 'inherit',
              backgroundColor: 'inherit'
            }
          }
        };
      }
    }
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: theme.typography.subtitle1.fontWeight
      })
    }
  }
};
