import { PDFDocument } from 'pdf-lib';
import {
  BYTES_TO_MEGABYTES_DIVIDER,
  DEFAULT_CONTENT_CREATOR,
  DEFAULT_RESOLUTION,
  DIMENSION_SEPARATOR
} from './constants';
import { UPPY_MAX_FILE_SIZE } from './uppy';
import { TFunction } from 'react-i18next';
import { IErrors, getSchemaErrors } from './yup';
import { number, object, string } from 'yup';

export interface IImgFileProps {
  width: number;
  height: number;
  src: string;
}

export interface IFileValidationRules {
  maxFileSize?: number;
  acceptedExtensions?: string[];
}

export const readImage = (file: File | Blob): Promise<IImgFileProps> => {
  return new Promise((resolve) => {
    try {
      //https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications#example_showing_thumbnails_of_user-selected_images
      //https://developer.mozilla.org/en-US/docs/Web/API/FileReader
      const reader = new FileReader();

      reader.onload = function (e) {
        if (
          typeof e.target?.result === 'string' &&
          e.target.result !== 'data:'
        ) {
          const img = new Image();

          img.onload = () => {
            resolve({
              width: img.width,
              height: img.height,
              src: e.target?.result as string
            });
          };
          img.onerror = () => {
            resolve({ width: 0, height: 0, src: '' });
          };

          img.src = e.target?.result;
        } else {
          resolve({ width: 0, height: 0, src: '' });
        }
      };

      reader.readAsDataURL(file);
    } catch (err) {
      resolve({ width: 0, height: 0, src: '' });
    }
  });
};

export const readVideoDuration = (
  file: File | Blob
): Promise<{ duration: number }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      resolve({ duration: video.duration });
    };
    video.onerror = () => {
      resolve({ duration: 0 });
    };

    video.src = window.URL.createObjectURL(file);
  });
};

export const readVideoSampleRate = (
  file: File | Blob
): Promise<{ sampleRate: number }> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      const buffer = reader.result;
      const AudioContext = window.AudioContext;
      const context = new AudioContext();
      if (buffer && typeof buffer !== 'string') {
        context
          .decodeAudioData(buffer, function (buffer1) {
            resolve({ sampleRate: buffer1.sampleRate });
          })
          .catch(() => {
            resolve({ sampleRate: 0 });
          });
      }
    };
  });
};

export const readPdf = (
  file: File | Blob
): Promise<{ pages: number; resolution: string; contentCreator: string }> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      if (e.target?.result && typeof e.target?.result !== 'string') {
        PDFDocument.load(e.target?.result, {
          updateMetadata: false
        })
          .then((pdf) => {
            const resolution =
              pdf.getPage(0).getHeight() +
              DIMENSION_SEPARATOR +
              pdf.getPage(0).getWidth();
            const contentCreator = pdf.getProducer();
            const pages = pdf.getPageCount();
            resolve({
              resolution: resolution ? resolution : DEFAULT_RESOLUTION,
              contentCreator: contentCreator
                ? contentCreator
                : DEFAULT_CONTENT_CREATOR,
              pages: pages
            });
          })
          .catch(() => {
            resolve({
              pages: 0,
              resolution: '0' + DIMENSION_SEPARATOR + '0',
              contentCreator: ''
            });
          });
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

export const validateAndSpliceFiles = (
  files: File[],
  items: (FileSystemEntry | null)[]
) => {
  const areTheseValidFiles: (boolean | undefined)[] = [];

  for (let i = 0, item; (item = items[i]); i++) {
    areTheseValidFiles.push(
      item?.isFile && !item?.isDirectory && files[i].size <= UPPY_MAX_FILE_SIZE
    );
  }

  let amountSpliced = 0;
  let containsErrors = false;
  areTheseValidFiles.forEach((isThisAValidFile, index) => {
    if (isThisAValidFile !== true) {
      files.splice(index - amountSpliced, 1);
      amountSpliced += 1;
      containsErrors = true;
    }
  });

  return { containsErrors, files };
};

export const getImgAcceptedTypesTxt = (
  acceptedTypesArray: string[],
  t: TFunction<'translation', undefined>
): string => {
  let acceptedFileTypesTxt = '';
  if (acceptedTypesArray.length === 2) {
    //add 'or' between the 2 formats
    acceptedFileTypesTxt = acceptedTypesArray.join(
      ` ${t('general__file_formats_separator')} `
    );
  } else if (acceptedTypesArray.length > 2) {
    //add ',' between the 3 or more formats and replace last comma by 'or'.
    acceptedFileTypesTxt = acceptedTypesArray
      .join(', ')
      .replace(/,(?=[^,]*$)/, ` ${t('general__file_formats_separator')}`);
  } else {
    acceptedFileTypesTxt = acceptedTypesArray.toString();
  }

  return acceptedFileTypesTxt;
};

export const getImgDimensionsTxt = (
  maxWidth: number,
  maxHeight: number,
  t: TFunction<'translation', undefined>
): string => {
  return `${t('general__file_img_dimensions', {
    width: maxWidth,
    height: maxHeight
  })}`;
};

export const validateSameFileExtension = (
  t: TFunction<'translation', undefined>,
  fileExtension: string,
  extensionToMatch: string
): IErrors | undefined => {
  const sameExtensionValidationSchema = object({
    extension: string()
      .defined()
      .test(
        'is-same-file-extension',
        t('asset__detail_add_new_version_extension_error'),
        (ext) => {
          return ext === extensionToMatch;
        }
      )
  });

  let fileErrors: IErrors = {};

  fileErrors = getSchemaErrors(sameExtensionValidationSchema, {
    extension: fileExtension
  });

  if (Object.keys(fileErrors).length > 0) {
    return fileErrors;
  } else {
    return undefined;
  }
};

export const validateFileSize = (
  t: TFunction<'translation', undefined>,
  fileSize: number,
  maxFileSize = UPPY_MAX_FILE_SIZE
): IErrors | undefined => {
  const fileSizeValidationSchema = object({
    size: number()
      .defined()
      .max(
        maxFileSize,
        t('general__file_img_max_mb_error', {
          sizeInMb: `${maxFileSize / BYTES_TO_MEGABYTES_DIVIDER}`
        })
      )
  });

  let fileErrors: IErrors = {};

  fileErrors = getSchemaErrors(fileSizeValidationSchema, {
    size: fileSize
  });

  if (Object.keys(fileErrors).length > 0) {
    return fileErrors;
  } else {
    return undefined;
  }
};

// validates max size and extension (if defined)
export const validateFile = (
  t: TFunction<'translation', undefined>,
  fileData: File,
  validationRules: IFileValidationRules
): IErrors | undefined => {
  const { maxFileSize, acceptedExtensions } = validationRules;
  let errors: IErrors = {};

  if (acceptedExtensions?.length && acceptedExtensions.length === 1) {
    const fileExtension = `.${fileData.name.split('.').pop() ?? ''}`;
    const fileExtensionError = validateSameFileExtension(
      t,
      fileExtension,
      acceptedExtensions[0]
    );
    errors = { ...fileExtensionError };
  }

  if (maxFileSize) {
    const fileSize = fileData.size;
    const fileOverMaxSizeError = validateFileSize(t, fileSize, maxFileSize);
    errors = { ...errors, ...fileOverMaxSizeError };
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};
