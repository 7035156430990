import { IApiData } from '../apiType';
import { noAuthAxiosInstance } from '../axiosInstance';
import { IAuthConfig, IAuthConfigRequest } from '../models/auth';

const AUTH_PREFIX = '/auth';
const AUTH_CONFIG = `${AUTH_PREFIX}/config`;

export function getAuthConfig(
  config: IAuthConfigRequest,
  abortSignal?: AbortSignal
): Promise<IApiData<IAuthConfig>> {
  return noAuthAxiosInstance.post(AUTH_CONFIG, config, { signal: abortSignal });
}
