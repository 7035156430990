import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledMenu: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        ':hover': {
          backgroundColor: `${theme.vars.palette.background.default}`
        },
        '&.Mui-selected': {
          backgroundColor: theme.vars.palette.primary.shades[1],
          fontWeight: theme.typography.fontWeightBold,
          outline: 'unset !important',
          '&:hover': {
            backgroundColor: `${theme.vars.palette.background.default}`
          }
        }
      })
    }
  }
};
