import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledLink: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.vars.palette.text.secondary,
        '&.Mui-focusVisible': {
          outline: `1px solid ${theme.palette.action.active} !important`,
          borderRadius: '4px',
          padding: theme.spacing(0.5)
        },
        '&.link-external': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          textDecoration: 'none',
          color: theme.vars.palette.text.secondary,
          padding: theme.spacing(0.5),
          '&:hover': {
            color: theme.palette.primary.light
          },
          '&:focus-visible': {
            outline: `1px solid ${theme.palette.primary.light} !important`,
            borderRadius: '4px',
            padding: theme.spacing(0.5)
          },
          '.icon': {
            fontSize: '1.25rem',
            marginRight: theme.spacing(0.5)
          }
        }
      })
    }
  }
};
