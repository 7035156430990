import { Components, CssVarsTheme, Theme } from '@mui/material';
import { styledPopover } from './popover';
import { styledModal } from './modal';

export const styledUtilsAll: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  ...styledPopover,
  ...styledModal
};
