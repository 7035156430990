import {
  IMetadata,
  MetaDataFieldEnum,
  MetadataValue
} from 'src/app/api/controllers/metadata';

export enum AssetActionEnum {
  VIEW = 'view',
  EDIT = 'edit'
}

export enum EStepName {
  FILE = 'file',
  METADATA = 'metadata',
  SUMMARY = 'summary'
}

export const displayMetadataFromBackend = (
  assetAction: AssetActionEnum,
  assetMetadata: MetadataValue,
  metadata?: IMetadata
): string | string[] => {
  if (!assetMetadata || !metadata) return '';
  switch (metadata.fieldType) {
    case MetaDataFieldEnum.MultiLineTextbox:
    case MetaDataFieldEnum.SingleLineTextbox:
      return assetMetadata.toString();
    case MetaDataFieldEnum.RadioButton:
    case MetaDataFieldEnum.SingleValueDropdown:
      return assetAction === AssetActionEnum.VIEW
        ? metadata.options?.find((o) => o.id === assetMetadata)?.label ?? ''
        : metadata.options?.find((o) => o.id === assetMetadata)?.id ?? '';
    case MetaDataFieldEnum.MultiValueDropdown:
    case MetaDataFieldEnum.Checkbox:
      return assetAction === AssetActionEnum.VIEW
        ? Array.isArray(JSON.parse(assetMetadata.toString()))
          ? Array.from(JSON.parse(assetMetadata.toString()))
              .map(
                (am) => metadata.options?.find((o) => o.id === am)?.label ?? ''
              )
              .join(', ')
          : ''
        : Array.isArray(JSON.parse(assetMetadata.toString()))
        ? Array.from(JSON.parse(assetMetadata.toString())).map(
            (am) => metadata.options?.find((o) => o.id === am)?.id ?? ''
          )
        : [];
    case MetaDataFieldEnum.CustomKeywords:
      return assetAction === AssetActionEnum.VIEW
        ? Array.isArray(JSON.parse(assetMetadata.toString()))
          ? Array.from(JSON.parse(assetMetadata.toString())).join(', ')
          : ''
        : Array.isArray(JSON.parse(assetMetadata.toString()))
        ? Array.from(JSON.parse(assetMetadata.toString()))
        : [];
    case MetaDataFieldEnum.Date:
      return JSON.stringify(assetMetadata.toString())
        ? assetMetadata.toString().toLocaleString() ?? ''
        : '';
    default:
      return assetMetadata.toString();
  }
};
