import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledDivider: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.vars.palette.divider,
        borderColor: theme.vars.palette.divider,
        height: '0.0625rem'
      })
    },
    variants: [
      {
        props: { variant: 'thick' },
        style: ({ theme }) => ({
          height: '0.125rem',
          margin: theme.spacing(3, 0)
        })
      },
      {
        props: { orientation: 'vertical' },
        style: ({ theme }) => ({
          borderRightWidth: '0.0625rem',
          margin: theme.spacing(0, 1),
          height: theme.spacing(3)
        })
      }
    ]
  }
};
