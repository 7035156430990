import { ReactNode } from 'react';
import { StateCreator } from 'zustand';
import { uploadResetters } from '../uploadStore';
import { UppyFile } from '@uppy/core';

//need to declare initial state like this to be able to reset it
const step1InitialState = {
  canLeaveStep1: false,
  step1Info: null,
  totalUploadFilesInfo: { totalFiles: 0, totalSize: 0 },
  uploadProgress: []
};

export interface ITotalUploadFilesInfo {
  totalFiles: number;
  totalSize: number;
}

export interface IStep1Slice {
  canLeaveStep1: boolean;
  step1Info: ReactNode;
  totalUploadFilesInfo: ITotalUploadFilesInfo;
  setCanLeaveStep1: (canLeave: boolean) => void;
  setStep1Info: (info: ReactNode) => void;
  setTotalUploadFilesInfo: (info: ITotalUploadFilesInfo) => void;
  uploadProgress: UppyFile[];
  setUploadProgress: (value: UppyFile[]) => void;
}

export const createStep1Slice: StateCreator<IStep1Slice> = (set) => {
  uploadResetters.push(() => set(step1InitialState));

  return {
    ...step1InitialState,
    setCanLeaveStep1: (canLeave: boolean) =>
      set(() => ({ canLeaveStep1: canLeave })),
    setStep1Info: (info: ReactNode) => set(() => ({ step1Info: info })),
    setTotalUploadFilesInfo: (info: ITotalUploadFilesInfo) =>
      set(() => ({ totalUploadFilesInfo: info })),
    setUploadProgress: (value: UppyFile[]) =>
      set(() => ({ uploadProgress: value }))
  };
};
