import { useTranslation } from 'react-i18next';
import AccessibilityLink from 'src/app/components/navigation/accessibilityLink/AccessibilityLink';
import { TempDevHelpers } from 'src/app/components/z_temp_forDevUse/TempDevHelpers';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { useAuth } from 'src/app/stores';
import { Box, useTheme } from '@mui/material';
import { Sidebar } from 'src/app/pages/admin/_components/navigation/Sidebar';
import { adminStore } from 'src/app/stores/admin/adminStore';
import { Claim } from 'src/app/utilities/claims';
import { BasicDrawer } from 'src/app/components/navigation/drawer/BasicDrawer';
import BaseError from 'src/app/pages/error/BaseError';

import './admin-gereral-styles.scss';
import './admin-layout.scss';
import { useAuthentication } from 'src/app/contexts/authentication';

export default function ConnectedAdminLayout() {
  const theme = useTheme();
  const [t] = useTranslation();
  const showDebugBar = process.env.REACT_APP_DEBUG_BAR;
  const { claims } = useAuth();
  const { sidebarWidth } = adminStore();
  const { isAuthenticated } = useAuthentication();

  //shows error page if trying to access admin without claims for it
  if (
    claims !== undefined &&
    !claims?.hasClaims([Claim.Admin.Dashboard]) &&
    isAuthenticated
  ) {
    return <BaseError errorType={403} />;
  }

  return (
    <>
      <ScrollRestoration />
      <AccessibilityLink
        href="#main-content"
        title={t('general__skip_to_main_content')}
      />
      <AccessibilityLink
        href="#footer-nav"
        title={t('general__skip_to_navigation')}
      />
      <AccessibilityLink
        href={t('routes__accessibility_page_slug')}
        title={t('general__skip_to_accessibility_page')}
      />
      <Box className={'admin-layout'}>
        <Sidebar />
        <Box
          id={'main-content'}
          margin={`${theme.spacing(5.25)} ${theme.spacing(2.75)}`}
          marginLeft={`calc(${sidebarWidth}rem + ${theme.spacing(4.75)})`}
          className={'admin-layout__content'}
        >
          <Outlet />
        </Box>
        <BasicDrawer />
      </Box>
      {showDebugBar === 'true' && (
        <Box marginLeft={`${sidebarWidth}rem`}>
          <TempDevHelpers />
        </Box>
      )}
    </>
  );
}
