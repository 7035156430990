import { Components, CssVarsTheme, Theme } from '@mui/material';
import { DEFAULT_COLOR_MODE } from 'src/app/utilities';

export const styledAutocomplete: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: () => ({
        flexDirection: 'row'
      }),
      input: () => ({
        flexGrow: 500
      }),
      listbox: ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        marginLeft: theme.spacing(-0.5),
        '.MuiAutocomplete-option': {
          padding: theme.spacing(0, 0.5),
          minHeight: theme.spacing(3.5),
          transition: 'all 100ms cubic-bezier(0, 0, 0.2, 1)',

          '&.Mui-focusedVisible, &.Mui-focused': {
            backgroundColor:
              theme.palette.mode === DEFAULT_COLOR_MODE
                ? theme.vars.palette.primary.shades[0]
                : theme.vars.palette.primary.shades[3]
          },

          '&.chip': {
            backgroundColor: theme.vars.palette.primary.shades[1],
            '&.Mui-focusedVisible, &.Mui-focused': {
              backgroundColor: theme.vars.palette.primary.shades[2]
            }
          },
          '&.asset': {
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            alignItems: 'center',
            height: theme.spacing(6),
            padding: theme.spacing(0, 0.5, 0, 0),
            '.img-container': {
              overflow: 'hidden',
              position: 'relative',
              height: 'inherit',
              width: theme.spacing(9),
              img: {
                objectFit: 'cover',
                maxWidth: '100%',
                maxHeight: '150%',
                minHeight: '100%',
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)'
              }
            },
            '.label': {
              padding: theme.spacing(0, 1, 0, 2),
              strong: {
                fontWeight: theme.typography.fontWeightBold
              }
            }
          }
        }
      }),
      root: ({ theme }) => ({
        '&.MuiAutocomplete-hasClearIcon': {
          '.MuiOutlinedInput-root': {
            padding: theme.spacing(1.5, 3)
          }
        },
        '.MuiOutlinedInput-root': {
          backgroundColor: theme.vars.palette.background.paper,
          borderRadius: '0.5rem',
          color: theme.vars.palette.text.primary,
          padding: theme.spacing(1.5, 3),
          minHeight: theme.spacing(8),
          caretColor: theme.vars.palette.secondary.main,

          'input::-webkit-input-placeholder': {
            opacity: '1'
          },

          '.MuiOutlinedInput-notchedOutline': {
            border: `0.0625rem solid ${theme.vars.palette.primary.shades[2]}`,
            boxShadow: theme.shadows[1],
            transition: 'border-width 100ms ease-in-out'
          },

          //changes border when we focus on input
          '&.Mui-focused': {
            borderRadius: '0.5rem 0.5rem 0 0',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.vars.palette.primary.shades[2]}`,
              borderWidth: '0.0625rem'
            }
          },

          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.vars.palette.primary.shades[2]}`
            }
          },

          '.MuiAutocomplete-input': {
            padding: 0
          }
        }
      }),
      noOptions: {
        padding: 0
      }
    }
  }
};
