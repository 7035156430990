import { Components, CssVarsTheme, Theme } from '@mui/material';

export const styledSkeleton: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
> = {
  MuiSkeleton: {
    styleOverrides: {
      root: ({ theme }) => ({
        ':after': {
          background: `linear-gradient( 90deg, transparent, ${theme.palette.primary.shades[1]}, transparent )`
        }
      })
    }
  }
};
