import axiosInstance from '../axiosInstance';
import {
  ETenantBrandImages,
  ITenantInfoApi,
  ITenantLanguage,
  ITenantBrandImagePropsForAPI,
  ITenantBrandImageApiInfo,
  ITenantInfoWithBranding
} from '../models/tenants';

const TENANTS_PREFIX = 'api/v1/tenants';
const TENANTS_CURRENT = `${TENANTS_PREFIX}/current`;
const TENANTS_CURRENT_WITH_BRANDING = `${TENANTS_CURRENT}/branding`;
const CUSTOMIZATIONS_PREFIX = 'api/v1/customizations';
const CUSTOMIZATIONS_CURRENT = `${CUSTOMIZATIONS_PREFIX}/current`;
const CUSTOMIZATIONS_LOGO = `${CUSTOMIZATIONS_CURRENT}/logo`;
const CUSTOMIZATIONS_FAVICON = `${CUSTOMIZATIONS_CURRENT}/favicon`;

export async function getTenantInfoFromAPI(
  controller?: AbortController
): Promise<{
  data: Partial<ITenantInfoApi>;
}> {
  return await axiosInstance.get(TENANTS_CURRENT, {
    signal: controller?.signal
  });
}

export async function getTenantInfoAndBranding(
  lastFetchDate?: string,
  controller?: AbortController
): Promise<ITenantInfoWithBranding> {
  const url = lastFetchDate
    ? `${TENANTS_CURRENT_WITH_BRANDING}?lastFetchDate=${lastFetchDate}`
    : TENANTS_CURRENT_WITH_BRANDING;

  return await axiosInstance
    .get(url, {
      signal: controller?.signal
    })
    .then((response) => response.data);
}

export async function getTenantLanguages(
  controller?: AbortController
): Promise<{
  data: ITenantLanguage[];
}> {
  return await axiosInstance.get(`${TENANTS_PREFIX}/current/languages`, {
    signal: controller?.signal
  });
}

export async function getTenantBrandImageFromApi({
  brandImageType,
  controller
}: ITenantBrandImagePropsForAPI): Promise<{
  data: ITenantBrandImageApiInfo;
}> {
  const url =
    brandImageType === ETenantBrandImages.FAVICON
      ? `${CUSTOMIZATIONS_FAVICON}`
      : brandImageType === ETenantBrandImages.COLOURED_LOGO
      ? `${CUSTOMIZATIONS_LOGO}`
      : `${CUSTOMIZATIONS_LOGO}?isWhiteVersion=true`;

  return await axiosInstance.get(url, {
    signal: controller?.signal
  });
}
