import i18next from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enUS from 'src/assets/locales/en-US.json';
import esES from 'src/assets/locales/es-ES.json';
import frCA from 'src/assets/locales/fr-CA.json';

/*
 * ⚠️ when adding a new local, please also import the new one
 * in date.ts from date-fns and in APp.tsx
 */

export enum Language {
  enUS = 'en-US',
  esES = 'es-ES',
  frCA = 'fr-CA'
}

export type ILanguage = `${Language}`;

export const defaultLanguage = Language.enUS;

const resources = {
  [Language.enUS]: {
    translation: enUS
  },
  [Language.esES]: {
    translation: esES
  },
  [Language.frCA]: {
    translation: frCA
  }
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: globalThis.localStorage.userLanguage,
    fallbackLng: Language.enUS,
    supportedLngs: [Language.enUS, Language.esES, Language.frCA],
    detection: {
      order: ['path', 'navigator']
    }
  });

export default i18next;

export function getCurrentLanguage(): Language {
  return i18next.language as Language;
}

export function changeLanguage(language: Language): void {
  i18next.changeLanguage(language);
}
