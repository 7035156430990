import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Container, Typography, useTheme } from '@mui/material';

import { Flexbox } from 'src/app/components/surfaces/box/Flexbox';
import SimpleHeader from 'src/app/components/surfaces/headers/simpleHeader/SimpleHeader';
import { SVGWithDynamicColors } from 'src/app/components/utils/svgImages/SVGWithDynamicColors';
import { Logo } from 'src/app/components/dataDisplay/brandImages/Logo';
import { useCustomTheme } from 'src/styles/_theme';
import { DEFAULT_COLOR, DEFAULT_COLOR_MODE } from 'src/app/utilities/constants';
import { useNavigate } from 'react-router-dom';

import errorPageImg1x from 'src/assets/images/errorpage-1x.png';
import errorPageImg2x from 'src/assets/images/errorpage-2x.png';

import './maintenance.scss';

export default function Maintenance() {
  const [t] = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setCustomTheme, setMode } = useCustomTheme();

  useEffect(() => {
    setCustomTheme(DEFAULT_COLOR);
    setMode(DEFAULT_COLOR_MODE);
  }, [setCustomTheme, setMode]);

  return (
    <>
      <Helmet>
        <title>{t('general__app_title')}</title>
      </Helmet>

      <SVGWithDynamicColors
        svgArrayMappingInfo={{ arrayKey: 'bgSquares', index: 10 }}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
      />
      <SVGWithDynamicColors
        svgArrayMappingInfo={{ arrayKey: 'bgSquares', index: 11 }}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
      />

      <Container
        maxWidth="xl"
        className="no-container-padding-below-xxs maintenance-page__header"
        sx={{ zIndex: 1 }}
      >
        <SimpleHeader>
          <Logo
            isAlwaysASMBLLogo
            sx={{
              maxWidth: '4.5rem',
              maxHeight: '4.5rem',
              marginLeft: theme.spacing(1)
            }}
          />
        </SimpleHeader>
      </Container>

      <main className="maintenance-page">
        <Container maxWidth="xl" className="maintenance-page__middle-container">
          <img
            srcSet={`${errorPageImg1x} 715w, ${errorPageImg2x} 1428w`}
            src={errorPageImg2x}
            className="maintenance-page__main-img"
          />
          <Flexbox
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={theme.spacing(2)}
          >
            <Typography variant="h2">{t('maintenance__title')}</Typography>

            <Flexbox
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={theme.spacing(3)}
            >
              <Typography component="p" variant="body1">
                <Trans i18nKey="maintenance__text" components={{ 1: <br /> }} />
              </Typography>
              <Button variant="contained" onClick={() => navigate(0)}>
                {t('general__refresh')}
              </Button>
            </Flexbox>
          </Flexbox>
        </Container>
      </main>
    </>
  );
}
